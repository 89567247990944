import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Modal, Button} from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import Cookies from 'universal-cookie';
import fireApi from "../redux/index";
import allActions from "../redux/actions";
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import MainHeader from '../components/MainHeader';
import Footer from '../components/footer';
import ProfileNav from './profile/profileNav';
import HandlePrescriptionModal from '../containers/handlePrescription';
import { useHistory } from "react-router-dom";
import { RootImgUrl } from "../constants/const";
import _ from 'lodash'
import '../css/prescription.css';
const cookies = new Cookies();

const Prescription = () => {
	const dispatch = useDispatch();
  	const currentUser = useSelector((state) => state.login.currentUser);
	const prescriptionList = useSelector((state) => state.PrescriptionList.prescriptionData) ;
	const [confirmWithPrescriptionModal, setConfirmWithPrescriptionModal] = useState(false);
	const [isGuestUser, setIsGuestUser] = useState(false);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [prescriptions, setPrescriptions] = useState([]);
	const [deleteId, setDeleteId] = useState("");
	const history = useHistory();
	const userType = cookies.get("user_type");
	

	const addPrescription = () => {
		setShowUpdateModal(true);
	};
	const handleClose = () => {
		setConfirmWithPrescriptionModal(false)
	}

	useEffect(() => {
		fetchDataFromServer();
	}, []);
	
	useEffect(() => {
		if (userType !== "user") setIsGuestUser(true);
	}, [userType]);
	
	useEffect(() => {
		prescriptionList && setPrescriptions(prescriptionList) ;
	}, [prescriptionList]);

	const fetchDataFromServer = () => {
		return fireApi
			.callApiWithToken(
			"prescriptions/get_my",
			{},
			currentUser.token || cookies.get("token"),
			currentUser._id || cookies.get("user_id")
			)
			.then((res) => {
			if (!res || !res.data || !res.data.status) {
				return;
			}

			if (res && res.token_refreshed) {
				LogicalFuncs.storeRefreshToken({
				res,
				dispatch,
				allActions,
				cookies,
				});
			}
			dispatch(allActions.prescriptionActions.prescription(res.data.data))
			});
	};

	const handlePrescriptionItemDelete = () =>{
		fireApi
		.callApiWithToken(
			"prescriptions/delete",
			{prescription_id:deleteId},
			currentUser.token || cookies.get("token"),
			currentUser._id || cookies.get("user_id") || localStorage.getItem('user_id')
		)
		.then((res) => {
			if (!res || !res.data || !res.data.status) return;
			const newPrescriptionList = _.filter(prescriptions, pr => pr._id !== deleteId)
			dispatch(allActions.prescriptionActions.prescription(newPrescriptionList));
			setConfirmWithPrescriptionModal(false)
			setDeleteId("")
			if(newPrescriptionList.length === 0 ) setPrescriptions([])

		});
	}


	return (
		<>
			{isGuestUser ? (
			    history.push("/Login")
			) : (
				<>
				<MainHeader />
				<Container className="profile-pages-content-area-container">
					<Row>
						<Col lg={3} md={12} className="profile-pages-content-area-item-nav">
							<ProfileNav />
						</Col>
						<Col lg={9} md={12} className="profile-pages-content-area-item-content">
							<div className="title-heading">
								<span className="title-heading-small-styles">My</span>
								prescription
							</div>
							<button className="btn-secondary add-prescription-button" onClick={() => addPrescription()}>
								Add New
							</button>
							<Row className="profile-history-singel-order-area">
								{
									_.map(prescriptions, pr => {
									return <Col xl={3} lg={4} md={6} >
												{pr.prescription_images.length>0 && 
													<>
														<div className="prescription-block" style={{backgroundImage: `url("${RootImgUrl}${pr.prescription_images[0]}")`}} /> 
														<i className="material-icons delete-icon" onClick={() => {
															setConfirmWithPrescriptionModal(true)
															setDeleteId(pr._id)
															}} >delete</i>
													</>
												}
												<p className="prescription-block_title">{pr.name}</p>
											</Col>
									})
								}
							</Row>
						</Col>
					</Row>
				</Container>
				<Footer backToTopVisible />
				</>
			)}

            {showUpdateModal && (
				<HandlePrescriptionModal
					openModal={showUpdateModal}
					setShowUpdateModal={setShowUpdateModal}
					is_new={true}
					setPrescriptions = {setPrescriptions}
				/>
			)}


			<Modal show={confirmWithPrescriptionModal} onHide={handleClose} keyboard={false}  centered>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body className="guest-modal-message">
					Do you want to remove this prescription?
				</Modal.Body>
				<Modal.Footer
				className="guest-modal-footer"
				style={{ justifyContent: "center" }}
				>
				<Button
					className="guest-modal-footer-button"
					variant="secondary"
					onClick={() => handlePrescriptionItemDelete()}
				>
					Yes
				</Button>
				<Button
					className="guest-modal-footer-button"
					variant="secondary"
					onClick={() => {
						setConfirmWithPrescriptionModal(currConfirmWithPrescriptionModal => !currConfirmWithPrescriptionModal)
						setDeleteId("")
					}}
				>
					No
				</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Prescription;
