import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";

import fireApi from "../../../redux/index";
import allActions from "../../../redux/actions";
import LogicalFuncs from "../../../logicalFunctions/handleHomeData";
import NoDataFound from "../../noDataFound";
import MainHeader from "../../../components/MainHeader";
import Footer from "../../../components/footer";
import ProfileNav from "../profileNav";
import OrderHistoryList from "./orderHistory";
import { useHistory } from "react-router-dom";
import "../../../css/orderHistory.css";

const cookies = new Cookies();
const OrderHistory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.login.currentUser);

  const [orderHistory, setOrderHistory] = useState([]);
  const [isGuestUser, setIsGuestUser] = useState(false);
  const userType = cookies.get("user_type");
  
  useEffect(() => {
		if (userType !== "user") setIsGuestUser(true);
	}, [userType]);

  useEffect(() => {
    fetchDataFromServer();
  }, []);

  const fetchDataFromServer = () => {
    return fireApi
      .callApiWithToken(
        "order/history",
        {},
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        let apiResponse = res.data.data;
        if (apiResponse) {
          let historyRelevantData = apiResponse.filter(
            (data) => data.order_status === 6 || data.order_status === 7 || data.order_status === 0
          );
          setOrderHistory(historyRelevantData);
        }
      });
  };

  return (
    <>
      {isGuestUser ? history.push("/Login") : (
        <>
          <MainHeader />
          <Container className="profile-pages-content-area-container">
            <Row>
              <Col
                lg={3}
                md={12}
                className="profile-pages-content-area-item-nav"
              >
                <ProfileNav />
              </Col>
              <Col
                lg={9}
                md={12}
                className="profile-pages-content-area-item-content"
              >
                <div className="title-heading">
                  <span className="title-heading-small-styles">Order</span>
                  History
                </div>
                {orderHistory ? orderHistory.map((item) => (
                    <OrderHistoryList item={item} history={true} />
                  )) : <><NoDataFound /></>}
              </Col>
            </Row>
            
          </Container>
          <Footer backToTopVisible />
        </>
      )}
    </>
  );
};

export default OrderHistory;
