import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import ContactUsImg from "../images/contact_us.png";
import MetaDecorator from '../components/MetaDecorator';
import "../css/contactUs.css";

const ContactUs = () => {
  const seoData = {
    title:`Contact Us For Medicine Home Delivery | Osudpotro`,
    description:`OsudPotro is Bangladesh's most trusted online medical supply store & top ... ✓Free Home Delivery ✓DoctorHelp ✓Fast Delivery with COD. ... Call to Order.`,
    keywords: `Contact us, pharmacy, medicine home delivery, 24/7 Online Doctor consultation`,
    og_url:`/contact-us-for-medicine-home-delivery`,
    og_imageUrl: ContactUsImg
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaDecorator seo = {seoData} />
      <MainHeader />
      <Container className="contact-page-area">
        <Row>
          <Col lg={5} md={5} sm={12} className="align-self-center ">
            <img
              style={{ width: "100%" }}
              src={ContactUsImg}
              alt="Contact Doctor"
            />
          </Col>
          <Col lg={7} md={7} sm={12} className="align-self-center ">
            <a className=" call-btn btn btn-secondary" href="tel:09610001122">
              Call : 09610-00-11-22
            </a>
          </Col>
        </Row>
      </Container>
      <Footer backToTopVisible />
    </>
  );
};

export default ContactUs;
