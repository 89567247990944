import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import allActions from "../redux/actions";

import MainHeader from "../components/MainHeader";
import ProductListComponent from "../components/ProductList";
import OrderFromPhone from "../components/orderFromPhone";
import Footer from "../components/footer";
import fireApi from "../redux/index";

import InfiniteScroll from "react-infinite-scroll-component";
import MetaDecorator from '../components/MetaDecorator';
import "../css/allProductListing.css";

const cookies = new Cookies();
const apiNameList = {
  'otc-medicines': "otc_disease/get_all_w_pag",
  'prescription-medicines': "medicines/get_all_w_pag",
  'products': "products/get_cat_prod_w_pag",
  "online-doctor's": "doctors/get_all_docs",
};
const searchTypeList = {
  'otc_disease': "disease",
  'prescription-medicines': "pres_medicine",
  'products': "product",
  "online-doctor's": null,
};

const ProductList = ({category, category_id = "",cat_alias,seoObj }) => {
  
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const [items, setItems] = useState([]);
  const [apiName, setAPIName] = useState("");
  const [searchType, setSearchType] = useState("");
  const [numberOfTimesDataFetched, setNumberOfTimesDataFetched] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [seoData,setSeoData] = useState(null)

  const fetchdataFromServer = () => {
    if (apiName) {
      let parameters = { page: `${numberOfTimesDataFetched}`, limit: 10 };
      if (category_id && category_id !== "") {
        parameters["cat_id"] = category_id;
      }

      return fireApi
        .callApiWithToken(
          apiName,
          parameters,
          currentUser.token,
          currentUser._id
        )
        .then((res) => {
          if (res.no_token) return;

          if (!res || !res.data.status) {
            setHasMore(false);
            return;
          }

          if (res && res.token_refreshed) {
            var after7Days = new Date();
            after7Days.setDate(after7Days.getDate() + 7);
            cookies.set("token", res.token, {
              path: "/",
              expires: after7Days,
            });
            
            dispatch(
              allActions.loginActions.currentUserToStore({
                token: res.token,
              })
            );
          }
          let productsfetched = [...items, ...res.data.data];
          setItems(productsfetched);
          setNumberOfTimesDataFetched(numberOfTimesDataFetched + 1);
          return;
        });
    }
  };

  useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

  useEffect(() => {
    setAPIName(apiNameList[category.toLowerCase().split(" ").join("_")]);
    setSearchType(
      searchTypeList[category.toLowerCase().split(" ").join("_")]
    );
  }, [category]);

  useEffect(() => {
    if (category_id && category_id !== "") {
      setAPIName(apiNameList["products"]);
      setSearchType(searchTypeList["products"]);
    }
  }, [category_id]);

  useEffect(() => {
    if (apiName !== "" && currentUser.token !== undefined) {
      fetchdataFromServer();
    }
  }, [apiName,currentUser]);

  useEffect(() => {
    setSeoData({
      title:seoObj.title,
      description:seoObj.description,
      keywords:seoObj.keywords,
      og_url:seoObj.url,
      og_imageUrl:seoObj.image
    })
  }, [seoObj]);
  
  return (
    <>
      {seoData !==null && <MetaDecorator seo = {seoData} /> }
      <MainHeader
        isDetailPage={true}
        searchType={searchType}
        prdId={category_id && category_id !== "" ? category_id : null}
      />
      <div className="container inner-page-structure-area pt-5">
        <div className="list-cont">
          <div className="page-titile">
            <h3 className="prod-listing-heading mb-2">
              {category.replace('-',' ')}
            </h3>
            <div className="prod-list-description">
              {/* It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. */}
              {/* {productType.cat_desc} */}
            </div>
          </div>

          <div>
            <InfiniteScroll
              dataLength={items.length}
              next={fetchdataFromServer}
              hasMore={hasMore}
              scrollThreshold={0.7}
              loader={
                <h4 style={{ display: "flex", justifyContent: "center" }}>
                  Loading...
                </h4>
              }
            >
              <div className="all-prod-list-cont">
                {items.map((item) => {
                  let imageToShow =
                    (item &&
                      (item.dis_image ||
                        (item.images && item.images[0] && item.images[0].img) ||
                        item.doc_image)) ||
                    "";
                  let name =
                    (item &&
                      (item.dis_name ||
                        item.brand_name ||
                        item.prod_name ||
                        item.doc_name)) ||
                    "";
                  let description =
                    (item &&
                      (item.dis_desc ||
                        item.description ||
                        item.prod_desc ||
                        item.doc_designation)) ||
                    "";
                  let price =
                    (item &&
                      item.inventory &&
                      item.inventory[0] &&
                      item.inventory[0].price) ||
                    "";
                  let regular_price =
                    (item &&
                      item.inventory &&
                      item.inventory[0] &&
                      item.inventory[0].regular_price) ||
                    "";
                  let discount =
                    (item &&
                      item.inventory &&
                      item.inventory[0] &&
                      item.inventory[0].discount) ||
                    0;
                  return (
                    <div className="single-prod-cont" key={item._id}>
                      <ProductListComponent
                        item={{
                          cat_name: cat_alias,
                          cat_type: item.item_type,
                          cat_id: category_id,
                          alias:item.alias,
                          is_showcasing:item.is_showcasing
                        }}
                        itemId={item._id}
                        imageToShow={imageToShow}
                        name={name}
                        description={description}
                        price={price}
                        regular_price={regular_price}
                        discount={discount}
                      />
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>

      <OrderFromPhone />
      <Footer backToTopVisible />
    </>
  );
};

export default ProductList;
