import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swiper from "react-id-swiper";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay  } from "swiper";
import supportOverlay from "../images/support-overlay.png";
import shieldImg from "../images/shield.png";
import shieldOverlay from "../images/shield-overlay.png";
import { RootImgUrl } from "../constants/const";

import "../css/index.css";
import "swiper/swiper.scss";
import "swiper/swiper-bundle.min.css";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
SwiperCore.use([Autoplay]);

const AdvertismentPanel = () => {
  const homeSliders = useSelector((state) => state.home.sliders);
  const homeMobileSliders = useSelector((state) => state.home.mobileSliders);
  const history = useHistory();

  const addSwiperParams = {
    slidesPerView: 1,
    rebuildOnUpdate: true,
    runCallbacksOnInit: true,
    slideToClickedSlide: true,
    shouldSwiperUpdate:true,
    activeSlideKey: 0,
    loop:true,
    autoplay:true,
    lazy: {
      loadPrevNext: true,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  };
  const onHistoryClick = (sliderImage)=>{
    if(sliderImage.offer_id !== undefined && sliderImage.offer_id !== '')
      history.push(`/offers-and-discount-online-medicine-order-in-dhaka/${sliderImage.offer_id}`)
  }
  const getOfferSliderClass = (sliderImage) => {
    return sliderImage.offer_id !== '' && sliderImage.offer_id !== undefined  ? 'has-offer' : ''
  }
  const imageSlider = sliders => {
    return sliders && sliders.length ? sliders.map((sliderImage, index) => (
            <img
              key={index}
              className={`advertisement-panel img-fluid ${getOfferSliderClass(sliderImage)}`}
              src={`${RootImgUrl}${sliderImage.image}`}
              onClick={() => onHistoryClick(sliderImage)}
            />
          )) : <></>
  }

  return (
    <div className="advertisement-panel-cont">
      <Swiper {...addSwiperParams} containerClass="d-none d-md-block">
        {imageSlider(homeSliders)}
      </Swiper>
      <Swiper {...addSwiperParams} containerClass="d-md-none d-block">
        {imageSlider(homeMobileSliders)}
      </Swiper>

      <div className={homeSliders && !homeSliders.length  ? "container-fluid px-0 home-floting-advertise ":"container home-floting-advertise"}>
        { homeSliders && !homeSliders.length && 
          <div className="slider-area"></div>
        }
        <div className="services-provided align-items-center">
          <Row className="service-row">
            <Col
              lg={4}
              md={4}
              sm={12}
              className="d-flex flex-row justify-content-center align-items-center service"
            >
             <i className="fas fa-clock"></i>
              <div className="service-text ml-3">
                <h6>24/7 support</h6>      
              </div>
            </Col>
            <Col
              lg={4}
              md={4}
              sm={12}
              className="d-flex flex-row justify-content-center align-items-center service hide-in-small"
            >
              <i className="fas fa-user-md"></i>
              <div className="service-text ml-3">
                <h6>Online Doctor</h6>  
              </div>
            </Col>
            <Col
              lg={4}
              md={4}
              sm={12}
              className="d-flex flex-row justify-content-center align-items-center service hide-in-small"
            >
              <img src={supportOverlay} />
              <div className="service-text ml-3">
                <h6>Helpline</h6>
              </div>
            </Col>
          </Row>
        </div>
        <div className="services-provided align-items-center show-in-small">
          <Row className="service-row">
            <Col
              lg={4}
              md={4}
              sm={12}
              className="d-flex flex-row justify-content-center align-items-center service"
            >
              <img
                src={shieldImg}
                onMouseOver={(e) => (e.currentTarget.src = shieldOverlay)}
                onMouseOut={(e) => (e.currentTarget.src = shieldImg)}
              />
              <div className="service-text ml-3">
                <h6>Quality Support</h6>
                <p>Free Support Online 24/7</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="services-provided align-items-center show-in-small">
          <Row className="service-row">
            <Col
              lg={4}
              md={4}
              sm={12}
              className="d-flex flex-row justify-content-center align-items-center service"
            >
              <img
                src={shieldImg}
                onMouseOver={(e) => (e.currentTarget.src = shieldOverlay)}
                onMouseOut={(e) => (e.currentTarget.src = shieldImg)}
              />
              <div className="service-text ml-3">
                <h6>Quality Support</h6>
                <p>Free Support Online 24/7</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AdvertismentPanel;
