import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'react-bootstrap';

import '../css/index.css';

const SignInModal = ({ show, setHandleClose }) => {

	const handleSignIn = () => {
		setHandleClose(false);
		window.location.href = '/login';
	};

	const handleClose = () => {
		setHandleClose(false);
	};

	return (
		<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
			<Modal.Header className="guest-modal-header" closeButton></Modal.Header>
			<Modal.Body className="guest-modal-message">Signin first to buy product and access your account</Modal.Body>
			<Modal.Footer className="guest-modal-footer" style={{ justifyContent: 'center' }}>
				<Button className="guest-modal-footer-button" variant="secondary" onClick={handleSignIn}>
					Login/Signup
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default SignInModal;
