import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import LogicalFuncs from '../logicalFunctions/handleHomeData';
import allActions from '../redux/actions';
import fireApi from '../redux/index';
import addPhotPng from '../images/addphoto.png';
import imageNotFound from '../images/imageNotFound.png';
import { RootThumbImgUrl } from "../constants/const";
import 'swiper/swiper.scss';

const cookies = new Cookies();

const HandlePrescriptionModal = ({
	prescriptionItem = {},
	openModal = false,
	setShowUpdateModal,
	is_new = false,
	prescriptionIndex,
}) => {
	const swipperParams = {
		slidesPerView: 2,
		spaceBetween: 10,
		pagination: { el: '.swiper-pagination', clickable: true },
	};

	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.login.currentUser);
	const prescriptionListFromRedux = useSelector((state) => state.PrescriptionList.prescriptionData) || [];
	const [newPrescription, setNewPrescription] = useState({ ...prescriptionItem });
	const [showPrescriptionDetails, setShowPrescriptionDetails] = useState(openModal);
	const handleNewPrescription = () => {
		const apiName = is_new ? 'prescriptions/add' : 'prescriptions/update';
		const params = { name: newPrescription.name, images: JSON.stringify(newPrescription.prescription_images) };
		if (!is_new) params['prescription_id'] = prescriptionItem._id;

		return fireApi
			.callApiWithToken(
				apiName,
				params,
				currentUser.token || cookies.get('token'),
				currentUser._id || cookies.get('user_id')
			)
			.then((res) => {
				if (!res || !res.data || !res.data.status) {
					return;
				}

				if (res && res.token_refreshed) {
					LogicalFuncs.storeRefreshToken({
						res,
						dispatch,
						allActions,
						cookies,
					});
				}
				if (!is_new) {
					let tmpArray = [...prescriptionListFromRedux];
					tmpArray[prescriptionIndex] = {
						...tmpArray[prescriptionIndex],
						...newPrescription,
					};
					dispatch(allActions.prescriptionActions.prescription(tmpArray));
				} else {
					let tmpObj = { ...newPrescription };
					tmpObj['_id'] = res.data.data && res.data.data._id;
					let tmpArray = [...prescriptionListFromRedux];
					tmpArray.push(tmpObj);
					dispatch(allActions.prescriptionActions.prescription(tmpArray));
				}
				setNewPrescription({});
				setShowPrescriptionDetails(false);
				setShowUpdateModal(false);
			});
	};
	const cancelNewPrescription = () => {
		setNewPrescription({});
		setShowPrescriptionDetails(false);
		setShowUpdateModal(false);
	};

	const handleNewName = (e) => {
		e.persist();
		let tmpObj = { ...newPrescription };
		tmpObj['name'] = e.target.value;
		setNewPrescription(tmpObj);
	};

	const handleImages = (e) => {
		e.persist();
		return fireApi
			.uploadImages({
				images: Object.values(e.target.files),
				apiName: 'prescriptions/upload/images',
				currentToken: currentUser.token || cookies.get('token'),
				_id: currentUser._id || cookies.get('user_id'),
			})
			.then((res) => {
				if (!res || !res.data || !res.data.status) {
					return;
				}

				if (res && res.token_refreshed) {
					LogicalFuncs.storeRefreshToken({
						res,
						dispatch,
						allActions,
						cookies,
					});
				}
				let tmpObj = { ...newPrescription };
				tmpObj['prescription_images'] =
					newPrescription && newPrescription.prescription_images
						? [...newPrescription.prescription_images, ...res.data.data]
						: [...res.data.data];
				setNewPrescription(tmpObj);
			});
	};

	return (
		<Modal show={showPrescriptionDetails} centered onHide={cancelNewPrescription} backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<div style={{ textAlign: 'center', width: '100%' }} className="prescription-new-header">
					{is_new ? 'Add Prescription' : 'Update Prescription'}
				</div>
			</Modal.Header>
			<Modal.Body>
				<div style={{ paddingTop: '20px',textAlign:'center' }}>
					{newPrescription && newPrescription.prescription_images ? (
						<Swiper {...swipperParams}>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<img src={addPhotPng} style={{ width: 'inherit', position: 'relative' }} />
								<input
									type="file"
									style={{ opacity: 0, overflow: 'hidden', position: 'absolute' }}
									className="new-prescription-input-file"
									onChange={(e) => handleImages(e)}
									multiple={true}
								/>
							</div>
							{newPrescription.prescription_images.map((singleImg) => (
								<img src={`${RootThumbImgUrl}${singleImg}` || imageNotFound} style={{ objectFit: 'contain' }} />
							))}
						</Swiper>
					) : (
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<img src={addPhotPng} style={{ width: 'inherit', position: 'relative' }} />
							<input
								type="file"
								style={{ opacity: 0, overflow: 'hidden', position: 'absolute' }}
								className="new-prescription-input-file"
								onChange={(e) => handleImages(e)}
								multiple={true}
							/>
						</div>
					)}
					<input
						type="text"
						name="name"
						value={prescriptionItem.name || newPrescription.name}
						onChange={(e) => handleNewName(e)}
						className="new-prescription-name-input"
						placeholder="Enter Name"
					/>
				</div>
			</Modal.Body>
			<Modal.Footer className="guest-modal-footer" style={{ justifyContent: 'center' }}>
				<Button className="guest-modal-footer-button" variant="secondary" onClick={handleNewPrescription}>
					{is_new ? 'Add Prescription' : 'Update Prescription'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default HandlePrescriptionModal;
