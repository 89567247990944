import React , { useEffect } from 'react';
import {useParams } from 'react-router-dom';

import MainHeader from '../components/MainHeader';
import ProductDetailComponent from '../components/productDetailComponent';
import Footer from '../components/footer';


const ProductDetailPage = () => {
	let { cat_alias, cat_type, alias } = useParams();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	

	return (
		<>
			<MainHeader />
			<div className="product-detail-cont">
				<ProductDetailComponent
					cat_alias = {cat_alias}
					cat_type= {cat_type}
					alias = {alias}
				/>
			</div>
			<div className="container">
				<div className="row">
					<p className="product-disclaimer">
					<strong>Disclaimer:</strong>
					The information provided herein are for informational purposes only and not intended to 
					be a substitute for professional medical advice, diagnosis, or treatment. Please seek the
					advice of a physician or other qualified health provider with any questions you may have 
					regarding a medical condition. Do not disregard professional medical advice or delay in 
					seeking it because of something you have read on this website/ app.
				</p>
				</div>
			</div>
			
			<Footer backToTopVisible />
		</>
	);
};

export default ProductDetailPage;
