import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { Container, Row, Col ,NavLink} from 'react-bootstrap';
import { localUserkey } from "../../../constants/const";
import { useSelector, useDispatch } from 'react-redux';
import MainHeader from '../../../components/MainHeader'
import Footer from '../../../components/footer';
import ProfileNav from '../profileNav';
import Login from '../../Login';
import NoDataFound from '../../noDataFound';
import LogicalFuncs from '../../../logicalFunctions/handleHomeData';
import allActions from '../../../redux/actions';
import fireApi from '../../../redux/index';
import NumberFormat from 'react-number-format';
import '../../../css/transection.css';
const cookies = new Cookies();

const localUserDetails = JSON.parse(localStorage.getItem(localUserkey))
const TransactionHistory = () => {
	const dispatch = useDispatch();
	const [currentUser, setCurrentUser] = useState(useSelector((state) => state.login.currentUser));
	const transactionsFromRedux = useSelector((state) => state.transactionsHistory.transactions) || [];
	const userType = cookies.get("user_type");
	const [isGuestUser, setIsGuestUser] = useState(false);
	const [transactionHitory, setTransactionHistory] = useState([...transactionsFromRedux]);

	useEffect(() => {
		if (userType !== "user") setIsGuestUser(true);
	}, [userType]);

	useEffect(() => {
		if(currentUser && !currentUser.wallet_amount) fetchUserDataFromServer();
		if (!transactionHitory || !transactionHitory.length) fetchDataFromServer();
	}, []);

	const fetchUserDataFromServer = () => {
		return fireApi.callApiWithToken(
			'users/get_detail',{},
			currentUser.token || cookies.get('token'),
			currentUser._id || cookies.get('user_id')
		).then((res) => {
			if (!res || !res.data || !res.data.status) return;
			if (res && res.token_refreshed) LogicalFuncs.storeRefreshToken({res,dispatch,allActions,cookies})
			const updateDetails = {...localUserDetails, ...currentUser, ...res.data.data };
			setCurrentUser(updateDetails)
			localStorage.setItem(localUserkey,JSON.stringify(updateDetails))
			dispatch(allActions.loginActions.currentUserToStore(updateDetails));
		});
	};

	const fetchDataFromServer = () => {
		return fireApi
			.callApiWithToken(
				'wallet/get_wallet',{},
				currentUser.token || cookies.get('token'),
				currentUser._id || cookies.get('user_id')
			)
			.then((res) => {
				if (!res || !res.data || !res.data.status) {
					return;
				}

				if (res && res.token_refreshed) {
					LogicalFuncs.storeRefreshToken({ res, dispatch, allActions, cookies});
				}
				dispatch(allActions.transactionActions.transactionDetail(res.data.data));
				if (res.data.wallet_amount)
					dispatch(
						allActions.loginActions.currentUserToStore({
							...currentUser,
							wallet_amount: res.data.wallet_amount,
							wallet_gift: res.data.wallet_gift,
						})
					);
				setTransactionHistory(res.data.data);
			});
	};

	return (
		<>
			{isGuestUser ? (
				<Login />
			) : (

                 <>
                   <MainHeader />
					<Container className="profile-pages-content-area-container transection-area-container">
						
							<Row>
								<Col lg={3} md={12} className="profile-pages-content-area-item-nav">
									<ProfileNav />
								</Col>
								<Col lg={9} md={12} className="profile-pages-content-area-item-content">
								   <div className="address-list-cont">
									<div className="title-heading">
										<span className="title-heading-small-styles">Wallet</span>
										History
									</div>
									  <Container className="transcetion-history-area">
										<Row className="profile-history-singel-order-area total-balance-area">
											<Col lg={6} xs={6} className="history-singel-title-area align-self-center single-transection text-left">
												<span className="product-name">Total Balance</span>
											</Col>
											<Col lg={6} xs={6} className="history-singel-pricing-area align-self-center text-right">
													<p className="pro-pricing-number wallaet-number-profile">
														<NumberFormat
															value={currentUser.wallet_amount}
															displayType={'text'}
															thousandSeparator={true}
															prefix={`৳`}
															decimalScale={2}
															fixedDecimalScale={true}
															type="currency"
															renderText={(value) => value}
														/>
													</p>	
											</Col>
										</Row>


										{transactionHitory && transactionHitory.length ? (
											transactionHitory.map((transaction) => (
												  <Row className="single-transection-history">
														<Col lg={12} className="single-transection">
															<span className="transection-letter-style">
																Transection ID: {transaction.trx_id}
															</span>
															<span className="transection-letter-style">
																Ammount: 
																<b className="boldstyle-transection">
																<NumberFormat
																	value={`${transaction.amount}`}
																	displayType={'text'}
																	thousandSeparator={true}
																	prefix={`৳`}
																	decimalScale={2}
																	fixedDecimalScale={true}
																	type="currency"
																	renderText={(value) => value}
																	style={{ color: `${transaction.amount ? '#005E6A' : 'red'}` }}
																/>
																</b>
															</span>
															<span className="transection-letter-style">
																Status: 
																<b className="boldstyle-transection" style={{ color: `${transaction.payment_status ? '#005E6A' : 'red'}` }}>
																    {transaction.payment_status ? 'Success' : 'Failure'}
																</b>
															</span>
															<span className="transection-letter-style">
																Date: 19-aug-2020
															</span>
														</Col>
													</Row>
											))
										) : (
											<>
												<NoDataFound />
											</>
										)}	
											
													
									  </Container>
									</div>
								</Col>
							</Row>
						
					</Container>
					<Footer backToTopVisible />	

                </>









			
			)}
		</>
	);
};

export default TransactionHistory;
















                                          