const PrescriptionList = (state = [], action) => {
    switch (action.type) {
    case 'PRESCRIPTION':
        return {
            ...state,
            prescriptionData: action.prescriptionData
        }
    default:
        return state
    }
}

export default PrescriptionList