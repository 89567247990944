const setHomeData = (homeData) => {
    return {
        type: "SET_HOME_DATA",
        homeData 
    }
}

const setHomeSliders = (sliders) => {
    return {
        type: "SET_HOME_SLIDERS",
        sliders
    }
}

export default {
    setHomeData,
    setHomeSliders
}