import fireApi from "../redux/index";
import { localUserkey } from '../constants/const';

const country_code = process.env.REACT_APP_COUNTRY_CODE;

const handleLoginOTP = async ({
  fullMobileNumber,
  otpValue,
  dispatch,
  setPhoneNumberWrong,
  setOTP,
  setIsOtpMatched,
  setIsOtpSent,
  allActions,
  cookies,
}) => {
  var after7Days = new Date();
  after7Days.setDate(after7Days.getDate() + 1);
  let currentUserBecomes = {};

  if (!fullMobileNumber || fullMobileNumber.length !== 15) {
    setPhoneNumberWrong(true);
    setOTP("");
    return;
  }

  const apiName = "users/match_otp";
  const parameters = { mobile: fullMobileNumber, otp: otpValue };

  return await fireApi.callApi(apiName, parameters).then(async (res) => {
    if (!res || !res.data || res.data.status === false) {
      setIsOtpMatched(false);
      cookies.set("user_type", "guest_user", { path: "/", expires: after7Days });
      setIsOtpSent(false);
      return;
    } else {
      setIsOtpMatched(true);
      if (res.data.data && !res.data.data._id) {
        cookies.set("user_type", "user", { path: "/", expires: after7Days });
        cookies.set("token", res.data.data.token, { path: "/", expires: after7Days});

        const apiName = "users/get_detail";
        return await fireApi
          .callApiWithToken(apiName, {}, res.data.data.token)
          .then((userDetails) => {
            if (
              userDetails.data &&
              userDetails.data.data &&
              userDetails.data.data._id
            ) {
              cookies.set("user_id", userDetails.data.data._id, {
                path: "/",
                expires: after7Days,
              });
              currentUserBecomes = {
                ...currentUserBecomes,
                ...userDetails.data.data,
              };
            }
          })
          .then(async (data) => {
            await setIsOtpSent(false);
            currentUserBecomes = {
              ...currentUserBecomes,
              ...res.data.data,
            };
            
            await dispatch(allActions.loginActions.currentUserToStore(currentUserBecomes));
            localStorage.setItem(localUserkey,JSON.stringify(currentUserBecomes))
            return;
          })
          .then((loginSuccess) => {
            return { loginSuccess: true };
          });
      }
     
    }
  });
};

const handleSendOTP = ({
  phoneNumber,
  setPhoneNumberWrong,
  setIsOtpSent,
  setFullMobileNumber,
  setPhoneNumber,
  setOtpSendResponse,
  setErrorResponse,
}) => {
  const first2Digits = phoneNumber.slice(0, 2);
  if (first2Digits !== "01" || phoneNumber.length !== 11) {
    setPhoneNumberWrong(true);
    setIsOtpSent(false);
  } else {
    setPhoneNumberWrong(false);
    const fullNumberBecomes = `${country_code}-${phoneNumber}`;
    setFullMobileNumber(fullNumberBecomes);
    const apiName = "users/send_otp";
    const parameters = {
      mobile: fullNumberBecomes,
      deviceToken: "web",
      language: "en",
      os: "web",
    };
    return fireApi.callApi(apiName, parameters).then((res) => {
      if (res || res.data || res.data.status) 
        setIsOtpSent(true);

      setErrorResponse(!res.data.status)
      setOtpSendResponse(res.data.message)
      return;
      // dispatch(allActions.loginActions.addGuestUserToStore(res.data.data));
    });
  }
};

export default {
  handleLoginOTP,
  handleSendOTP,
};
