import React, { useEffect, useState } from "react";
import { Container, Form, Nav, Navbar, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SearchComponent from "./SearchComponent";
import NavProfileComponent from "./navProfileComponent";
import NavCartComponent from "./navCartComponent";
import TopNavHeader from "../components/topNavHeader";
import { localUserkey} from "../constants/const";
import fireApi from "../redux/index";
import allActions from "../redux/actions";
import { RootImgUrl } from "../constants/const";
import congratulationImage from "../images/group3@3x.png";
import "../css/mainheader.css";

import Cookies from "universal-cookie";
const cookies = new Cookies();
let localUserDetails = JSON.parse(localStorage.getItem(localUserkey))

const MainHeader = ({
  homeScreenData,
  isDetailPage = false,
  searchType = "",
  prdId,
  disId,
}) => {
  var after7Days = new Date();
  after7Days.setDate(after7Days.getDate() + 7);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const [showCongratulationModal, setShowCongratulationModal] = useState(false);
  if (currentUser._id !== undefined && currentUser._id !== "101") {
    localStorage.setItem("user_id", currentUser._id);
  }

  const fetchUserDetailFromServer = (apiname, parameter = {}) => {
    return fireApi
      .callApiWithToken(
        apiname,
        parameter,
        currentUser.token || cookies.get("token"),
        currentUser._id ||
          cookies.get("user_id") ||
          localStorage.getItem("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          var after7Days = new Date()
          after7Days.setDate(after7Days.getDate() + 7)
          cookies.set("token", res.token, {path: "/", expires: after7Days})
          dispatch(allActions.loginActions.currentUserToStore({...currentUser,token: res.token }))
        }
        let apiResponse = res.data.data;
        if (apiResponse) {
          if (apiResponse.show_bonus) {setShowCongratulationModal(true) }
          dispatch(allActions.loginActions.currentUserToStore({...currentUser,...apiResponse}))
        }
      });
  };

  const hideCongratulationModal = () => {
    setShowCongratulationModal(false);
    fetchUserDetailFromServer("users/change_show_bonus");
  };

  const getGuestUser = async () => {
    const guest_user_token = cookies.get("token");
    if (guest_user_token) {
      dispatch(
        allActions.loginActions.currentUserToStore({
          ...currentUser,
          token: guest_user_token,
          _id: "101",
        })
      );
      cookies.set("user_type", "guest_user", { path: "/", expires: after7Days});
      return guest_user_token;
    }

    return await fireApi
      .callApi("users/guest_login")
      .then(async ({ data }) => {
        const token = data.data.token;
        cookies.set("user_type", "guest_user", {path: "/",expires: after7Days});
        cookies.set("token", token, { path: "/", expires: after7Days });
        localStorage.setItem("user_id", data.data.user_id);
        localStorage.setItem(localUserkey,JSON.stringify(data.data))
        dispatch(allActions.loginActions.currentUserToStore(data.data));
        return token;
      });
  };

  useEffect(() => {
    const user_type_cookie = cookies.get("user_type");
    if (!user_type_cookie || user_type_cookie !== "user") {
      getGuestUser();
    } else {
      dispatch(allActions.loginActions.currentUserToStore(localUserDetails));
      cookies.set("user_type", "user", { path: "/", expires: after7Days });
    }
  }, []);


  const [scroll, setScroll] = useState(false);
    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 50);
      });
  }, []);
  
  return (
    <>
      <header className={scroll ? "sticky-header" : "normal-header"}>
        <Navbar className="top-header">
          <Container>
            <Navbar.Brand as={Link} className="brand-Logo" to="/">
              <img
                src={`${RootImgUrl}appImage/web-osudpotro-logo.webp`}
                className="d-inline-block align-top"
                alt="Osudpotro"
              />
            </Navbar.Brand>
            <Form inline className="top-schBar">
              <SearchComponent
                searchType={searchType}
                isDetailPage={isDetailPage}
                prdId={prdId}
                disId={disId}
              />
            </Form>
            <Nav className="headerTools">
              {
                // currentUser._id !== '101' ?
                false ? (
                  <Nav.Link as={Link} to="/cart">
                    <i className="material-icons">person_pin</i>
                    <span className="label">
                      <span>Deliver to&nbsp;</span> Asfaqur - Dhaka
                    </span>
                  </Nav.Link>
                ) : ""
              }

              <Nav.Link as={Link} to="/trackOrder">
                <i className="material-icons">track_changes</i>
                <span className="label">
                  <span>Track</span> Your Order
                </span>
              </Nav.Link>

              <NavCartComponent />
              <NavProfileComponent />
            </Nav>
          </Container>
        </Navbar>
        <TopNavHeader data={homeScreenData} />
      </header>

      <Modal
        show={showCongratulationModal}
        onHide={hideCongratulationModal}
        keyboard={false}
      >
        <Modal.Header
          className="add-address-modal-header"
          closeButton
        ></Modal.Header>
        <Modal.Body style={{ display: "grid", justifyContent: "center" }}>
          <img src={congratulationImage} style={{ width: "100%" }} alt="Congratulation"/>
          <span style={{ textAlign: "center", paddingBottom: "20px" }}>
            <p className="congratulation-text">
              <b>Congartulations</b>
            </p>
            <p className="congratulation-des">we have added </p>
            <p className="congratulation-des">
              <b className="congratulation-amount">50 taka</b> in your wallet
            </p>
          </span>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MainHeader;
