import React from "react";
import { useParams } from "react-router-dom";

import MainHeader from "../components/MainHeader";
import DiseaseDetailPage from '../containers/DiseaseDetailPage';
import Footer from "../components/footer";

const DiseaseDetail = () => {
  const { disId } = useParams();
  return (
    <>
      <MainHeader isDetailPage={true} searchType={'disease_med'} disId={disId}/>
      <div className="product-detail-cont">
        <DiseaseDetailPage />
      </div>

      <Footer backToTopVisible/>
    </>
  );
};

export default DiseaseDetail;
