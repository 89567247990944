import axios from "axios";
import { paymentUrl,http, imgHttp } from "../constants/const";
import Cookies from "universal-cookie";
import allActions from "./actions";
import { localUserkey } from "../constants/const";
let localUserDetails = JSON.parse(localStorage.getItem(localUserkey))
var FormData = require("form-data");
const cookies = new Cookies();

const handleAddMoney = (parameters) => {
  return axios.post(paymentUrl, parameters).then((res) => {
    return res;
  });
};

const callApi = (apiName, parameters = {}) => {
  return http.post(apiName, parameters).then((res) => {
    http.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
    return res;
  });
};

const callImageApi = (apiName, parameters = {}) => {
  return imgHttp.post(apiName, parameters).then((res) => {
    imgHttp.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
    return res;
  });
};

const storeRefreshToken = ({ res, dispatch, allActions, cookies }) => {
	var after7Days = new Date();
  after7Days.setDate(after7Days.getDate() + 7);
  const newToken = res.data.data.token
  http.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  cookies.set('token', newToken, {path: '/',expires: after7Days});
  localUserDetails['token'] = newToken
  localStorage.setItem(localUserkey,JSON.stringify(localUserDetails))
	dispatch(allActions.loginActions.currentUserToStore(localUserDetails));
};

const callApiWithToken = async (
  apiName,
  parameters = {},
  currentToken,
  _id = "",
  dispatch = null,
  postType = 'post'
) => {
  if (!currentToken || !currentToken.length) {
    return await { no_token: true };
  }
  http.defaults.headers.common['Authorization'] = `Bearer ${currentToken}`;
  const config = { headers: { 'Access-Control-Allow-Origin':'*','Authorization': `Bearer ${currentToken}` } }
  
  if(postType === 'get') {
    parameters = {
      params: parameters
    }
  }

  return await http[postType](apiName, parameters,config)
    .then(async (res) => {
      // if token expires from backend, then refresh the token and again fire the api
      if (res.data && res.data.statusCode === 401) {
        const apiNameForRefreshToken = "common/refresh_jwt";
        let userID = (_id !== "" && _id !== "null" && _id !== null && _id !== undefined ) ? _id : (localStorage.getItem('user_id') || 101 )
        return await callApi(apiNameForRefreshToken, { user_id: userID }).then((res) => {
          const newToken = res.data && res.data.data && res.data.data.token;
          if (newToken) {
            http.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            return callApiWithToken(apiName, parameters, newToken, _id,dispatch,postType).then(
              (finalRes) => {
                if(dispatch !== null)
                  storeRefreshToken({ res, dispatch, allActions, cookies })
                return { ...finalRes, token_refreshed: true, token: newToken };
              }
            );
          } else {
            return { no_token: true };
          }
        });
      }
      return res;
    });
};




const uploadImages = async ({
  images = [],
  apiName,
  parameters = {},
  currentToken,
  _id = "",
  image_id = "prescription_files",
}) => {
  var formData = new FormData();
  images.forEach((img) => {
    formData.append(image_id, img);
  });

  if (!currentToken || !currentToken.length) {
    return await { no_token: true };
  }

  return imgHttp
    .post(apiName, formData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${currentToken}`,
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        type: "formData",
      },
    })
    .then((res) => {
      // if token expires from backend, then refresh the token and again fire the api
      if (res.data && res.data.statusCode === 401) {
        const apiNameForRefreshToken = "common/refresh_jwt";
        return callApi(apiNameForRefreshToken, { user_id: _id }).then((res) => {
          const newToken = res.data && res.data.data && res.data.data.token;
          if (newToken) {
            return callApiWithToken(apiName, parameters, newToken, _id).then(
              (finalRes) => {
                return { ...finalRes, token_refreshed: true, token: newToken };
              }
            );
          } else {
            return { no_token: true };
          }
        });
      }
      return res;
    });
};

export default {
  callApi,
  callImageApi,
  callApiWithToken,
  handleAddMoney,
  uploadImages,
};
