import React, { useState } from "react";
import Swiper from "react-id-swiper";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import HandlePrescriptionModal from "../containers/handlePrescription";
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import allActions from "../redux/actions";
import fireApi from "../redux/index";
import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// import "../css/prescription.css";
import "swiper/swiper.scss";

var FontAwesome = require("react-fontawesome");
const cookies = new Cookies();
const checkBoxStyles = (theme) => ({
  root: {
    "&$checked": {
      color: "#005e6a",
    },
  },
  checked: {},
});
const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const PrescriptionItemView = ({
  swipperParams,
  prescriptionItem,
  prescriptionIndex,
  preparePrescriptionIdList = [],
  setPreparePresriptionIdList,
  showEditDelete,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const prescriptionListFromRedux =
    useSelector((state) => state.PrescriptionList.prescriptionData) || [];

  const [activeImage, setActiveImage] = useState();
  const [showImageInModal, setImgInModal] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const hideImageModal = () => {
    setImgInModal(false);
  };

  const updatePrescription = (prescription_id) => {
    setShowUpdateModal(true);
  };

  const handleDeletePrescription = (prescription_id) => {
    return fireApi
      .callApiWithToken(
        "prescriptions/delete",
        { prescription_id: prescription_id },
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        let tmpArray = [...prescriptionListFromRedux];
        tmpArray.splice(prescriptionIndex, 1);
        dispatch(allActions.prescriptionActions.prescription(tmpArray));
        return true;
      });
  };

  const prescriptionIdsForPlaceOrder = (id, checkedOrNot) => {
    let tmpList = [...preparePrescriptionIdList];
    let tmpUpdatedList = [];
    if (checkedOrNot) {
      tmpUpdatedList = [...tmpList, id];
    } else {
      tmpList.map((presId) => {
        if (presId !== id) {
          tmpUpdatedList.push(presId);
        }
      });
    }
    setPreparePresriptionIdList(tmpUpdatedList);
  };

  return (
    <>
      <div>
        <Row nogutters="true">
          <Col lg={10} md={12}>
            <div className="prescription-detail-specification">
              <span className="prescription-detail-specification-name">
                {(prescriptionItem && prescriptionItem.name) || ""}
              </span>
              <span>
                <Swiper {...swipperParams}>
                  {prescriptionItem &&
                    prescriptionItem.prescription_images &&
                    prescriptionItem.prescription_images.map(
                      (singleImg, imgIndex) => (
                        <div
                          className="image-swipper"
                          onClick={() => {
                            setImgInModal(true);
                            setActiveImage(imgIndex);
                          }}
                        >
                          <img
                            src={prescriptionItem.prescription_images[imgIndex]}
                            style={{ objectFit: "cover" }}
                          />
                        </div>
                      )
                    )}
                </Swiper>
              </span>
            </div>
          </Col>
          <Col
            lg={2}
            md={12}
            style={{ marginBottom: "auto", marginTop: "auto" }}
          >
            {showEditDelete ? (
              <>
                <span
                  style={{ marginRight: "10px" }}
                  onClick={() => updatePrescription(prescriptionItem._id)}
                >
                  <FontAwesome
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    name="pencil"
                  />
                </span>
                <span
                  onClick={() => handleDeletePrescription(prescriptionItem._id)}
                >
                  <FontAwesome
                    style={{
                      fontSize: "25px",
                      paddingLeft: "10px",
                      cursor: "pointer",
                    }}
                    name="trash"
                  />
                </span>
              </>
            ) : (
              <>
                <CustomCheckbox
                  onChange={(e) =>
                    prescriptionIdsForPlaceOrder(
                      prescriptionItem._id,
                      e.target.checked
                    )
                  }
                />
              </>
            )}
          </Col>
        </Row>
      </div>

      {showUpdateModal && (
        <HandlePrescriptionModal
          prescriptionItem={prescriptionItem}
          openModal={showUpdateModal}
          setShowUpdateModal={setShowUpdateModal}
          is_new={false}
          prescriptionIndex={prescriptionIndex}
        />
      )}

      <Modal show={showImageInModal} onHide={hideImageModal} keyboard={false} centered>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={
              prescriptionItem.prescription_images &&
              prescriptionItem.prescription_images[activeImage]
            }
            style={{ width: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PrescriptionItemView;
