import React,{useState} from 'react'
import {ListGroup,NavLink} from 'react-bootstrap';

const StickyChat = () => {
    const [collapse, setCollapse] =  useState(false)

    return <div className={`social-media-links sticky-call-to-action-btn ${collapse ? 'showed':'hiddened'}`}>
                <ListGroup as="ul" className={`collapsible ${collapse ? 'expand':'collapsed'}`}>
                    <ListGroup.Item  as="li" >
                        <NavLink className="whatapp" href="https://api.whatsapp.com/send?phone=+8801313980033">
                        <i className="fab fa-whatsapp" aria-hidden="true"></i>
                        </NavLink>
                    </ListGroup.Item>
                    <ListGroup.Item  as="li">
                        <NavLink className="phnCall" href="tel:09610001122">
                        <i className="material-icons">phone</i>
                        </NavLink>
                    </ListGroup.Item>
                </ListGroup>
                <ListGroup as="ul" className={`${collapse ? 'chat-active':''} chaticon`}>
                    <ListGroup.Item  as="li" onClick={() => setCollapse(currCollapse => !currCollapse)}>
                        <NavLink className="phnCall">
                        <i className="fas fa-comment-dots"></i>
                        </NavLink>
                    </ListGroup.Item>
                </ListGroup>
            </div>
}

export default StickyChat;