import React, { useEffect } from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import "../css/footerPagesStyles.css";
import {
  Row,
  Col,
  Container
} from "react-bootstrap";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MainHeader />
      <Container className="footer-related-page-area">
        <Row>
          <Col>
            <span className="page-title">Privacy policy</span>
            <span className="page-subtitles-bold">
              Osudpotro copyright policy
            </span>
            <p>
              You cannot use any of our content for commercial purposes unless
              you have obtained the right to do so from us.
            </p>
            <p>
              The information we provide at Osudpotro.com is intended to replace
              consultation with a qualified medical professional. Seek the
              advice of your physician or another qualified health provider with
              any questions you may have regarding a medical condition.
            </p>
            <p>
              The content we provide is intended to be relied upon for medical
              diagnosis or treatment. Inform your physician of any changes you
              may make to your lifestyle and discuss these with him or her. Do
              disregard medical advice or delay visiting a medical professional
              and also something you read on our site, app, or on our other
              communication channels
            </p>
            <p>
              You may not use our recipes or membership content unless you have
              obtained the right to do so from us.
            </p>
            <span className="page-subtitles-bold">
              Osudpotro privacy policy
            </span>
            <p>
              Osudpotro.com stores and uses personal information in order to
              provide you with good service. We will never sell your
              information, and will only share it with third parties (like
              service providers) when needed to operate and improve our services
            </p>
            <span className="page-paragraph-title">
              What information Osudpotro collects and why Information from
              website browsers
            </span>
            <p>
              We store standard access logs of all visits to the site. This
              includes access time, page visited, browser information and
              internet address, which can potentially be personally identifying.
              We may also store how the user interacts with the site, including
              clicks, scrolling etc.
            </p>
            <span className="page-paragraph-title">
              Why do we collect this?
            </span>
            <p>
              We collect this information to better understand how our website
              visitors use Osudpotro, and to monitor and protect the security of
              our services.
            </p>
            <p>
              We may store information about how our apps are being used. This
              can include access time, page visited, browser information,
              internet address, button and link clicks, scrolling and all other
              ways in which someone interacts with the app, which can
              potentially be personally identified
            </p>
            <span className="page-paragraph-title">
              User-provided information
            </span>
            <p>
              When you submit information to Osudpotro, via our site, apps etc.,
              to comment on posts and articles, rate a recipe or video, ask or
              answer a question, customize a meal plan, mark content as favorite
              or to watch later, or in other ways the content submitted is
              processed and stored. If you are logged in it may be linked to
              your account. It will also in some cases include your internet
              address, which is potentially personally-identifying.
            </p>
            <p>
              Please be careful with any personal or sensitive health
              information you submit or share. Comments, success stories, and
              similar content can be visible to anyone visiting the site! We do
              not filter user-submitted content for personal information or
              sensitive information, and by submitting it you give permission to
              publish the content, and to store and process it as needed for
              that purpose
            </p>
            <p>
              The information may be transmitted, processed and stored by
              third-party service providers to detect spam and to ensure that
              the service works as intended.
            </p>

            <span className="page-paragraph-title">
              Why do we collect this?
            </span>

            <ul className="common-ul-styles">
              <li>
                We need to process and store the information as necessary to
                publish it.
              </li>
              <li>
                We need to detect spam and other forms of invalid use of our
                services.
              </li>
              <li>
                We need to monitor usage and to ensure that the service works as
                intended.
              </li>
              <li>
                We need to understand how our services are used, and we want to
                periodically publish reports on this. For those purposes the
                information may be aggregated, analyzed and published (with
                personally-identifying information removed).
              </li>
              <li>
                We need to improve the service you receive and we may use the
                information you supply, like your ratings, to improve the
                services we offer you.
              </li>
              <li>
                We need to monitor and protect the security of our services and
                your information.
              </li>
            </ul>
            <span className="page-subtitles-bold">Member information</span>
            <p>
              If you sign up for the membership we collect your name, email
              address and payment information. We collect information about all
              subscription payments, and data and statistics on how you use the
              site including video views and when you last visited the site.
            </p>
            <p>
              We also store your answers to surveys you may reply to via email
              or on Osudpotro.com.
            </p>
            <p>
              If membership of Osudpotro were to be considered sensitive
              personal information (health information), by signing up you give
              us permission to handle your information.
            </p>
            <p>
              Payment details are processed and stored by our third-party
              payment service providers. We do not store your actual payment
              details, like full credit card information. That information is
              only handled by our payment service providers.
            </p>
            <p>
              Your stored basic personal information and order information are
              displayed on your account page once you have become a member.
            </p>
            <span className="page-paragraph-title">
              Why do we collect this?
            </span>
            <ul className="common-ul-styles">
              <li>
                We need personal and payment information to set up and charge
                for the membership.
              </li>
              <li>
                We need to use your email address to communicate with you if
                needed for the membership (for example if a payment fails).
              </li>
              <li>
                We need to collect and store information as reasonably needed to
                respond to support requests.
              </li>
              <li>
                We need to collect and store information regarding payments as
                mandated by law (for example for accounting and for VAT
                reporting).
              </li>
              <li>
                We need to monitor and protect the security of our services and
                your information.
              </li>
              <li>
                We need to keep improving our services, and for that purpose may
                contact some members to ask you to help us with this, as well as
                collect and store information about the usage of our services.
              </li>
            </ul>
            <span className="page-subtitles-bold">
              Sharing of information with third parties
            </span>
            <p>
              Osudpotro will not share your personal information with any third
              party unless absolutely required to do so by law, or unless
              required for those service providers we use to operate and improve
              Osudpotro.
            </p>
            <p>
              Service providers handle services such as payment processing, spam
              detection, network data transmission, and other similar services.
            </p>
            <p>
              We remain responsible for your information when shared with our
              service providers, and it will only be used and stored for the
              purposes detailed in this document.
            </p>
            <span className="page-subtitles-bold">
              Changes to terms or privacy policy
            </span>
            <p>
              Osudpotro will not change the core philosophy of our privacy
              policy and terms, but may otherwise update them from time to time.
              If there are significant changes we will make reasonable efforts
              to notify you before they take effect. For minor changes that do
              not affect your rights we encourage you to check this page
              frequently.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer backToTopVisible={true} />
    </>
  );
};

export default PrivacyPolicy;
