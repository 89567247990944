import React, { useEffect, useState } from "react";
import fireApi from "../../../redux/index";
import LogicalFuncs from "../../../logicalFunctions/handleHomeData";
import { useSelector, useDispatch  } from "react-redux";
import Cookies from "universal-cookie";
import allActions from "../../../redux/actions";
import NumberFormat from "react-number-format";
import { Row, Col } from "react-bootstrap";
import { RootThumbImgUrl} from "../../../constants/const";
import CallForPrice from "../../../components/callPriceComponent";

const cookies = new Cookies();
const OrderItemData = ({order_id}) => {

    const [itemsInOrder,setItemsInOrder] =  useState(null)
    const currentUser = useSelector((state) => state.login.currentUser);
    const dispatch = useDispatch();

    useEffect(()=>{
        fireApi.callApiWithToken(
            "order/get_info",
            { order_id },
            currentUser.token || cookies.get("token"),
            currentUser._id || cookies.get("user_id") || localStorage.getItem('user_id')
        )
        .then((res) => {
            if (!res || !res.data || !res.data.status) {
            return;
            }

            if (res && res.token_refreshed) {
                LogicalFuncs.storeRefreshToken({
                    res,
                    dispatch,
                    allActions,
                    cookies,
                });
            }
            setItemsInOrder(res.data.data.order_data)
        });
    },[])

    const getItemOrderPrice = item => {
        let price = 0;
        item.item_data.forEach( itm => {
            if(itm.qty > 0) price += itm.price * itm.qty
        })
        return parseFloat(price).toFixed(2)
    }

    return  itemsInOrder !== null && 
    <>
        { itemsInOrder.map((item,idx) => {
            return  <Row className="profile-history-singel-order-area" key={idx}>
                        <Col
                            lg={2} xs={4}
                            className="history-singel-medical-img-area align-self-center"
                            style={{backgroundImage:  `url("${RootThumbImgUrl}${item.item_image}")`}}
                        ></Col>
                        <Col lg={7} xs={8} className="history-singel-title-area align-self-center">
                            <h4 className="mb-1">{item.item_name}</h4>
                            <ul className="modal-list">
                                {item.item_data.map((itm,index)=> {
                                    return itm.qty > 0 &&  <li key={index}>{`${itm.capacity} ${!item.is_showcasing ? `(৳${parseFloat(itm.price).toFixed(2)})` : ''} x ${itm.qty}`}</li>
                                })}
                            </ul>
                        </Col>
                        <Col lg={3} xs={12} className="history-singel-pricing-area align-self-center">
                            {!item.is_showcasing ?  <NumberFormat
                                value={getItemOrderPrice(item)}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={`৳`}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="currency"
                                renderText={(value) => value}
                            /> : <CallForPrice />}
                        </Col>
                    </Row>
            })
        }
    </>;
}
 
export default OrderItemData;