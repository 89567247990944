import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import NoDataFound from "../app/noDataFound";
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import allActions from "../redux/actions";
import fireApi from "../redux/index";

import PrescriptionItemView from "../components/prescriptionIItemView";

// import "../css/prescription.css";
const cookies = new Cookies();

const PrescriptionList = ({
  swipperParamsFromCheckout = {},
  preparePrescriptionIdList = [],
  setPreparePresriptionIdList,
}) => {
  const swipperParams = { ...swipperParamsFromCheckout } || {
    slidesPerView: 8,
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  };

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const prescriptionListFromRedux =
    useSelector((state) => state.PrescriptionList.prescriptionData) || [];

  const [prescriptionList, setPrescriptionList] = useState([
    ...prescriptionListFromRedux,
  ]);

  useEffect(() => {
    if (!prescriptionListFromRedux || !prescriptionListFromRedux.length)
      fetchDataFromServer();
    setPrescriptionList([...prescriptionListFromRedux]);
  }, []);

  const fetchDataFromServer = () => {
    return fireApi
      .callApiWithToken(
        "prescriptions/get_my",
        {},
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        dispatch(allActions.prescriptionActions.prescription(res.data.data));
        setPrescriptionList(res.data.data);
      });
  };

  return (
    <div className="prescription-lists-list">
      {prescriptionList && prescriptionList.length ? (
        prescriptionList.map((prescriptionItem, index) => (
          <div className="prescription-list-cont">
            <PrescriptionItemView
              swipperParams={swipperParams}
              prescriptionItem={prescriptionItem}
              prescriptionIndex={index}
              preparePrescriptionIdList={preparePrescriptionIdList}
              setPreparePresriptionIdList={setPreparePresriptionIdList}
              showEditDelete={
                Object.keys(swipperParamsFromCheckout).length ? false : true
              }
            />
          </div>
        ))
      ) : (
        <>
          <NoDataFound />
        </>
      )}
    </div>
  );
};

export default PrescriptionList;
