const formatCategoryTitle = category => {
    let formatedCategory = category.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replace('-',' ');
    return formatedCategory
};

const isEmailInValid = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(email).toLowerCase());
}

const isContactNumberInValid = number => {
    const re = /^\d[0-9]{10}$/g;
    return !re.test(number)
}

const isTypeProductCategory = itemType => {
    const types = ['product','products','healthcare-product']
    return types.includes(itemType)
}

export {
    formatCategoryTitle,
    isEmailInValid,
    isContactNumberInValid,
    isTypeProductCategory
}