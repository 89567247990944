import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import MainHeader from "../components/MainHeader";
import allActions from "../redux/actions";
import handleHomeData from "../logicalFunctions/handleHomeData";
import AdvertismentPanel from "../components/AdvertismentPanel";
import Footer from "../components/footer";
import OrderFromPhone from "../components/orderFromPhone";
import ProductListComponent from "../components/ProductList";
import ShowMoreComponent from "../components/showMorePanelForList";
import SignInModal from "../components/signInModal";
import Cookies from "universal-cookie";
import MetaDecorator from '../components/MetaDecorator';
import { RootImgUrl } from "../constants/const";
import "../css/index.css";

const assetRootUrl = 'https://cdn.osudpotro.com/project_assets/'
const cookies = new Cookies();

const Home = () => {
  const currentUser = useSelector((state) => state.login.currentUser);
  const homeScreenData = useSelector((state) => state.home.homeData);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let [numOfProductsToShow, setNumOfProductsToShow] = useState(4);
  const [showModal, setShowModal] = useState(false);
  const [homeContent, sethomeContent] = useState([]);
  // const [retoken, setReToken] = useState("");
  const dispatch = useDispatch()

  const seoObj = {
    title: `Online Pharmacy | Buy Medicine Online In Bangladeshs | OsudPotro`,
    description: `Online Medicine orders: You can order medicines online with Osudpotro and they will be delivered to you within 2-5 hours in Dhaka Cities`,
    keywords:`pharmacy, online pharmacy, ordering medicines online`,
    og_url:`/`,
    og_imageUrl:`${RootImgUrl}ogimage/home-og.webp`
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767 && window.innerWidth < 991) {
        setNumOfProductsToShow(3);
        setWindowWidth(window.innerWidth);
      } else {
        setNumOfProductsToShow(4);
        setWindowWidth(window.innerWidth);
      }
    };
    window.addEventListener("resize", handleResize);
  }, [windowWidth]);

  useEffect(() => {
      if(currentUser.token !== undefined && homeScreenData.length === 0){
        const homeApiData = {
          homeActions: allActions.homeActions,
          currentToken: currentUser.token,
          _id:currentUser.user_id || currentUser._id || cookies.get("user_id"),
          dispatch,
        };
        handleHomeData.fetchHomeDataFromServer(homeApiData)
        handleHomeData.fetchSliderImagesFromServer(homeApiData)
      }
  },[currentUser, homeScreenData])

  const set = (n, arr, ...ins) => [...arr.slice(0, n), ...ins, ...arr.slice(n)];

  const getHomeScreenData = (homeScreenData) => {
    return homeScreenData.map((singleData, index) => {
      return singleData.category_data.length > 0 && (
          <Container key={singleData.cat_id} className={index === 0 && `pt-0`}>
            <Row
              className={`d-flex category-section ${
                index % 2 === 0 ? "flex-dir-row" : "flex-dir-row-rev"
              } ${index === 0 && "pt-0"} ${index === 2 && 'border-bottom-0'}`} 
            >
              <Col xl={2} lg={3} md={12} className="see-all-container">
                <ShowMoreComponent item={singleData} />
              </Col>
              <Col xl={10} lg={9} md={12} className="product-slider">
                <div
                  className={`home-product-list-cont ${
                    index % 2 === 0 ? "flex-dir-row" : "flex-dir-row-rev"
                  }`}
                >
                  {singleData.category_data.map((item, index) => {
                    let imageToShow =
                      (item &&
                        (item.dis_image ||
                          (item.images &&
                            item.images[0] &&
                            item.images[0].img) ||
                          item.doc_image)) ||
                      "";
                    let name =
                      (item &&
                        (item.dis_name ||
                          item.brand_name ||
                          item.prod_name ||
                          item.doc_name)) ||
                      "";
                    let description =
                      (item &&
                        (item.dis_desc ||
                          item.description ||
                          item.prod_desc ||
                          item.doc_designation)) ||
                      "";
                    let price =
                      (item &&
                        item.inventory &&
                        item.inventory[0] &&
                        item.inventory[0].price) ||
                      "";
                    let regular_price =
                      (item &&
                        item.inventory &&
                        item.inventory[0] &&
                        item.inventory[0].regular_price) ||
                      "";
                    let discount =
                      (item &&
                        item.inventory &&
                        item.inventory[0] &&
                        item.inventory[0].discount) ||
                      0;
                    return index < numOfProductsToShow ? (
                      <div key={`pl_${index}`} className="home-single-prod-cont">
                        <ProductListComponent
                          item={{
                            cat_name: singleData.cat_alias.toLowerCase().replace('_','-'),
                            cat_type: item.item_type,
                            cat_id: singleData.cat_id,
                            alias:item.alias,
                            is_showcasing:item.is_showcasing
                          }}
                          itemId={item._id}
                          imageToShow={imageToShow}
                          name={name}
                          description={description}
                          price={price}
                          regular_price={regular_price}
                          discount={discount}
                        />
                      
                      </div>
                    ) : <></>;
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        )
    })
  }
  

  const getAddBanner = (image, alt='', buttons = []) => {
    const mobileImage = `${RootImgUrl}appImage/call-online-doctor-mobile.webp`
    return <Container  className='add-section'>
            <Row>
              <Col className="pr-0 pl-0 position-relative col-gutter">
                <picture>
                  <source media="(max-width: 767.98px)" srcSet={mobileImage} />
                  <img src={image} alt={alt} className="img-fluid"/>
                </picture>
                {buttons.map((Button,i)=> Button)}
              </Col>
            </Row>
          </Container>
  }

  const handleVerify = (gToken) => {
    // setReToken(1)
  }
  
  useEffect(() => {
   if(homeScreenData.length>0){
    const callButton = <a className="btn button add-btn" href="tel:09610001122" data-rb-event-key="tel:09610001122">
      <Image src={`${assetRootUrl}call-button.png`} alt='Call Now'/></a>
    sethomeContent(set(3, getHomeScreenData(homeScreenData), getAddBanner(`${assetRootUrl}add-banner.jpg`, 'Online Doctor Banner',[callButton])) )
   }
  }, [homeScreenData]);

  return (
    <>
      <MetaDecorator seo = {seoObj} />
      <MainHeader data={homeScreenData} />
      <AdvertismentPanel />
        { homeContent }
      <OrderFromPhone />
      <Footer backToTopVisible={true} />
      <SignInModal show={showModal} />
      {/* <GoogleReCaptcha onVerify={handleVerify} /> */}
    </>
  );
};

export default Home;
