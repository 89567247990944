const cart = (cartData) => {
  return {
    type: "CART",
    cartData: cartData,
  };
};

const calculateCartTotal = (cartTotal) => {
  return {
    type: "CART_TOTAL",
    cartTotal: cartTotal,
  };
};

export default {
  cart,
  calculateCartTotal,
};
