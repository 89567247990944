import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import Geocode from "react-geocode";
import GoogleMaps from "../../../components/GoogleMap";
import Cookies from "universal-cookie";
import fireApi from "../../../redux/index";
import allActions from "../../../redux/actions";
import { isContactNumberInValid } from "../../../helpers/utils"

import "../../../css/addAddressModal.css";

const cookies = new Cookies();

const AddAddressModal = ({
  show,
  handleShow,
  editData = {},
  fromCheckout = false,
  forCustomer = false,
  setCustomerOrderAddress = null
}) => {
  let currentUser = useSelector((state) => state.login.currentUser);
  let currentAddressBook = (currentUser && currentUser.addressBook) || [];
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [googleMapActive, setGoogleMapActive] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState( "");
  const [addressErrorMsg, setAdressErrorMsg] = useState( "");
  const [isPrimary, setIsPrimary] = useState(false);
  const [houseNo, setHouseNo] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [nameError,setNameError] = useState(false)
  const [contactNumberError,setContactNumberError] = useState(false)
  const [addressError,setAddressError] = useState(false)

  useEffect(() => {
    if (editData) {
      setName(editData.name);
      setAddress(editData.address);
      setHouseNo(editData.address2);
      setIsPrimary(editData.is_primary);
      setEmail(editData.email);
      setContactNumber(editData.contact);
    }
  }, [editData]);

  useEffect(() => {
    if(name !== '') checkRequiredFields()
  }, [name]);

  useEffect(() => {
    if(address !== '') checkRequiredFields()
  }, [address]);

  useEffect(() => {
    if(contactNumber !== '') checkRequiredFields()
  }, [contactNumber]);

  const checkContactNumberValidity = contactNumber => {
    return contactNumber === '' ? 'Contact number is required': (isContactNumberInValid(contactNumber) ? 'Contact number is not valid' : false) 
  }

  const checkModalValidity = () => {
    return name === '' || address === '' || contactNumber === '' || 
    checkContactNumberValidity(contactNumber) 
  }

  const checkRequiredFields = () =>{
    name === '' ? setNameError('Name is required') : setNameError(false)
    address === '' ? setAddressError('Address is required') : setAddressError(false)
    setContactNumberError(checkContactNumberValidity(contactNumber))
  }

  const modalLabel = forCustomer ? 'Customer' : ''
  const modalPlaceHolder = forCustomer ? 'customer' : 'your'

  const clearModaldata = () =>{
    if(!editData){
      setName("");
      setAddress("");
      setHouseNo("");
      setIsPrimary(false);
      setEmail("");
      setContactNumber("");
    }
  }

  const handleCustomerAddress = (e) => {
    e.persist();
    checkRequiredFields()
    
    if(checkModalValidity()) return

    let dataBecomes = {};
    Geocode.fromAddress(address)
    .then( response => {
        const { lat, lng } = response.results[0].geometry.location;
        dataBecomes = {
          name,
          address,
          contact: contactNumber,
          email,
          is_primary: isPrimary ? "1" : "0",
          lat,
          long: lng,
          address2: houseNo,
        };
        return dataBecomes;
      },
      (error) => {
        console.error(error);
      }
    )
    .then((data) => {
      if (editData._id) { data["_id"] = editData._id }
      localStorage.setItem('customerOrderAddress',JSON.stringify(data))
      setCustomerOrderAddress(data)
      clearModaldata()
      setShowModal(false)
      handleShow(false)
      return true

    });

  }


  const handleAddAddress = (e) => {
    e.persist();
    checkRequiredFields()
    if(checkModalValidity()) return

    let dataBecomes = {};
    Geocode.fromAddress(address)
      .then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          dataBecomes = {
            name,
            address,
            contact: contactNumber,
            email,
            is_primary: isPrimary ? "1" : "0",
            lat,
            long: lng,
            address2: houseNo,
          };
          return dataBecomes;
        },
        (error) => {
          console.error(error);
        }
      )
      .then((data) => {
        let apiName = editData._id !== undefined ? "address/update" : "address/add_new";
        if (editData._id) {
          dataBecomes["address_id"] = editData._id;
        }

        return fireApi
          .callApiWithToken(
            apiName,
            data,
            currentUser.token || cookies.get("token"),
            currentUser._id || cookies.get("user_id")
          )
          .then((res) => {
            if (!res || !res.data || !res.data.status) {
              setAdressErrorMsg(res.data.message)
              return;
            }

            if (res && res.token_refreshed) {
              var after7Days = new Date();
              after7Days.setDate(after7Days.getDate() + 7);
              cookies.set("token", res.token, {
                path: "/",
                expires: after7Days,
              });
              dispatch(allActions.loginActions.currentUserToStore({...currentUser,token: res.token}));
              window.location.reload();
            }
            setAdressErrorMsg("")
            dispatch(
              allActions.loginActions.currentUserToStore({
                ...currentUser,
                addressBook: [...currentAddressBook, data],
                addressFromGoogle: null,
              })
            );
            
            if (res.data.data) {
              dispatch(
                allActions.loginActions.currentUserToStore({
                  ...currentUser,
                  addressBook: res.data.data,
                  addressFromGoogle: null,
                })
              );

            }
            clearModaldata()
            setShowModal(false)
            handleShow(false)
            window.location.reload()
            return true;
          });
      });
  };

  const handleClose = () => {
    if (!editData) {
      setName("");
      setAddress("");
      setHouseNo("");
      setIsPrimary(false);
      setEmail("");
      setContactNumber("");
      dispatch(allActions.loginActions.currentUserToStore({addressFromGoogle: null}));
    }
    setShowModal(false);
    handleShow(false);
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const onRadioChange = (e) => {
    if (e.target.value === "primary") 
      setIsPrimary(true);
    else 
      setIsPrimary(false);
    
  };

  const onHandleAddressUpdated = (mapActive, formattedAddress) => {
    setGoogleMapActive(mapActive);
    setAddress(formattedAddress);
  };

  return currentUser && (
    <Modal
      size="xl"
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
    >
      <Modal.Header className="add-address-modal-header" closeButton>
        <p>{editData._id !== undefined ? `Update`:`Add ${forCustomer ? 'Customer' : 'New'}`} Address</p>
      </Modal.Header>
      {!googleMapActive ? (
        <>
          <Modal.Body className="add-address-modal-form">
            {addressErrorMsg !== "" && <p class="form-error">{addressErrorMsg} <i className="fa fa-times error-close-btn" onClick={()=> setAdressErrorMsg("")}></i></p>}
            <div>
              <label>{modalLabel} Name</label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder={`Enter ${modalPlaceHolder} username`}
              />
              {nameError && <div className="help-block with-errors">{nameError}</div>}
            </div>
            <div>
              <label>{modalLabel} Address</label>
              <input
                value={address}
                onClick={() => setGoogleMapActive(true)}
                onChange={(e) => {
                  setAddress(e.target.value)
                }}
                type="text"
                placeholder={`Enter ${modalPlaceHolder} address`}
                autoComplete="off"
              />
              {addressError && <div className="help-block with-errors">{addressError}</div>}
            </div>
            <div className="radio-btns modal-flex-row">
              <div>
                <label className="cus-radio">
                  Use this address as {modalLabel.toLowerCase()} primary address.
                  <input
                    type="radio"
                    checked={isPrimary}
                    value={"primary"}
                    name="setAddress"
                    onChange={onRadioChange}
                  />
                  <span className="checkmark" style={{ marginTop: 0 }}></span>
                </label>
              </div>
              <div>
                <label className="cus-radio">
                  Use this address as {modalLabel.toLowerCase()} other delivery address.
                  <input
                    type="radio"
                    checked={!isPrimary}
                    value={"other"}
                    name="setAddress"
                    onChange={onRadioChange}
                  />
                  <span className="checkmark" style={{ marginTop: 0 }}></span>
                </label>
              </div>
            </div>
            <div className="modal-flex-row">
              <div>
                <label>House No. / Flat No.</label>
                <input
                  value={houseNo}
                  onChange={(e) => setHouseNo(e.target.value)}
                  type="text"
                  placeholder={`Enter ${modalPlaceHolder} house or flat no`}
                />
              </div>
              <div>
                <label>{modalLabel} Contact No.</label>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="contact-input-group">+88</span>
                  </div>
                  <input type="text" 
                  placeholder={`Enter ${modalPlaceHolder} contact number`} onChange={(e) => {
                    setContactNumber(e.target.value)
                    }} aria-describedby="basic-addon1" value={contactNumber} />
                  {contactNumberError && <div className="help-block with-errors">{contactNumberError}</div>}
                </div>
              </div>
            </div>
            <div>
              <label>{modalLabel} Email</label>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                type="email"
                placeholder={`Enter ${modalPlaceHolder} email`}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="add-address-modal-footer">
            <Button
              className="add-address-modal-footer-button"
              variant="secondary"
              onClick={!forCustomer ? handleAddAddress : handleCustomerAddress} 
            >
              {editData._id !== undefined ? 'Update':'Save'}
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body className="add-address-modal-form address-picker-modal">
            <GoogleMaps onAddressUpdated={onHandleAddressUpdated} handleMapActive={setGoogleMapActive}/>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};
export default AddAddressModal;
