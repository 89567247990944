import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Container, Row, Col } from "react-bootstrap";

import MainHeader from "../../components/MainHeader";
import ProfileDetails from "./profile";
import ProfileNav from "./profileNav";
import Footer from "../../components/footer";
import { useHistory } from "react-router-dom";
import "../../css/profile.css";
const cookies = new Cookies();

const Profile = () => {
  const [isGuestUser, setIsGuestUser] = useState(false);
  const userType = cookies.get("user_type");
  const history = useHistory();

  useEffect(() => {
    if (userType !== "user") setIsGuestUser(true);
  }, [userType]);

  return (
    <>
      {isGuestUser ? (
        history.push("/Login")
      ) : (
        <>
          <MainHeader />
          <Container className="profile-pages-content-area-container">
            <Row>
              <Col
                lg={3}
                md={12}
                className="profile-pages-content-area-item-nav"
              >
                <ProfileNav />
              </Col>
              <Col
                lg={9}
                md={12}
                className="profile-pages-content-area-item-content"
              >
                <ProfileDetails />
              </Col>
            </Row>
          </Container>
          <Footer backToTopVisible />
        </>
      )}
    </>
  );
};

export default Profile;
