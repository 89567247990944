import React,{ useState,useEffect} from "react";
import { useHistory ,Link } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import { Row, Col, Container, Nav} from "react-bootstrap";
import "../css/paymentConfirmation.css";
import Cookies from "universal-cookie";
import notificationBg from '../images/successBg.png';
const cookies = new Cookies();

const WalletRecharged = () => {
    const [isGuestUser, setIsGuestUser] = useState(false);
    let userType = cookies.get("user_type");
    const history = useHistory();


    useEffect(()=>{
        if (userType !== "user") setIsGuestUser(true);
    },[userType])

    useEffect(()=>{

    },[])

    return (
        <>
          {isGuestUser ? history.push("/Login")  : (
            <>
              <MainHeader />
              <div className="order-notification-area-section">
                <Container className="order-notification-area">
                    <Row>
                        <Col className="text-center order-notification confirmed">
                          <div className="order-notification-bg" style={{backgroundImage: `url(${notificationBg})`}}>

                          </div>
                          <i className="far fa-check-circle"></i>
                          <h2>Wallet Recharged</h2>
                          <span>
                              Thanks for using Osudpotro. Your request<br/>
                              has been successfull. 
                          </span>
                          <Nav.Link as={Link} to="/balance">Go to Wallet</Nav.Link>

                          
                        </Col>
                    </Row>
                </Container>
              </div>
              <div className="footer-gaph">
                
              </div>
          <Footer backToTopVisible={false} />
          </>
      )}
  </>
  );
};

export default WalletRecharged;



              