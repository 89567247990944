const defaultState = {currentUser: {}};

const login = (state = defaultState, action) => {
	switch (action.type) {
		case 'GET_GUEST_USER':
			// return state.currentUser.map(guestUser =>
			//     guestUser.id === action.userData.id ? guestUser : ''
			// )
			break;
		case 'CURRENT_USER':
			const currUser = {
				...state, currentUser: action.userData,
			}
			return currUser
		case 'UPDATE_CURRENT_USER':
			const updateUser = {
				...state, currentUser: action.userData,
			}
			return updateUser

		default:
			return state;
	}
};

export default login;
