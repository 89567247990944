import React from "react";
import { createStore, compose } from "redux";
import { Provider } from "react-redux";
import reducers from "./redux/reducers";
import WebRouter from "./router/route";

const enhancers = compose(
  window.devToolsExtension ? window.devToolsExtension() : (f) => f
);
let store = createStore(reducers, enhancers);

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <WebRouter />
      </div>
    </Provider>
  );
}

export default App;
