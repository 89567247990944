import React from "react";
import { NavLink } from "react-router-dom";
import "../css/showMorePanelForList.css";
import {getCatUrlData} from "../constants/const"

const ShowMoreComponent = ({ item }) => {
  //const urlObj = getCatUrlData(item.cat_name.split(" ").join("_"))
  const catAlias = item.cat_alias.split("_").join("-")
  const urlObj = getCatUrlData(catAlias)
  return (
    <div className="main-container">
      <h3>{item.cat_name}</h3>
      <p className="home-category-description">
       {item.cat_desc}
      </p>
      <NavLink
        to={urlObj.path}
        className="see-all-button"
      >
          See All
      </NavLink>
    </div>
  );
};

export default ShowMoreComponent;
