import React from "react";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";

import NoDataFound from "../../app/noDataFound";
import CartItem from "./cartItem";
import "../../css/cart/cart-container.css";

const cookies = new Cookies();
const CartListCont = () => {
  const currentUser = useSelector((state) => state.login.currentUser);
  const currentCart = useSelector((state) => state.cart);
  const cartData = currentCart.cartData; 

  return (
    <>
      {cartData && cartData.length ? (
        cartData.map((cartProd, index) => {
          return (
            <div className="cart-prod-cont" key={cartProd._id}>
              <CartItem
                item={cartProd}
                img={cartProd.item_image}
                name={cartProd.item_name}
                desc={cartProd.item_desc}
                data={cartProd.item_data}
                currentUser={currentUser}
                cookies={cookies}
              />
            </div>
          );
        })
      ) : <NoDataFound />
      }
    </>
  );
};

export default CartListCont;
