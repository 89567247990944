import React, { useEffect, useRef, useState } from "react";
import { useHistory,Link } from "react-router-dom";
import { Nav, Button } from "react-bootstrap";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import { FiSearch } from "react-icons/fi";
import InfiniteScroll from "react-infinite-scroll-component";
import NumberFormat from 'react-number-format';
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import allActions from "../redux/actions";
import fireApi from "../redux/index";
import pro1Imgae from "../images/pro1.jpg";
import CallForPrice from './callPriceComponent';
import FormModal from './FormModalComponent'
import AlertModal from "../components/alertModal";
import "../css/searchComponent.css";
import { RootThumbImgUrl } from "../constants/const";

const cookies = new Cookies();

const SearchComponent = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const [searchValue, setSearchValue] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [prevSearch, setPrevSearch] = useState([]);
  const [hasContent,sethasContent] =  useState(false)
  const [onFocus,setOnFocus] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false);

  const handleSearch = (event) => {
    if (event && event.target.value === "") sethasContent(false);
    if (event) setSearchValue(event.target.value);

    let apiName = "home/search_sql";
    let params = {
      needle: event ? event.target.value : searchValue,
      page: "" + 0,
      limit: 20,
    };

    return fireApi
      .callApiWithToken(
        apiName,
        params,
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          setSearchData([]);
          return;
        }
        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }

        if (res.data.data.item_data && res.data.data.item_data.length <= 20) {
          setHasMore(false);
        }

        setSearchData(
          Array.isArray(res.data.data)
            ? res.data.data[0].item_data
            : res.data.data.item_data
        );
        sethasContent(event.target.value !== "")
      });
  };

  const handleOnItemClick = (item) =>{
    setOnFocus(false);
    // const {mongodb_id,item_type} = item
    // return fireApi
    // .callApiWithToken(
    //   "home/add_item_history",
    //   {mongodb_id,item_type},
    //   currentUser.token || cookies.get("token"),
    //   currentUser._id || cookies.get("user_id")
    // )
    // .then((res) => {
    //   if (!res || !res.data || !res.data.status) return;
    // });
  }

  const myRef = useRef();
    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            setOnFocus(false);
        }
    };
    
    const getPrevSeachData = () => {
      return prevSearch.map(srcItem => <li key={srcItem.mongodb_id}>{srcItem.item_name}</li>)
    }

    const handleClickInside = () => setOnFocus(true);

    const handleModalShow = () => {
      setOnFocus(false)
      if (currentUser._id === undefined || currentUser._id === "101") {
        setShowAlertModal(true)
      }else{
        setShowModal(true)
      }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });
    
  return (
    <>
    <div className="search-component" style={{ position: "relative" }}
    ref={myRef} 
    >
      <span className="search-container" onClick={handleClickInside}>
        <span className="search-icon">
          <FiSearch />
        </span>
        {/* <input className='search-input' placeholder="Search Medicine"/> */}
        <DebounceInput
          className={`search-input ${hasContent && onFocus? 'searching' : ''}`}
          minLength={1}
          debounceTimeout={400}
          onChange={(event) => handleSearch(event)}
          placeholder="Search by brand or generic"
        />
      </span>
      {hasContent && onFocus? (
        <div className="search-suggestion-box">
          <InfiniteScroll
            dataLength={searchData.length}
            next={handleSearch}
            hasMore={hasMore}
            scrollThreshold={1}
            loader={
              <h6
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  margin: 0,
                }}
              >
                loading...
              </h6>
            }
          >
            
          {searchData && searchData.length ? (
            <>
              {searchData.map((item, index) => {
                const imgUrl =
                  item.images && item.images.length
                    ? `${RootThumbImgUrl}${item.images[0].img}`
                    : `${RootThumbImgUrl}${pro1Imgae}`;

                const sectionStyle = {backgroundImage: `url(${imgUrl})`};
                const location = {
                  pathname: `/${item.item_type}/${item.cat_alias}/${item.alias}`,
                };
                
                return (
                    <Nav.Link as={Link} className="single-sch-item-area" to={location.pathname} key={index} onClick={() => handleOnItemClick(item)}>
                      <div  className="search-content">
                        <span
                          className="schItem schresultItemImage"
                          style={sectionStyle}
                        ></span>
                        <span className="schItem schresultItemDescription" title={item.item_name || ''}>
                          {item.item_name}
                          <span className="gener-name">{item.generic_or_category_name}</span>
                          <span className="gener-name">{item.manufacturer_name}</span>
                        </span>
                      </div>
                      {!item.is_showcasing ?
                      <span className="search-price">
                        <NumberFormat
                          value={item.sell_price}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={`৳`}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          type="currency"
                          renderText={(value) => value}
                        />
                        {
                        item.discount > 0 && 
                        <span className='regular-price'>
                          <NumberFormat
                            value={item.regular_price}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={`৳`}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            type="currency"
                            renderText={(value) => value}
                          />
                        </span>
                      }
                      </span>
                      : <CallForPrice />} 
                    </Nav.Link>
                );
              })}
            </>
          ) : 
          <div className="request-products p-3">
            <p>Sorry! We couldn't find your Medicine or Product. <br/>Do you want to make a request? Please &nbsp;
            <Button variant="link" className="theme-btn-link" onClick={handleModalShow}> Click here </Button></p>
          </div>
          }
          </InfiniteScroll>
          {/* <div className="prev-search">
            <div className="src-cont">
              <label>Previous Search:</label>
            </div>
            <ul className="src-data">
              {getPrevSeachData}
            </ul>
          </div> */}
        </div>
      ) : <></>}
    </div>
    <FormModal show={showModal} handleModalClose={() => setShowModal(false)}/>
    <AlertModal
        closeBtn
        show={showAlertModal}
        message="You need to signin first to request medicine or product"
        link='/login'
        linkText="Signin"
        handleModalClose={()=>setShowAlertModal(false)}
      />
    </>
  );
};

export default SearchComponent;
