import React from "react";
import {Container, Row, Col } from "react-bootstrap";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import MetaDecorator from '../components/MetaDecorator';
import ContactUsImg from "../images/contact_us.png";
import "../css/contactUs.css";

const OnlineDoctor = () => {
  const seoData = {
    title:`Consult Doctor Online | Get Expert Doctors Help | OsudPotro`,
    description:`OsudPotro brings you online Expert doctors consult in  Bangladesh anywhere anytime to get answers of your health problems and treatments on call and chat. So ask a doctor now!`,
    keywords: `doctor online, online doctor help, consult doctor online`,
    og_url:`/consult-doctor-online-get-expert-doctors-help`,
    og_imageUrl: ContactUsImg
  }

  return (
    <>
     <MetaDecorator seo = {seoData} />
     <MainHeader />
        <Container  className="contact-page-area">
            <Row >
              <Col lg={5} md={5} sm={12} className='align-self-center '>
                <img style={{ width: "100%" }} src={ContactUsImg} />
              </Col>
              <Col lg={7} md={7}sm={12} className='align-self-center '>
                 <a className=" call-btn btn btn-secondary" href="tel:09610001122">Call : 09610-00-11-22</a>
              </Col>
            </Row>
        </Container>  
        <Footer backToTopVisible />
    </>
  );
};

export default OnlineDoctor;
