const cart = (state = [], action) => {
    switch (action.type) {
    case 'CART':
        return {
            ...state,
            cartData: action.cartData
        }
        case 'CART_TOTAL':
            return {
                ...state,
                cartTotal: action.cartTotal
            }
    default:
        return state
    }
}

export default cart