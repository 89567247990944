import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import Geocode from "react-geocode";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import allActions from "../redux/actions";

Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);

const cookies = new Cookies();

const GoogleMaps = ({ onAddressUpdated,handleMapActive }) => {
  const dispatch = useDispatch();

  const [isGuestUser, setIsGuestUser] = useState(false);
  const [currentCords, setCurrentCords] = useState({});
  const [formattedAddress, setFormattedAddress] = useState("");

  useEffect(() => {
    let _id = cookies.get("user_id");
    if (!_id || _id === "101") setIsGuestUser(true);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentCords({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          (response) => {
            const address = response.results[0].formatted_address;
            setFormattedAddress(address);
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => alert(error.message),
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    );
  }, []);

  const mapStyles = {
    height: "500px",
    width: "100%",
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentCords({ lat, lng });
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setFormattedAddress(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const saveGoogleAddress = (formattedAddress) => {
    dispatch(
      allActions.loginActions.currentUserToStore({
        addressFromGoogle: formattedAddress,
      })
    );
    onAddressUpdated(false, formattedAddress);
  };
  const onChangeAddress = (e) => {
    setFormattedAddress(e.target.value);
  };
  return (
    // Important! Always set the container height explicitly
    <>
      <div
        style={{
          height: "100px",
          fontSize: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start"
        }}
        className="google-address-taker-modal"
      >
        <input
          style={{ width: "88%", marginRight: "2%" }}
          onChange={onChangeAddress}
          value={formattedAddress}
        />
        <button
          style={{ width: "5%", marginRight: "1%"}}
          className="btn btn-primary btn-theme adrress-save-google-btn"
          onClick={() => saveGoogleAddress(formattedAddress)}
        >
          Save
        </button>
        <button
          style={{ width: "4%" }}
          className="btn btn-primary btn-theme adrress-save-google-btn"
          onClick={() => handleMapActive(false)}
        >
          Cancel
        </button>
      </div>
      {currentCords && currentCords.lat && currentCords.lng && (
        <div style={{ height: "500px", width: "100%" }}>
          <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}>
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={13}
              center={currentCords}
            >
              <Marker
                position={currentCords}
                onDragEnd={(e) => onMarkerDragEnd(e)}
                draggable={true}
              />
            </GoogleMap>
          </LoadScript>
        </div>
      )}
    </>
  );
};

export default GoogleMaps;
