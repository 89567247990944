import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import CartProdQuantityMgt from "./cartProdQuantityMgt";
import fireApi from "../../redux/index";
import allActions from "../../redux/actions";
import CallForPrice from '../../components/callPriceComponent';
import "../../css/cart/cartItem.css";
import _ from 'lodash'
import { RootThumbImgUrl } from "../../constants/const";

var FontAwesome = require("react-fontawesome");

const CartItem = ({ item, img, name, desc, data, currentUser, cookies }) => {
  const dispatch = useDispatch();
  const [paramsArray, setParamsArray] = useState([]);
  const [openCartModal, setOpenCartModal] = useState(false);
  const [confirmWithCartModal, setConfirmWithCartModal] = useState(false);
  const [itemTotal, setItemTotal] = useState(0);
  const [regularTotal, setRegularTotal] = useState(0);
  const [totalNumOfItems, setTotalNumOfItems] = useState(0);
  const [hasDiscount, setHasDiscount] = useState(false);

  const currentCart = useSelector((state) => state.cart.cartData) || [];
  const isOfferApplied = useSelector(
    (state) => state.placeOrder.placeOrderData
  );
  const hideCartModal = () => setOpenCartModal(false);

  const handleCartItemDelete = () =>{
    fireApi
    .callApiWithToken(
      "cart/delete",
      {item_id:item.item_id},
      currentUser.token || cookies.get("token"),
      currentUser._id || cookies.get("user_id") || localStorage.getItem('user_id')
    )
    .then((res) => {
      if (!res || !res.data || !res.data.status) return;
      const newCartItems = _.filter(currentCart, cc => cc.item_id !== item.item_id)
      dispatch(allActions.cartActions.cart(newCartItems));
      setConfirmWithCartModal(false)
      return fireApi
        .callApiWithToken("cart/get_total",
          {},
          currentUser.token || cookies.get("token"),
          currentUser._id || cookies.get("user_id")
        )
        .then((res) => {
          if (!res || !res.data || !res.data.status) {
            return;
          }

          var { total, cart_count, delievery_fee, discount } = res.data.data;
          dispatch(
            allActions.cartActions.calculateCartTotal({
              sub_total: cart_count,
              delievery_fee: delievery_fee,
              discount: discount,
              cartTotal: total,
            })
          );
        });
    });
  }

  const handleClose = () => {
    setConfirmWithCartModal(false)
  }

  const handleAddCart = () => {
    setOpenCartModal(false);

    let paramsData = [];
    paramsArray.forEach((paramItem, index) => {
      paramsData[index] = {
        capacity: paramItem.capacity,
        qty: paramItem.qty,
        arrange: paramItem.arrange,
        item_variation_option_id: paramItem.item_variation_option_id,
        variation_option_id: paramItem.variation_option_id,
      };
    });

    return fireApi
      .callApiWithToken(
        "cart/add",
        {
          item_data: JSON.stringify(paramsData),
          item_id: item.item_id,
          item_type: item.item_type,
        },
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        let newcartForRedux = [...currentCart];
        currentCart &&
          currentCart.forEach((cart, cartIndex) => {
            if (cart._id === item._id) {
              newcartForRedux[cartIndex]["item_data"] = paramsArray;
            }
          });
        dispatch(allActions.cartActions.cart(newcartForRedux));

        return fireApi
          .callApiWithToken(
            "cart/get_total",
            {},
            currentUser.token || cookies.get("token"),
            currentUser._id || cookies.get("user_id")
          )
          .then((res) => {
            if (!res || !res.data || !res.data.status) {
              return;
            }

            var { total, cart_count, delievery_fee, discount } = res.data.data;
            dispatch(
              allActions.cartActions.calculateCartTotal({
                sub_total: cart_count,
                delievery_fee: delievery_fee,
                discount: discount,
                cartTotal: total,
              })
            );
            dispatch(
              allActions.placeOrderActions.placeOrder({
                ...isOfferApplied,
                cartTotal: total,
              })
            );
          });
      });
  };

  useEffect(() => {
    var tmpTotal = 0;
    var tmpRegularTotal = 0;
    var tmpItemTotal = 0;
    data &&
      data.forEach((dataItem) => {
        setHasDiscount(dataItem.discount !== 0)
        tmpTotal = tmpTotal + (dataItem.qty || 0) * (dataItem.price || 0);
        tmpRegularTotal += (dataItem.qty || 0) * (dataItem.regular_price || 0);
        tmpItemTotal = tmpItemTotal + (dataItem.qty || 0);
        setItemTotal(tmpTotal);
        setRegularTotal(tmpRegularTotal)
        setTotalNumOfItems(tmpItemTotal);
      });
  }, [data]);


  return (
    <Row className="cart-order-desc px-3 cart-added-product-area">
      <Col lg={12} md={12} sm={12} className="cart-order-detail">
        <div className="container-fluid cart-container">
          <Row>
            <Col xl={3} lg={3} md={3} sm={2} xs={2}
              className="cart-prod-image "
              style={{ backgroundImage: `url(${RootThumbImgUrl}${img})` }}
            >
              <FontAwesome name="times" onClick={() => setConfirmWithCartModal(true)} />
            </Col>
            <Col xl={6} lg={6} md={6} sm={8} xs={8} className="cart-prod-desc">
              <h5>{name || ""}</h5>
              <p>{decodeURIComponent(desc) || ""}</p>

              {!item.is_showcasing ?
                <p className="product-price">
                  <NumberFormat
                    value={itemTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={`৳`}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="currency"
                    renderText={(value) => value}
                  />
                  {
                hasDiscount && 
                <span className='regular-price'>
                  <NumberFormat
                    value={regularTotal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={`৳`}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    type="currency"
                    renderText={(value) => value}
                  />
                </span>
              }
              </p>
              : <CallForPrice />}
            
              <ul className="purchased-prod-list-in-cart">
                {data &&
                  data.map((dataItem, index) => {
                    return (
                      <div key={index}>
                        {dataItem.qty ? (
                          <li key={`di_${index}`}>
                            
                            {dataItem.capacity || ""} x {dataItem.qty} {!item.is_showcasing && 
                            <> (
                              <NumberFormat
                                value={dataItem.price || 0}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={`৳`}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                type="currency"
                                renderText={(value) => value}
                              /> x {dataItem.qty} 
                              ) 
                            </>}
                          </li>
                        ) : <></>
                        }
                      </div>
                    );
                  })}
              </ul>
            </Col>
            <Col
              xl={3}
              lg={3}
              md={3}
              sm={2}
              xs={2}
              className="cart-order-detail text-right"
            >
              <div className="cart-qty">
                <span className="counter-input">
                  <span
                    className="cntrl-btn plus-btn"
                    onClick={() => setOpenCartModal(true)}
                  >
                    <FontAwesome name="plus" />
                  </span>
                  <input value={totalNumOfItems || 0} readOnly />
                  <span
                    className="cntrl-btn minus-btn"
                    onClick={() => setOpenCartModal(true)}
                  >
                    <FontAwesome name="minus" />
                  </span>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </Col>

      <Modal
        show={openCartModal}
        onHide={hideCartModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <CartProdQuantityMgt item={item} setParamsArray={setParamsArray} />
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            className="guest-modal-footer-button"
            variant="secondary"
            onClick={handleAddCart}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={confirmWithCartModal} onHide={handleClose} keyboard={false}  centered={true}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="guest-modal-message">
            Do you want to remove the item from cart?
        </Modal.Body>
        <Modal.Footer
          className="guest-modal-footer"
          style={{ justifyContent: "center" }}
        >
          <Button
            className="guest-modal-footer-button"
            variant="secondary"
            onClick={() => handleCartItemDelete()}
          >
            Yes
          </Button>
          <Button
            className="guest-modal-footer-button"
            variant="secondary"
            onClick={() => setConfirmWithCartModal(currConfirmWithCartModal => !currConfirmWithCartModal)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

    </Row>
  );
};

export default CartItem;
