const handleSocket = (state = {}, action) => {
    switch (action.type) {
    case 'SOCKET':
        return {
            ...state,
            socketData: action.socketData
        }
    default:
        return state
    }
}

export default handleSocket