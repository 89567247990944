import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import ReactImageZoom from "react-image-zoom";
import ShowMoreText from "react-show-more-text";
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import Cookies from "universal-cookie";
import NumberFormat from "react-number-format";
import Swiper from "react-id-swiper";
import SignInModal from "../components/signInModal";
import ArrangeForMeModal from "../components/arrangeForMeModal";
import allActions from "../redux/actions";
import DetailsImage from "../images/product-details.png";
import { RootImgUrl } from "../constants/const";
import { isTypeProductCategory } from "../helpers/utils"
import MetaDecorator from '../components/MetaDecorator';
import CallForPrice from '../components/callPriceComponent';
import Swal from 'sweetalert2'
import fireApi from "../redux/index";
import _ from 'lodash'

import "swiper/swiper.scss";
import "../css/orderDelivery.css";
import "../css/productDetailComponent.css";


var FontAwesome = require("react-fontawesome");
const cookies = new Cookies();
const product_cookie_info = cookies.get("required_prescription_id")

const ProductDetailComponent = ({ cat_alias, cat_type, alias }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const currentCart = useSelector((state) => state.cart);
  const swipperParams = {slidesPerView: 3,spaceBetween: 10,pagination: false};

  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showArrangeForMeModal, setShowArrangeForMeModal] = useState(false);
  const [prescriptionRequired, setPrescriptionRequired] = useState(false);
  const [isWarningShowed,setIsWarningShowed] = useState(false)

  const [imageField, setImageField] = useState();
  const [productDetail, setProductDetail] = useState(null);
  const [cartTotal, setCartTotal] = useState(0);
  const [inventory, setInventory] = useState([]);
  const [cartInventory,setCartInventory] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  const [arrangeForMe, setArrangeForMe] = useState(false)
  const [productDiscount, setProductDiscount] = useState(0)
  const [seoData,setSeoData] = useState(null)

  useEffect(()=>{
    if(currentUser._id !== undefined && product_cookie_info && productDetail !== null){
      setIsWarningShowed(product_cookie_info.user_id === currentUser._id && product_cookie_info.id === productDetail._id)
    }
  },[currentUser,product_cookie_info,productDetail])

  useEffect(()=>{
    if(currentCart && currentCart.cartData && productDetail !== null){
      const cartInv = _.find(currentCart.cartData, cd => cd.item_id == productDetail._id )
      if(cartInv) setCartInventory(cartInv.item_data)
    }
  },[currentCart,productDetail])

  useEffect(() => {
    const apiData = {name:`${isTypeProductCategory(cat_type) ? 'products' : cat_type}/get_by_alias`,params:{ alias: alias }}
    if(cat_alias === "prescription-medicines" || (cat_alias === "otc-medicines" && cat_type !== "" && cat_type !== "disease" && cat_type !== "prescribed")){
      apiData.name = "medicines/get_by_alias"
      apiData.params = { alias: alias }
    }
    const authData = {
      token: cookies.get("token"),
      _id: cookies.get("user_id") || localStorage.getItem("user_id")|| "101",
    }

    fetchdataFromServer(apiData,authData);
  }, [cat_alias,cat_type,alias]);

  useEffect(() => {
    if(productDetail !== null ){
      setInventory(productDetail.inventory)
      setCartTotal(productDetail.cart_total);
      setSeoData({
        title:productDetail.seo_title|| productDetail.brand_name || productDetail.prod_name,
        description:decodeURIComponent(productDetail.prod_desc || productDetail.description),
        keywords:productDetail.seo_keywords,
        og_url:`/${cat_type}/${cat_alias}/${alias}`,
        og_imageUrl:`${RootImgUrl}${productDetail.images[0].img}`
      })
    }
    if (productDetail !== null && productDetail.images && productDetail.images.length) {
      setImageField(productDetail.images[0].img);
    }
  }, [productDetail]);

  const handleArrangeForMeClose = () => setShowArrangeForMeModal(false);

  const fetchdataFromServer = (apiData,authData) => {
    if (!apiData.name) return;

    return fireApi
      .callApiWithToken(
        apiData.name,
        apiData.params,
        currentUser.token || authData.token,
        currentUser._id || authData._id
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) return;

        if (res && res.token_refreshed) {
          var after7Days = new Date();
          after7Days.setDate(after7Days.getDate() + 7);
          cookies.set("token", res.token, { path: "/",expires: after7Days });
          dispatch( allActions.loginActions.currentUserToStore({token: res.token}));
        }
        setProductDetail(res.data.data);
        setProductDiscount(res.data.data.inventory['0'].discount)
        setPrescriptionRequired(res.data.data.is_prescription_required === 1)
      });
  };

  const getCartDataFromServer = async () => {
    return await fireApi
      .callApiWithToken(
        "cart/get", {},
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id"),
        dispatch
      ).then( async (res) => {
        if (!res || !res.data || !res.data.status) return;
        if (res && res.token_refreshed) {
          await LogicalFuncs.storeRefreshToken({ res, dispatch, allActions, cookies});
        }
        await dispatch(allActions.cartActions.cart(res.data.data));
      });
  };

  const incrementProduct = (index, pid, operator = "p") => {
    if (!currentUser || currentUser._id === "101") {
      setShowSignInModal(true);
      return;
    }

    if (prescriptionRequired && operator === "p" && !isWarningShowed) {
      var ex_date = new Date();
      ex_date.setDate(ex_date.getDate() + 1)
      Swal.fire('Warning', 'This medicine required prescription', 'warning')
      cookies.set("required_prescription_id", {id:alias,user_id:currentUser._id}, { path: "/", expires: ex_date });
      setIsWarningShowed(true)
    }
    setActiveIndex(index)

    let tempArray = [...inventory];

    tempArray[index] = {
      ...inventory[index],
      in_cart:operator === "p"
          ? inventory[index].in_cart + 1
          : inventory[index].in_cart - 1 < 0
          ? 0
          : inventory[index].in_cart - 1,
    };
    setInventory(tempArray);

    const cartApiName = "cart/add";
    let paramsData = [];

    tempArray.map((paramItem, index) => {
      paramsData[index] = {
        capacity: paramItem.capacity,
        qty: paramItem.in_cart,
        arrange: paramItem.arrange,
        item_variation_option_id: paramItem.item_variation_option_id
          ? paramItem.item_variation_option_id
          : null,
        variation_option_id: paramItem.variation_option_id
          ? paramItem.variation_option_id
          : null,
      };
    });

    return fireApi
      .callApiWithToken(
        cartApiName,
        {
          item_data: JSON.stringify(paramsData),
          item_id: pid,
          item_type:(cat_alias === "prescription-medicines" || cat_alias === "otc-medicines" ) ? "medicine" : cat_type,
        },
        currentUser.token,
        currentUser._id
      )
      .then((res) => {
        if(!res.data.status){
          let tempArray = [...inventory];
          tempArray[index] = {
            ...inventory[index],
            in_cart:0,
            qty_in_pcs:0
          };
          setInventory(tempArray);
          Swal.fire('Error', res.data.message, 'error')
          return
        }else{
          if (res.data && res.data.data) {
            setCartTotal(res.data.data.cart_total || 0);
            dispatch(allActions.cartActions.calculateCartTotal(res.data.data.cart_total || 0));
            getCartDataFromServer()
          }
        }
        
    });
  };

  const purchaseTypeChange = (e) => {
    e.persist();
    setActiveIndex(parseInt(e.target.value));
  };

  const loadImageToMainCont = (img) => {
    setImageField(img);
  };

  const handleArrangeForMe = () => {
    let tmpArray = [...inventory];
    tmpArray[activeIndex] = {
      ...tmpArray[activeIndex],
      arrange: !tmpArray[activeIndex].arrange,
    };
    setInventory(tmpArray);
    setArrangeForMe(arrangeForMe => !arrangeForMe)
  };
  
  const getProductDescription = detail =>{
    if(detail.prod_desc !== undefined)
      return detail.prod_desc
    
    if(detail.description !== undefined)
      return detail.description
  }

  return (
    <>
    {seoData !== null && <MetaDecorator seo = {seoData} /> }
    <Container className="product-details-inner-page">
      <Row style={{ margin: "0px" }}>
        <Col lg={4} className="product-image-details-inner-page">
          <div>
            <div className="product-image-cont-compo">
              {productDiscount > 0 && <div className="discount-percent">{productDiscount}%<br/>Off</div> }
              {imageField && (
                <ReactImageZoom
                  {...{
                    width: 400,
                    zoomWidth: 800,
                    img: `${RootImgUrl}${imageField}`,
                    zoomPosition: "original",
                    scale: 1,
                  }}
                />
              )}
            </div>
            <div className="image-swipper-cont">
              <Swiper {...swipperParams}>
                {productDetail !== null &&
                  productDetail.images &&
                  productDetail.images.map((singleImg, imgIndex) => (
                    <div
                      className="image-swipper"
                      onClick={() =>
                        loadImageToMainCont(productDetail.images[imgIndex].img)
                      }
                      key={imgIndex}
                    >
                      <img src={`${RootImgUrl}${productDetail.images[imgIndex].img}`} />
                    </div>
                  ))}
              </Swiper>
            </div>
          </div>
        </Col>
        {productDetail !== null && (
          <Col lg={8} className="product-info-cont-compo product-details-details-inner-page" >
            <Row>
              <Col lg={10}>
                <h3 className="product-info-heading-compo">
                {productDetail.brand_name || productDetail.prod_name || ""}
                </h3>
                {(productDetail.generic_name !== undefined && productDetail.generic_name !== null && (cat_alias === "prescription-medicines" ||cat_alias === "otc-medicines")) && 
                  <div className="generes-wrap">
                    <strong>Generics:</strong>  
                    <ul className="generes">
                      {productDetail.generic_name.map(gener => <li>{gener}</li>)}
                    </ul>
                  </div>}
                <p className="menufacturer">{productDetail.manufacturers} </p>
                {!productDetail.is_showcasing ? <p className="product-price-info product-price ">
                  {(inventory  && inventory[activeIndex] &&
                  <>
                    <NumberFormat
                      value={inventory[activeIndex].price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={`৳`}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      type="currency"
                      renderText={(value) => value}
                    />
                    {
                      inventory[activeIndex].discount > 0 && 
                      <span className='regular-price'>
                        <NumberFormat
                          value={inventory[activeIndex].regular_price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={`৳`}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          type="currency"
                          renderText={(value) => value}
                        />
                      </span>
                    }
                  </>
                  ) ||
                    0}
                </p> : <></> }
                {productDetail.is_showcasing ? <CallForPrice /> : <></>}
              </Col>
              <Col lg={2}>
                {(productDetail.item_desc_pdf !== undefined && productDetail.item_desc_pdf.length>0 ) && <a href={`${RootImgUrl}${productDetail.item_desc_pdf[0].pdf}`} className="details-link" target="_blank">
                  <img src={DetailsImage} className="img-fluid"/>
                    See Details
                </a>}
              </Col>
            </Row>
          
            {/* ======================= INVENTORY ======================= */}
            
            {/* <span className="pro-purchase-info-cont-compo">
              <span className="product-stock-info-compo">
                <p className="stock-out-text">
                  {isStockAvailable() ? "In Stock" : "Out Of Stock"}
                </p>

                {isStockAvailable() ? <></> : 
                  <label className="container arrange-label">
                    <input
                      className="arrange-for-me-box"
                      type="checkbox"
                      value={ arrangeForMe }
                      checked={ arrangeForMe }
                      onChange={() => handleArrangeForMe() }
                    />
                    <span className="arrange-for-me-checkmark"></span> Arrange for me
                  </label>
                }
              </span>
            </span> */}

            <div style={{ marginTop: "30px" }} className="">
              {inventory &&
                inventory.map((purchaseType, index) => (
                  <Row className="radio-item_wrap" key={index}>
                    <Col lg={6} md={6} sm={9} xs={6} className="radio-item_left">
                      <label className="cus-radio radio-label-text">
                        {purchaseType && purchaseType.variation_option_name}
                        <input
                          type="radio"
                          checked={index === activeIndex}
                          value={index}
                          name="radio"
                          onChange={purchaseTypeChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </Col>
                    <Col lg={6} md={6} sm={3} xs={6} className="radio-item_right">
                      <div className="qty-control-button">
                        <span
                          onClick={() => incrementProduct(index,productDetail._id, "m")}
                        >
                          <FontAwesome
                            className="decrement-button"
                            name="minus"
                          />
                        </span>
                        <input
                          className="qyt-input"
                          value={
                            inventory &&
                            inventory[index].in_cart
                              ? inventory[index].in_cart
                              : 0
                          }
                          maxLength={4}
                          maxLength={1}
                          readOnly
                        />
                        <span
                          onClick={() => incrementProduct(index,productDetail._id, "p")}
                        >
                          <FontAwesome
                            className="decrement-button"
                            name="plus"
                          />
                        </span>
                      </div>
                    </Col>
                  </Row>
                ))}
                
              {inventory && _.find(inventory, item => item.in_cart !== 0 ) ? (
                <Row className="prod-control-buttons">
                  <NavLink to="/#">
                    <button className="btn buy-more-button ">Buy More</button>
                  </NavLink>
                  <NavLink to="/checkout">
                    <button className="btn btn-secondary details-checkout-btn ">
                      Checkout
                    </button>
                  </NavLink>
                </Row>
              ) : 
                <> </>
              }
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col lg={12}>
          <div className="product-description">
            <h5>
              {(productDetail !== null && (productDetail.prod_desc || productDetail.description)) &&
                "Description:"}
            </h5>
            <ShowMoreText
              lines={5}
              more="Show more"
              less="Show less"
              expanded={false}
              className='content-css'
              keepNewLines={true}
            >
              {decodeURIComponent(productDetail !== null && getProductDescription(productDetail)) }
            </ShowMoreText>
          </div>
        </Col>
      </Row>

      <SignInModal show={showSignInModal} setHandleClose={setShowSignInModal}/>
      <ArrangeForMeModal
        show={showArrangeForMeModal}
        handleArrangeForMeClose={handleArrangeForMeClose}
      />
    </Container>
    </>
  );
};

export default ProductDetailComponent;
