import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, NavLink, Button } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { getOrderStatus } from "./../helpers/trackOrderFunctions";
import { RootImgUrl, RootThumbImgUrl } from "../constants/const";
import { useHistory } from "react-router-dom";


const TrackOderItem = ({ item }) => {
  const history = new useHistory();
  let status = getOrderStatus(item.order_status);
  const [buttonTitle, setButtonTitle] = useState("");

  useEffect(() => {
    setTitle();
  }, []);

  const setTitle = () => {
    if (item.order_status < 3) {
      setButtonTitle("Cancel");
    } else {
      setButtonTitle("Track Order");
    }
  };
  const onClickTrackOrder = () => {
    if (item.order_status < 3) {
      //setButtonTitle("Cancel");
      history.push("/googleMapsTrackOrder");
    } else {
      history.push("/googleMapsTrackOrder");
    }
  };
  return (
    <Row className="profile-history-singel-order-area">
      <Col
        lg={2}
        xs={2}
        className="history-singel-medical-img-area align-self-center"
        style={{
          backgroundImage: `url("${RootThumbImgUrl}appImage/deli.png")`,
        }}
      ></Col>
      <Col
        lg={6}
        xs={5}
        className="history-singel-title-area align-self-center"
      >
        <span className="product-order-id">Order id:{item.order_ref}</span>
        <span className="product-order-date">{item.order_date}</span>
        <span className="order-denger-Btn"> {status}</span>
      </Col>
      <Col
        lg={4}
        xs={5}
        className="history-singel-pricing-area align-self-center"
      >
        <p className="pro-pricing-number">
          <NumberFormat
            value={item.grand_total}
            displayType={"text"}
            thousandSeparator={true}
            prefix={`৳`}
            decimalScale={2}
            fixedDecimalScale={true}
            type="currency"
            renderText={(value) => value}
          />
        </p>
        {/* <Button
          onClick={onClickTrackOrder}
          className="order-Agian-Btn"
          variant="secondary"
          size="sm"
        >
          {buttonTitle}
        </Button> */}
      </Col>
    </Row>
  );
};

export default TrackOderItem;
