import React,{ useState,useEffect} from "react";
import { useHistory ,Link } from "react-router-dom";
import notificationBg from '../images/errorBg.png';
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import { Row, Col, Container, Nav} from "react-bootstrap";
import "../css/paymentConfirmation.css";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const PaymentFail = () => {
    const [isGuestUser, setIsGuestUser] = useState(false);
    let userType = cookies.get("user_type");
    const history = useHistory();


    useEffect(()=>{
        if (userType !== "user") setIsGuestUser(true);
    },[userType])

    return (
        <>
          {isGuestUser ? (
            history.push("/Login")
          ) : (
            <>
             <MainHeader />
                <div className="order-notification-area-section">
                  <Container className="order-notification-area">
                      <Row>
                          <Col className="text-center order-notification not-confirmed">
                            <div className="order-notification-bg" style={{backgroundImage: `url(${notificationBg})`}}>

                            </div>
                            <i className="far fa-times-circle"></i>
                            <h2>Payment failed</h2>
                            <span>
                                Thanks for using Osudpotro. unfortunately <br/>
                                Your request has been failed
                            </span>
                            <Nav.Link as={Link} to="/trackOrder">Profile</Nav.Link>

                            
                          </Col>
                      </Row>
                  </Container>
                </div>
                <div className="footer-gaph">
                  
                </div>
            <Footer backToTopVisible={false} />
          </>
      )}
  </>
  );
};

export default PaymentFail;







