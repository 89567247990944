import React from "react";
import { Container } from 'react-bootstrap';

import noDataImage from '../images/no_data.png';

const NoDataFound = () => {
    return (
        <Container style={{ display: "grid", justifyContent: "center" }}>
            <img style={{ width: '70%', objectFit: 'cover', marginTop: '-75px' }} className='img-fluid' src={noDataImage} alt="No Data Found"/>
            <h3 style={{ paddingLeft: '15%' }}>No Data Found</h3>
        </Container>
    )
}

export default NoDataFound