const topNavHeader = (state = {}, action) => {
  switch (action.type) {
    case "NAV_HEADER":
      return {
        ...state,
        headerContent: action.headerContent,
      };

    default:
      return state;
  }
};

export default topNavHeader;
