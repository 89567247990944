import React, {useEffect } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import allActions from "../redux/actions";
import fireApi from "../redux/index";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const NavCartComponent = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const cartData = useSelector((state) => state.cart.cartData);
  const cartTotalFromRedux = useSelector((state) => state.cart.cartTotal) || 0;
  const fetchdataFromServer = async () => {
    return await fireApi
      .callApiWithToken(
        "cart/get", {},
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id"),
        dispatch
      ).then( async (res) => {
        if (!res || !res.data || !res.data.status) return;
        if (res && res.token_refreshed) {
          await LogicalFuncs.storeRefreshToken({ res, dispatch, allActions, cookies});
        }
        await dispatch(allActions.cartActions.cart(res.data.data));
      });
  };
  useEffect(() => {
    if(cookies.get("user_type") === 'user'){
      fetchdataFromServer();
    }
  }, [cartTotalFromRedux]);

  return (
    <Nav.Link as={Link} to="/cart">
      <i className="material-icons">shopping_cart</i>
      <span className="cart-qty">{cartData ? cartData.length : 0}</span>
      <span className="label">
        Cart
      </span>
    </Nav.Link>
  );
};

export default NavCartComponent;
