import React, { useState, useEffect } from "react";

import { Modal, Button } from "react-bootstrap";

import "../css/index.css";

const ArrangeForMeModal = ({ show, handleArrangeForMeClose }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };
  const handleOk = () => {
    handleArrangeForMeClose();
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      className="arrange-me-modal-area"
    >
      {/* <Modal.Header className="guest-modal-header" closeButton></Modal.Header> */}
      <Modal.Body className="guest-modal-message justify-content-center">
        Click on "<b>Arrange For Me</b>" If you want us to arrange this for you.
      </Modal.Body>
      <Modal.Footer
        className="guest-modal-footer"
        style={{ justifyContent: "center" }}
      >
        <Button
          className="guest-modal-footer-button"
          variant="secondary"
          onClick={handleOk}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ArrangeForMeModal;
