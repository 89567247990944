  const cartInventory = (state = [], action) => {
    switch (action.type) {
    case 'CART_INVENTORY':
        return {
            ...state,
            inventoryData: action.inventoryData
        }
    default:
        return state
    }
}

export default cartInventory