import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import fireApi from "../../../redux/index";
import allActions from "../../../redux/actions";
import AddAddressModal from "./addAddressModal";
import { localUserkey } from "../../../constants/const";
import GeneralAlertModal from "../../../components/GeneralAlertModal";
import "../../../css/orderDelivery.css";

const cookies = new Cookies();

const OrderDeliveryAddressList = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.login.currentUser);
  const addressBookRedux =
    useSelector((state) => state.login.currentUser.addressBook) || [];

  const addressFromGoogleRedux =
    useSelector((state) => state.login.currentUser.addressFromGoogle) || "";
  const [addressBook, setAddressBook] = useState(addressBookRedux || []);
  const [addAddress, setAddAddress] = useState(addressFromGoogleRedux ? true : false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [deleteAddressId, setDeleteAddressId] = useState("");
  const [deleteAddressIndex, setDeleteAddressIndex] = useState("");

  const [classifiedAddress, setClassifiedAddress] = useState({
    primary: [],
    other: [],
  });

  const [editData, setEditData] = useState({});

  const fetchAddressesFromServer = (apiname, parameter = {}) => {
    return fireApi
      .callApiWithToken(
        apiname,
        parameter,
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          var after7Days = new Date();
          after7Days.setDate(after7Days.getDate() + 7);
          cookies.set("token", res.token, {
            path: "/",
            expires: after7Days,
          });
          dispatch(allActions.loginActions.currentUserToStore({...currentUser,token: res.token}));
        }
        let apiResponse = res.data.data;

        if (apiResponse) {
          setAddressBook(apiResponse);
          dispatch( allActions.loginActions.currentUserToStore({...currentUser,addressBook: apiResponse}));
          localStorage.setItem(localUserkey,JSON.stringify({...currentUser,addressBook: apiResponse}))
        }
      });
  };

  useEffect(() => {
      fetchAddressesFromServer("address/get_all");
  }, []);

  useEffect(() => {
    if (addressBookRedux && addressBookRedux.length) {
      setAddressBook(addressBookRedux);
    }
  }, [addressBookRedux]);

  useEffect(() => {
    if (addressBook && addressBook.length) {
      let tmpObject = {};
      addressBook.map((item, index) => {
        let tmpItem = { ...item, addressBookIndex: index };
        if (item.is_primary && item.is_primary !== "0") {
          tmpObject["primary"] = [tmpItem];
        } else {
          tmpObject["other"] = tmpObject["other"]
            ? [...tmpObject["other"], tmpItem]
            : [tmpItem];
        }
      });
      setClassifiedAddress(tmpObject);
      if (!addressFromGoogleRedux || !addressFromGoogleRedux.length) {
        setAddAddress(false);
      } else {
        setAddAddress(true);
      }
    }
  }, [addressBook]);

  const deleteAddress = (address_id, addressIndex) => {
    if (address_id) {
      return fireApi
        .callApiWithToken(
          "address/delete",
          { address_id: address_id },
          currentUser.token || cookies.get("token"),
          currentUser._id || cookies.get("user_id")
        )
        .then((res) => {
          if (!res || !res.data || !res.data.status) {
            return;
          }

          if (res && res.token_refreshed) {
            var after7Days = new Date();
            after7Days.setDate(after7Days.getDate() + 7);
            cookies.set("token", res.token, {
              path: "/",
              expires: after7Days,
            });
            dispatch(allActions.loginActions.currentUserToStore({...currentUser, token: res.token}));
            localStorage.setItem(localUserkey,JSON.stringify({...currentUser, token: res.token}))
          }
          let apiResponse = res.data;
          if (apiResponse) {
            if (apiResponse.status) {
              let tmpAddressBook = [...addressBook];
              tmpAddressBook.splice(addressIndex, 1);
              setAddressBook(tmpAddressBook);
              dispatch(allActions.loginActions.currentUserToStore({...currentUser,addressBook: tmpAddressBook }));
              localStorage.setItem(localUserkey,JSON.stringify({...currentUser,addressBook: tmpAddressBook }))
            }
            if (apiResponse.data) {
              dispatch(allActions.loginActions.currentUserToStore({...currentUser,addressBook: apiResponse.data}));
              localStorage.setItem(localUserkey,JSON.stringify({...currentUser,addressBook: apiResponse.data}))
            }
            setShowAlertModal(false)
          }
        });
    } else {
      return;
    }
  };

  const handleOrderEdit = (address) =>{
    setAddAddress(true);
    setEditData(address);
  }
  const onCloseAlertModal = () => {
    setShowAlertModal(false)
  };

  const handleAddresDeleteModalShow = (address_id,addressIndex) =>{
    setShowAlertModal(true)
    setDeleteAddressId(address_id)
    setDeleteAddressIndex(addressIndex)
  }

  return (
    <>
      <Container>
        <Row className="address-area">
          <Col>
            <span>Primary Address</span>
            {classifiedAddress &&
              classifiedAddress["primary"] &&
              classifiedAddress["primary"].map((item) => (
                <Row>
                  <Col className="adress-name" xs={10}>
                    {item.address}
                  </Col>
                  <Col
                    className="adress-actiom-icon text-right"
                    xs={2}
                  >
                    <i className="fas fa-pencil-alt"
                    onClick={() => handleOrderEdit(item)}
                    ></i>
                    <i
                      className="far fa-trash-alt"
                      onClick={() => deleteAddress(item._id, item.addressBookIndex) }
                    ></i>
                  </Col>
                </Row>
              ))}
          </Col>
        </Row>
        <Row className="address-area other-adddress">
          <Col>
            <span>Other Address</span>
            {classifiedAddress &&
              classifiedAddress["other"] &&
              classifiedAddress["other"].map((item, addressIndex) => (
                <Row key={addressIndex}>
                  <Col className="adress-name" xs={10}>
                    {item.address}
                  </Col>
                  <Col
                    className="adress-actiom-icon text-right"
                    xs={2}
                  >
                    <i className="fas fa-pencil-alt" onClick={() => handleOrderEdit(item)}></i>
                    <i
                      className="far fa-trash-alt"
                      onClick={() => handleAddresDeleteModalShow(item._id, item.addressBookIndex)}
                    ></i>
                  </Col>
                </Row>
              ))}
          </Col>
        </Row>
        <Row className="address-area">
          <Col lg={12} sm={12} className="add-address-action">
            <button
              className="add-address-button"
              onClick={handleOrderEdit}
            >
              Add Address
            </button>
          </Col>
        </Row>
      </Container>
      <AddAddressModal
        show={addAddress}
        handleShow={setAddAddress}
        editData={editData}
      />
      <GeneralAlertModal
          show={showAlertModal}
          buttonText="Yes"
          message="Are you sure that you want to delete this address?"
          handleModalClose={onCloseAlertModal}
          handlePositiveAction={
            () => deleteAddress(deleteAddressId, deleteAddressIndex)
          }
        />
    </>
  );
};

export default OrderDeliveryAddressList;
