import React, { useEffect } from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import "../css/footerPagesStyles.css";
import {
  Row,
  Col,
  Container
} from "react-bootstrap";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MainHeader />
      <Container className="footer-related-page-area">
        <Row>
          <Col>
            <span className="page-title">Refund policy</span>
            <span className="page-subtitles-bold">
              We thank you for choosing Osudpotro.
            </span>
            <p>
              If you are not completely satisfied with your purchase and
              delivery, we are here to help.
            </p>
            <span className="page-paragraph-title">Return</span>
            <p>
              To be eligible for a return, you have to prove that wrong
              medicine, wrong product or any item different than what you
              originally ordered was delivered to you. Your item must be in the
              same condition that you received it. Your item must be in original
              medicine. You must put a “request for return” in the complaint box
              on the dashboard within 1 hour of receiving the item you want to
              return.
            </p>
            <span className="page-paragraph-title">Refund</span>
            <p>
              Once we receive your item, we will notify you and inspect it. We
              will immediately notify you on the status of your refund after our
              inspection is done. If your return is approved, we will initiate a
              refund to your credit card (or original methods of payment) you
              will receive the credit within a 30 days or less, depending on
              your card user’s policy.
            </p>
            <span className="page-paragraph-title">Delivery</span>
            <p>
              Delivery costs are non-refundable. If you receive a replacement
              for your returned item, the cost of return delivery will be
              deducted from your refund.
            </p>
            <span className="page-paragraph-title">Contact us</span>
            <p>
              If you have any questions on how to return your medicine to us,
              contact us.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer backToTopVisible={true} />
    </>
  );
};

export default RefundPolicy;
