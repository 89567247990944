import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal} from 'react-bootstrap';
import Cookies from "universal-cookie";
import AddAddressModal from "./orderDelivery/addAddressModal";
import fireApi from "../../redux/index";
import allActions from "../../redux/actions";
import profilePic from "../../images/profile.png";
import { RootImgUrl,localUserkey } from "../../constants/const";
import LogicalFuncs from '../../logicalFunctions/handleHomeData';
import Swal from 'sweetalert2'
import GoogleMaps from "../../components/GoogleMap";
import _ from 'lodash'
import "../../css/profile.css";

var FontAwesome = require("react-fontawesome");
// let localUserDetails = JSON.parse(localStorage.getItem(localUserkey))
const cookies = new Cookies();

const ProfileDetails = () => {
  const inputFile = React.createRef();
  const currentUser = useSelector((state) => state.login.currentUser);
  const dispatch = useDispatch();
  const [file, setFile] = useState({});
  const [show, setShow] = useState(false);
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const addressFromGoogleRedux = useSelector((state) => state.login.currentUser.addressFromGoogle) || "";
  const [googleMapActive, setGoogleMapActive] = useState(false);
  const [address, setAddress] = useState(currentUser.addressFromGoogle || "");
  const [addAddress, setAddAddress] = useState(addressFromGoogleRedux ? true : false);
  const [primaryAddress,setPrimaryAddress] = useState('')
  const fetchUserDetailFromServer = (apiname, parameter = {}) => {
    return fireApi
      .callApiWithToken(
        apiname,
        parameter,
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (res && res.token_refreshed) {
          var after7Days = new Date();
          after7Days.setDate(after7Days.getDate() + 7);
          cookies.set("token", res.token, {
            path: "/",
            expires: after7Days,
          });
          dispatch(allActions.loginActions.currentUserToStore({token: res.token,}));
        }

        return res.data;
      });
  }

  const fetchAddressesFromServer = (apiname, parameter = {}) => {
    return fireApi
      .callApiWithToken(
        apiname,
        parameter,
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          var after7Days = new Date();
          after7Days.setDate(after7Days.getDate() + 7);
          cookies.set("token", res.token, {
            path: "/",
            expires: after7Days,
          });
          dispatch(allActions.loginActions.currentUserToStore({token: res.token}));
        }
        let apiResponse = res.data.data;
        if (apiResponse) { 
          let newStorage = {...currentUser,addressBook: apiResponse}
          localStorage.setItem(localUserkey,JSON.stringify(newStorage))
          dispatch( allActions.loginActions.currentUserToStore(newStorage) );
          const prAddress = _.find(apiResponse, address => address.is_primary === 1)
          setPrimaryAddress(prAddress.address)
        }
      });
  };

  useEffect(()=>{
    if(currentUser.name !== undefined && currentUser.email !== undefined){
      setNewName(currentUser.name)
      setNewEmail(currentUser.email)
    }
    if(currentUser._id && currentUser._id !== 101 && currentUser.addressBook === undefined){
      fetchAddressesFromServer("address/get_all")
    }else{
      if(currentUser.addressBook !== undefined){
        let prAddress = _.find(currentUser.addressBook, address => address.is_primary === 1)
        setPrimaryAddress(prAddress ? prAddress.address : '')
      }
      
    }
  },[currentUser])


  const handleChangeDetails = (detail_type, e) => {
    if (detail_type === "name") {
      setNewName(e.target.value);
    }
    if (detail_type === "email") {
      setNewEmail(e.target.value);
    }
  }

  const saveDetail = async (detail_type) => {
    let newState = detail_type === "name" ? newName : newEmail
    let response = await fetchUserDetailFromServer(
      `users/change_${detail_type}`, {
        [detail_type]: newState,
      }
    );

    if (response.status) {
      if(detail_type === "name")
        setNewName(newName)
      else
        setNewEmail(newEmail)
      
      const newLocaStorage = {
        ...currentUser, [detail_type]: newState,
      }

      localStorage.setItem(localUserkey,JSON.stringify(newLocaStorage))
      dispatch(allActions.loginActions.currentUserToStore(newLocaStorage));
    }else{
      Swal.fire('Error', response.message, 'error')
    }
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      let files = e.target.files;
      reader.addEventListener("load", () => {
        file.path = reader.result;
        file.object = files[0];
        setFile({ ...file });
        uploadImageToServer(file)
      });
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onHandleAddressUpdated = (mapActive, formattedAddress) => {
    setGoogleMapActive(mapActive);
    setAddress(formattedAddress);
  }
  
  const uploadImageToServer = (file) => {

    return fireApi
      .uploadImages({
        images: Object.values(file),
        apiName: "users/upload/profile",
        currentToken: currentUser.token || cookies.get("token"),
        _id: currentUser._id || cookies.get("user_id"),
        image_id: "user_image",
      })
      .then((res) => {
        if (!res || !res.data || !res.data.status) return;

        if (res && res.token_refreshed) {
          var after7Days = new Date();
          after7Days.setDate(after7Days.getDate() + 7);
          cookies.set("token", res.token, {
            path: "/",
            expires: after7Days,
          })
          dispatch(allActions.loginActions.currentUserToStore({token: res.token}))
        }
        let apiResponse = res.data.data
        const newLocalStorage = { ...currentUser, image: apiResponse.image_url }
        localStorage.setItem(localUserkey,JSON.stringify(newLocalStorage))
        if (apiResponse) dispatch(allActions.loginActions.currentUserToStore(newLocalStorage));
      })
  }

  const deleteImageFromServer = () => {
		return fireApi.callApiWithToken(
			'users/delete_profile_image',{},
			currentUser.token || cookies.get('token'),
			currentUser._id || cookies.get('user_id')
		)
		.then((res) => {
			if (!res || !res.data || !res.data.status) return;
			if (res && res.token_refreshed) LogicalFuncs.storeRefreshToken({res,dispatch,allActions,cookies})
      
      const newLocaStorage = {...currentUser, image: null}
      localStorage.setItem(localUserkey,JSON.stringify(newLocaStorage))
      dispatch(allActions.loginActions.currentUserToStore(newLocaStorage));
      setFile({})
      // window.location.reload()
		});
	};


  return (
    <div className="profile-component">
      <div className="title-heading">Profile</div>
      <div className="identity-sec personal-details-sec">
        <div
          className="profile-picture"
          style={{
            backgroundImage: `url(${file.path ? file.path : ((currentUser.image !== undefined && currentUser.image !== '' && currentUser.image !== null) ? RootImgUrl+currentUser.image : profilePic )})`,
          }}
        >
          <i
            className="fas fa-camera uplad-btn"
            onClick={() => inputFile.current.click()}
          ></i>
          {currentUser.image !== undefined && currentUser.image !== '' && currentUser.image !== null ? <i className="material-icons delete-icon"
          onClick={() => deleteImageFromServer()}
          >delete</i> : ''}
        </div>
        <input
          type="file"
          id="file"
          ref={inputFile}
          onChange={onSelectFile}
          accept="image/*"
          style={{ display: "none" }}
        />
        <div
          style={{ marginLeft: "60px" }}
          className="personal-detail-section-row personal-detail-section-row-user-name"
        >
          <span>Name</span>
          <input
            value={ newName }
            placeholder="username"
            name="name"
            onChange={(e) => handleChangeDetails("name", e)}
            autoComplete="off"
          />
          {newName && newName !== (currentUser.name || '') && (
            <span onClick={() => saveDetail("name")}>
              <FontAwesome
                style={{ fontSize: "25px", cursor: "pointer" }}
                name="check"
              />
            </span>
          )}
        </div>
      </div>
      <div className="personal-details-sec">
        <div className="personal-detail-section-row">
          <span>Address</span>
          <p>
            <input
              value={primaryAddress || ''}
              placeholder="Address"
              readOnly
              title={primaryAddress || ''}
              // onClick={() => setGoogleMapActive(true)}
              // onClick={() => setShow(true)}
            />
          </p>
        </div>
        <div
          className="personal-detail-section-row"
        >
          <div>
            <span>Contact Number</span>
            <p>
              <input
                value={currentUser.mobile || ''}
                placeholder="Contact Number"
                readOnly
              />
            </p>
          </div>
        </div>
        <div
          className="personal-detail-section-row"
          style={{ borderBottom: "none" }}
        >
          <span>Email</span>
          <p>
            <input
              value={newEmail}
              placeholder="Email"
              name="email"
              onChange={(e) => handleChangeDetails("email", e)}
            />
            {newEmail && newEmail !== (currentUser.email || '') && (
              <span onClick={() => saveDetail("email")}>
                <FontAwesome
                  style={{ fontSize: "25px", cursor: "pointer" }}
                  name="check"
                />
              </span>
            )}
          </p>
        </div>
      </div>
      <AddAddressModal show={addAddress} handleShow={setAddAddress} />
      <Modal show={show} keyboard={true} onHide={() => setShow(false)}  size="xl" centered className="address-picker-modal-area">
					<Modal.Header closeButton>
					<Modal.Title>Add Address</Modal.Title>
					</Modal.Header>
					  <Modal.Body >
              <GoogleMaps onAddressUpdated={onHandleAddressUpdated}/>
					</Modal.Body>
			</Modal>
    </div>
  );
};

export default ProfileDetails;
