import React,{useState, useEffect, useLayoutEffect} from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { Row, Col,ListGroup,NavLink,Nav} from 'react-bootstrap';
import { Link as PageLink } from "react-router-dom";
import { Collapse } from 'antd';
import StickyChat from '../components/stickyChatComponent'
import 'antd/dist/antd.css';
import "../css/profileFooter.css";
import sslComrz from "../images/ssl.webp";
import arrowTop from "../images/arrow-to-top.svg";

const { Panel } = Collapse;

const Footer = ({ backToTopVisible }) => {
    const [showArrowTop,setShowArrowTop] = useState(false);
    const [scrollPosition, setPosition] = useState(0);

    useLayoutEffect(() => {
        function updatePosition() {
            setPosition(window.pageYOffset);
        }
        window.addEventListener('scroll', updatePosition);
        updatePosition();
        return () => window.removeEventListener('scroll', updatePosition);
    }, []);
    
    useEffect(() => {
        if(scrollPosition > 300 && !showArrowTop) setShowArrowTop(true)
        if(scrollPosition < 200 && showArrowTop) setShowArrowTop(false)
    }, [scrollPosition]);

    return (
        <>
            {backToTopVisible && <Link
                className="active"
                to="root"
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
                className="backToTop"
            >Back To Top</Link>}
            
            <div className={`move-top-top ${showArrowTop ? 'show' : '' }`} ><Link to="root" spy={true} smooth={true}><img src={arrowTop} /></Link></div> 

            <footer id="footer" className="py-5">
                <div className="container">
                    <Row>
                        <Col lg={4} md={12} className="about-osudpotro">
                            <h2>About OsudPotro</h2>
                            <p>osudpotro provides the best pharmacy solution online. We are focused on getting you the healthcare support & help you need so that you can enjoy your best health. Find your required medications at osudpotro.  </p>
                            <div className="social-media-links">
                                <ListGroup horizontal  as="ul">
                                    <ListGroup.Item  as="li">
                                    <NavLink target="_blank" href="https://www.facebook.com/osudpotro/">
                                    <i className="fab fa-facebook-f"></i>
                                    </NavLink>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                    <NavLink target="_blank" href="http://instagram.com/osudpotrobd/">
                                    <i className="fab fa-instagram"></i>
                                    </NavLink>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                    <NavLink target="_blank" href="http://youtube.com/channel/UCUtwG4DKSJpZYr1ytoibc7g/?guided_help_flow=5&disable_polymer=true">
                                    <i className="fab fa-youtube"></i>
                                    </NavLink>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                    <NavLink target="_blank" href="http://linkedin.com/company/67491136">
                                    <i className="fab fa-linkedin"></i>
                                    </NavLink>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </Col>
                        <Col lg={8} md={12} sm={12}className="d-flex flex-row footer-links">
                            <div className="footer-links-column">
                                <h2 className="footer-title">Categories</h2>
                                 <ListGroup  as="ul">
                                    <ListGroup.Item  as="li">
                                        <Nav.Link as={PageLink} to="/buy-over-the-counter-medicine-online-bangladesh">
                                            OTC Medicines
                                        </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                        <Nav.Link as={PageLink} to="/doctors-medicine-online-pharmacy-bangladesh">
                                            Prescription Medicines
                                        </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                    <Nav.Link as={PageLink} to="/kids-healthcare-products-online-pharmacy-bangladesh">
                                        Kid's Products
                                    </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                        <Nav.Link as={PageLink} to="/mens-healthcare-products-online-pharmacy-bangladesh">
                                           Men's Products
                                        </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                        <Nav.Link as={PageLink} to="/womens-healthcare-products-online-pharmacy-bangladesh">
                                            Women's Products
                                        </Nav.Link>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                            <div className="footer-links-column">
                                <h2 className="footer-title">Our Company</h2>
                                <ListGroup   as="ul">
                                    <ListGroup.Item  as="li">
                                        <Nav.Link as={PageLink} to="/consult-doctor-online-get-expert-doctors-help">
                                        Online Doctor's
                                        </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                    <Nav.Link as={PageLink} to="/privacy-policy">
                                      Privacy Policy
                                    </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                    <Nav.Link as={PageLink} to="/refund-policy">
                                      Refund Policy
                                    </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                    <Nav.Link as={PageLink} to="/disclaimer">
                                      Disclaimer 
                                    </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                    <Nav.Link as={PageLink} to="/contact-us-for-medicine-home-delivery">
                                      Contact
                                    </Nav.Link>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                            <div className="footer-links-column">
                                <h2 className="footer-title">Your Account</h2>
                                <ListGroup   as="ul">
                                    <ListGroup.Item  as="li">
                                        <Nav.Link as={PageLink} to="/profile">
                                        My Account
                                        </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                        <Nav.Link as={PageLink} to="/deliveryHistory">
                                        Order History
                                        </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                    <Nav.Link as={PageLink} to="/trackOrder">
                                      Track Orders
                                    </Nav.Link>
                                    </ListGroup.Item>
                                    <ListGroup.Item  as="li">
                                    <Nav.Link as={PageLink} to="/addresses">
                                      My Addresses
                                    </Nav.Link>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </Col>
                    
                        <Col lg={12} md={12} sm={12}  className="sslcommerz-banner text-center">
                            <img src={sslComrz} className="img-fluid footer-ssl"/>
                        </Col>
                    </Row>
                    
                </div>
            </footer>

            <div className="container">
                <Row className=" copy-right-area  text-center">
                    <Col>
                        <span className="copy-text">
                            Copyright &copy; 2020    <NavLink href="#"> OsudPotro. </NavLink>  All rights reserved.
                        </span>
                    </Col>
                </Row>
            </div>

            <StickyChat />
        </>
    );
};

export default Footer;
