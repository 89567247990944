import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import allActions from "../redux/actions";

import MainHeader from "../components/MainHeader";
import ProductListComponent from "../components/ProductList";
import OrderFromPhone from "../components/orderFromPhone";
import Footer from "../components/footer";
import fireApi from "../redux/index";
import { seo } from "../helpers/seo";
import "../css/allProductListing.css";

const cookies = new Cookies();

const OfferList = () => {
  const dispatch = useDispatch();
  const { offer_id } = useParams();
  const currentUser = useSelector((state) => state.login.currentUser);
  const [items, setItems] = useState([]);

  const fetchdataFromServer = () => {
      let parameters = { offer_id:offer_id };

      return fireApi
        .callApiWithToken(
          "offers/get",
          parameters,
          currentUser.token,
          currentUser._id,
          dispatch,
          'get'
        )
        .then((res) => {
          if (!res || !res.data.status) return;
          if (res && res.token_refreshed) {
            var after7Days = new Date();
            after7Days.setDate(after7Days.getDate() + 7);
            cookies.set("token", res.token, {path: "/",expires: after7Days});
            dispatch(allActions.loginActions.currentUserToStore({...currentUser, token: res.token}));
          }
          setItems(res.data.data.item_list);
          return;
        });
  };

  useEffect(() => {
    if(currentUser.token !== "" && currentUser.token !== undefined && offer_id !== "")
     fetchdataFromServer();
  }, [offer_id,currentUser]);

  useEffect(() => {
    let pageTitle = `Buy Offer and Discounted Product Online In Dhaka | OsudPotro`;
    let metaDescription = `
		Offers products online at the best price. 
		  Osudpotro, the standalone ecommerce website for generic medicines.`;
    let metaKeywords = `pharmacy,online pharmacy,ordering medicines online,otc medicines,otc treatment,over the counter medicine`;
    seo({
      title: `${pageTitle}`,
      metaDescription: `${metaDescription}`,
      metaKeywords: `${metaKeywords}`,
    });
  }, []);

  return (
    <>
      <MainHeader isDetailPage={true} />
      <div className="container inner-page-structure-area pt-5">
        <div className="list-cont">
          <div className="page-titile">
            <h3 className="prod-listing-heading mb-2">
              Offers and Discounted of Products/Medicines
            </h3>
            <div className="prod-list-description">
            </div>
          </div>
            <div className="all-prod-list-cont">
              {items.map((item) => {
                let imageToShow = (item &&
                    (item.dis_image ||
                      (item.images && item.images[0] && item.images[0].img) ||
                      item.doc_image)) || "";

                let name = (item &&
                    (item.dis_name ||
                      item.brand_name ||
                      item.prod_name ||
                      item.doc_name)) || "";

                let description = (item &&
                    (item.dis_desc ||
                      item.description ||
                      item.prod_desc ||
                      item.doc_designation)) || "";

                let price = (item &&
                    item.inventory &&
                    item.inventory[0] &&
                    item.inventory[0].price) || "";
                
                let regular_price =
                    (item &&
                      item.inventory &&
                      item.inventory[0] &&
                      item.inventory[0].regular_price) ||
                    "";
                let discount =
                  (item &&
                    item.inventory &&
                    item.inventory[0] &&
                    item.inventory[0].discount) ||
                  0;

                return (
                  <div className="single-prod-cont" key={item._id}>
                    <ProductListComponent
                      item={{
                        cat_name: item.cat_name,
                        cat_type: item.item_type,
                        cat_id: item.cat_id,
                        path:item.path
                      }}
                      itemId={item._id}
                      imageToShow={imageToShow}
                      name={name}
                      description={description}
                      price={price}
                      regular_price={regular_price}
                      discount={discount}
                    />
                  </div>
                );
              })}
            </div>
        </div>
      </div>

      <OrderFromPhone />
      <Footer backToTopVisible />
    </>
  );
};

export default OfferList;
