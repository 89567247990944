

import { combineReducers } from 'redux'
import login from './loginReducer'
import home from './homeDataReducers'
import topNavHeader from './topNavHeaderReducers'
import cartInventory from './cartinventoryReducers'
import cart from './cartReducers'
import offers from './offersReducer'
import transactionsHistory from './transactionReducers'
import PrescriptionList from './prescriptionReducers'
import placeOrder from './placeOrderReducer';
import handleSocket from './socketData';

const appReducer = combineReducers({
  login,
  home,
  topNavHeader,
  cartInventory,
  cart,
  offers,
  transactionsHistory,
  PrescriptionList,
  placeOrder,
  handleSocket
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;