import React, { useState, useEffect } from "react";

import Cookies from "universal-cookie";
import Geocode from "react-geocode";
//import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import MapWithADirectionsRenderer from "./TrackInGoogle";
import MainHeader from "./MainHeader";
Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY);

const GoogleMapsTrackOrder = () => {
  const [coords, setCoords] = useState({});
  const [destination, setDestination] = useState({
    longitude: 0,
    latitude: 0,
  });
  const [lat, setLat] = useState(21.0);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoords(() => position.coords);
        setDestination(() => ({ latitude: 23.8103, longitude: 90.4125 }));
      },
      (error) => alert(error.message),
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    );
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDestination((l) => ({
        longitude: l.longitude + 0.01,
        latitude: l.latitude + 0.01,
      }));
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [destination]);
  const onchangeHandle = (e) => {
    setLat(e.target.value);
  };
  const onClickBtn = () => {
    setCoords((c) => ({
      longitude: c.longitude,
      latitude: lat,
    }));
  };
  return (
    <>
      <MainHeader />
      {coords && coords.latitude && coords.longitude && (
        <div style={{ height: "500px", width: "100%" }}>
          <MapWithADirectionsRenderer
            source={coords}
            destination={destination}
          />
        </div>
      )}
      <div>
        <input onChange={onchangeHandle} />
        <button onClick={onClickBtn}>Submit</button>
      </div>
    </>
  );
};

export default GoogleMapsTrackOrder;
