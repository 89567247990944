import React, {useState,useEffect} from "react";
import { useSelector } from "react-redux";
import { Form, Modal, Button } from "react-bootstrap";
import Cookies from "universal-cookie";
import fireApi from "../redux/index";
import Swal from 'sweetalert2'
const cookies = new Cookies();

const FormModalComponent = ({
  show,
  handleModalClose
}) => {
  
  const [validated, setValidated] = useState(false);
  const currentUser = useSelector((state) => state.login.currentUser);
  const [name,setName] = useState("")
  const [gener,setGener] = useState("")
  const [manufacture,setManufacture] = useState("")

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
        fireApi.callApiWithToken("item/add_item_request",{
            item_name: name,
            manufacturer: manufacture,
            generic: gener
        },
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
        )
        .then((res) => {
            if (!res || !res.data || !res.data.status) return;
            handleModalClose()
            setName("")
            setGener("")
            setManufacture("")
            setValidated(false);
            Swal.fire('Success', res.data.message, 'success')
        });
    }

    setValidated(true);
  };


  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      className="arrange-me-modal-area"
    >
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Modal.Header className="form-modal-header" closeButton>Request for Medicine or Product</Modal.Header>
      <Modal.Body className="form-modal-content justify-content-left">
        <Form.Group controlId="madicine-name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter medicine or product name" required value={name} onChange={(e)=> setName(e.currentTarget.value)}/>
            <Form.Control.Feedback type="invalid"> Please enter medicine or product name.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="madicine-generic">
            <Form.Label>Generic (optional)</Form.Label>
            <Form.Control type="text" placeholder="Enter generic name" value={gener} onChange={(e)=> setGener(e.currentTarget.value)}/>
        </Form.Group>

        <Form.Group controlId="madicine-manufacture">
            <Form.Label>Manufacture (optional)</Form.Label>
            <Form.Control type="text" placeholder="Enter manufacture name" value={manufacture} onChange={(e)=> setManufacture(e.currentTarget.value)}/>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="guest-modal-footer form-footer">
        <Button
          className="guest-modal-footer-button form-btn"
          variant="secondary"
          type="submit"
        >
          Send Request
        </Button>
      </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default FormModalComponent;
