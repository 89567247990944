import React from 'react'
import { propTypes } from 'react-bootstrap/esm/Image';
import { Helmet } from  'react-helmet';

const MetaDecorator = ({seo}) => {
    const {title,description,keywords,og_url,og_imageUrl} = seo;
    return (
        <Helmet>
            <title>{title}</title>
            <meta
            name="description"
            content={description}
            />
            <meta name="keywords" content={keywords}/>
            <meta property="og:title" content={title}/>
            <meta property="og:type" content="website"/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={og_imageUrl} />
            <meta property="og:url" content={og_url} />

            <meta property="twitter:card" content={description} />
            <meta property="twitter:url" content={og_url} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={og_imageUrl} />

      </Helmet>
    )
}

MetaDecorator.prototype = {
    title: propTypes.string,
    description: propTypes.string,
}

export default MetaDecorator;