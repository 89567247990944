  const placeOrder = (state = [], action) => {
    switch (action.type) {
    case 'PLACEORDER':
        return {
            ...state,
            placeOrderData: action.placeOrderData
        }
    default:
        return state
    }
}

export default placeOrder