import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import LogicalFuncs from "../../logicalFunctions/handleHomeData";
import allActions from "../../redux/actions";
import fireApi from "../../redux/index";

import "../../css/cart/cart.css";

const cookies = new Cookies();

const PurchaseSummary = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const cartTotalFromRedux = useSelector((state) => state.cart.cartTotal) || 0;
  const isOfferApplied = useSelector((state) => state.placeOrder.placeOrderData);

  const [cartTotal, setCartTotal] = useState(cartTotalFromRedux.cartTotal);

  useEffect(() => {
    if (!cartTotal) fetchCartTotalFromServer();
  }, [cartTotal]);

  const fetchCartTotalFromServer = () => {
    return fireApi
      .callApiWithToken(
        "cart/get_total",
        {},
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        var { total, cart_count, delievery_fee, discount } = res.data.data;
        setCartTotal(total);
        dispatch(
          allActions.cartActions.calculateCartTotal({
            sub_total: cart_count,
            delievery_fee: delievery_fee,
            discount: discount,
            cartTotal: total,
          })
        );
        // dispatch(
        //   allActions.placeOrderActions.placeOrder({
        //     ...isOfferApplied,
        //     cartTotal: total,
        //   })
        // );
      });
  };
  const { sub_total, delievery_fee, discount } = cartTotalFromRedux;
  return (
    <>
      <div className="purchase-summary sub-total">
        <Row>
          <Col lg={6} md={5} sm={5} xs={5}>Sub Total</Col>
          <Col lg={6} md={6} sm={6} xs={6} style={{ textAlign: "end" }}>
            <NumberFormat
              value={sub_total}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`৳`}
              decimalScale={2}
              fixedDecimalScale={true}
              type="currency"
              renderText={(value) => value}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={5} sm={5} xs={5}>Delivery Fee</Col>
          <Col lg={6} md={6} sm={6} xs={6} style={{ textAlign: "end" }}>
            <NumberFormat
              value={delievery_fee || 0}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`৳`}
              decimalScale={2}
              fixedDecimalScale={true}
              type="currency"
              renderText={(value) => value}
            />
          </Col>
        </Row>
        <Row nogutters="true">
          <Col lg={6} md={5} sm={5} xs={5}>Discount</Col>
          <Col lg={6} md={6} sm={6} xs={6} style={{ textAlign: "end" }}>
            <NumberFormat
              value={discount || "0"}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`৳`}
              decimalScale={2}
              fixedDecimalScale={true}
              type="currency"
              renderText={(value) => value}
            />
          </Col>
        </Row>
      </div>
      <div className="purchase-summary grand-total">
        <Row nogutters="true">
          <Col lg={6} md={5} sm={5} xs={5}>Total</Col>
          <Col lg={6} md={6} sm={6} xs={6} style={{ textAlign: "end" }}>
            <NumberFormat
              value={cartTotalFromRedux.cartTotal}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`৳`}
              decimalScale={2}
              fixedDecimalScale={true}
              type="currency"
              renderText={(value) => value}
            />
          </Col>
        </Row>
      </div>  
    </>
  );
};

export default PurchaseSummary;
