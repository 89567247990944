import _ from 'lodash'
import axios from "axios";
import Cookies from "universal-cookie";
const baseUrl = process.env.REACT_APP_BaseApiUrl;
const paymentUrl = process.env.REACT_APP_PaymentUrl;
const kCurrenyCode = "BDT";
const kSSLProductCategory = "product";
const kSSLStoreID = "osudp5ef4fe4ca198f";
const kSSLStorePassword = "osudp5ef4fe4ca198f@ssl";
const RootImgUrl = process.env.REACT_APP_IMG_CDN;
const RootThumbImgUrl = process.env.REACT_APP_IMG_CDN + "thumb/";
const https = require("https");
const cookies = new Cookies();

const http = axios.create({
  baseURL: baseUrl,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  // headers:{
  //   common:{
  //     "Access-Control-Allow-Origin":"*",
  //     Authorization: `Bearer ${cookies.get("token")}` 
  //   }
  // }
});

const imgHttp = axios.create({
  baseURL: process.env.REACT_APP_IMAGE_UPLOADUrl,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  // headers:{
  //   common:{
  //     "Access-Control-Allow-Origin":"*",
  //     Authorization: `Bearer ${cookies.get("token")}` 
  //   }
  // }
});

const CATEGORY_URLS = [
  {
    name : "OTC Medicines",
    alias : "otc-medicines",
    path : "/buy-over-the-counter-medicine-online-bangladesh",
    id : "",
    seo : {
      title:"Buy OTC (Over The Counter) Medicine Online In Bangladesh | OsudPotro",
      description:"OTC Products. Buy Over the counter products online at the best price. Osudpotro, the standalone ecommerce website for generic medicines.",
      keywords:"otc medicines,otc treatment,over the counter medicine",
      image:'',
      url:'/buy-over-the-counter-medicine-online-bangladesh'
    }
  },
  {
    name : "Prescription Medicines",
    alias : "prescription-medicines",
    path : "/doctors-medicine-online-pharmacy-bangladesh",
    id : "",
    seo : {
      title:"Buy Doctors Medicine Online from Pharmacy in Bangladesh | OsudPotro",
      description:"OsudPotro brings you online Pharmacy to buy medicine in Dhaka anywhere anytime to get medicine of your health problems and treatments on call and chat. So buy medicine now!",
      keywords:"doctor medicine,online pharmacist,Pharmacy",
      image:'',
      url:'/doctors-medicine-online-pharmacy-bangladesh'
    }
  },
  {
    name : "Blood Pressure Kit",
    alias : "blood-pressure-kit",
    path : "/medical-equipement-blood-pressure-monitor-online",
    id : "5f4cf1f90a76cd4493a6c5d3",
    seo : {
      title:"Buy Medical Equipement | Blood Pressure Monitor Online",
      description:"Blood pressure monitor. Buy medical equipement online at the best price. Osudpotro, the standalone ecommerce website for generic medicines.",
      keywords:"Blood pressure monitor,Medical equipment",
      image:'',
      url:'/medical-equipement-blood-pressure-monitor-online'
    }
  },
  {
    name : "Glucose Meter",
    alias : "glucose-meter",
    path : "/glucometer-blood-sugar-tester-machine-buy-online-bangladesh",
    id : "5f4cf9680a76cd4493a6c5d6",
    seo : {
      title:"Glucometer, Blood Sugar Tester Machine Buy Online In Dhaka",
      description:"Glucometer, Blood sugar tester machine buy online in dhaka at the best price. Osudpotro, the standalone ecommerce website for generic medicines.",
      keywords:"Glucose tester,blood sugar machine,blood sugar tester,glucometer",
      image:'',
      url:'/glucometer-blood-sugar-tester-machine-buy-online-bangladesh'
    }
  },
  {
    name : "Medical Devices",
    alias : "medical-devices",
    path : "/medical-instruments-supplies-online-bangladesh",
    id : "5f4cf9b50a76cd4493a6c5d7",
    seo : {
      title:"Medical Instruments & Medical Supplies Online | Osudpotro ",
      description:"Medical Instruments & medical supplies online in dhaka at the best price. Osudpotro, the standalone ecommerce website for generic medicines.",
      keywords:"Medical instruments,medical supplies",
      image:'',
      url:'/medical-instruments-supplies-online-bangladesh'
    }
  },
  {
    name : "Rehabilitation Aids",
    alias : "rehabilitation-aids",
    path : "/rehabilitation-pain-relief-medicine-pharmacy-online",
    id : "5f54a14fcb8d581aade4ff57",
    seo : {
      title:"Buy Rehabilitation, Pain Relief Medicine From Online Pharmacy ",
      description:"Buy rehabilitation, Pain relief medicine from pharmacy online in dhaka at the best price. Our extensive range of Rehabilitation Aids Gel Healthcare range & many more.",
      keywords:"Rehablitation,painrelief",
      image:'',
      url:'/rehabilitation-pain-relief-medicine-pharmacy-online'
    }
  },
  {
    name : "Men's Products",
    alias : "men's-products",
    path : "/mens-healthcare-products-online-pharmacy-bangladesh",
    id : "5f72eea24ff3e17a905c4ed6",
    seo : {
      title:"Buy Mens Health Care Products From Online Pharmacy In Bangladesh",
      description:"Buy mens health care products from Osudpotro online pharmacy In dhaka at the best price. Men's health care products include all cosmetic product & skin care products",
      keywords:"Mens,health care,products,perfume",
      image:'',
      url:'/mens-healthcare-products-online-pharmacy-bangladesh'
    }
  },
  {
    name : "Online Doctor's",
    alias : "online-doctor's",
    path : "/consult-doctor-online-get-expert-doctors-help",
    id : "",
    seo : {
      title:"Consult Doctor Online|Get Expert Doctors Help| Osud Potro",
      description:"OsudPotro brings you online Expert doctors consult in  Bangladesh anywhere anytime to get answers of your health problems and treatments on call and chat. So ask a doctor now!",
      keywords:"doctor online,online doctor help,consult doctor online",
      image:'',
      url:'/consult-doctor-online-get-expert-doctors-help'
    }
  },
  {
    name : "Women's Products",
    alias : "women's-products",
    path : "/womens-healthcare-products-online-pharmacy-bangladesh",
    id : "5f72ef4f4ff3e17a905c4ed7",
    seo : {
      title:"Buy Womens Health Care Products From Online Pharmacy In Bangladesh",
      description:"Buy Women's Health Care Products From Online Pharmacy In Dhaka Women's personal care products used during menstruation vaginal discharge, and other bodily function.",
      keywords:"Women,health care,products",
      image:'',
      url:'/womens-healthcare-products-online-pharmacy-bangladesh'
    }
  },
  {
    name : "Kid's Item",
    alias : "kid's-item",
    path : "/kids-healthcare-products-online-pharmacy-bangladesh",
    id : "5f9ad86622070100075373ee",
    seo : {
      title:'Buy KidsHealth Care Products From Online Pharmacy In Bangladesh',
      description:'We know that you want to look after your little one. These baby care products will help you manage better. Buy kids health care product from online pharmacy in Bangladesh',
      keywords:'kids,health care,products,medicines',
      image:'',
      url:'/kids-healthcare-products-online-pharmacy-bangladesh'
    }
  },
]

const getCatUrlData = (catalias) => {
  return _.find(CATEGORY_URLS, (url)=> url.alias.toLowerCase() === catalias.toLowerCase()) 
}

const localUserkey = 'userDetails'

const ORDER_STATUS = [
  {
    status_value: 0,
    status_name: "Pending",
  },
  {
    status_value: 1,
    status_name: "Accepted",
  },
  {
    status_value: 2,
    status_name: "Packaging",
  },
  {
    status_value: 3,
    status_name: "Dispatched",
  },
  {
    status_value: 4,
    status_name: "On the Way",
  },
  {
    status_value: 5,
    status_name: "Arrived",
  },
  {
    status_value: 6,
    status_name: "Delivered",
  },
  {
    status_value: 7,
    status_name: "Cancelled",
  },
  {
    status_value: 8,
    status_name: "Unknown State",
  },
];
// 0 -> Pending, 1 -> Accepted, 2 -> Packaging, 3 -> Dispatched, 4 -> On the Way, 5 -> Arrived,
// 6 -> Delivered, 7 -> Cancelled, 8 -> Unknown State;
export {
  baseUrl,
  paymentUrl,
  kCurrenyCode,
  kSSLProductCategory,
  kSSLStoreID,
  kSSLStorePassword,
  RootImgUrl,
  RootThumbImgUrl,
  ORDER_STATUS,
  CATEGORY_URLS,
  getCatUrlData,
  localUserkey,
  http,
  imgHttp
};
