import fireApi from '../redux/index';
import { localUserkey } from "../constants/const";
let localUserDetails = JSON.parse(localStorage.getItem(localUserkey))

const fetchHomeDataFromServer = async ({ homeActions, currentToken, _id, dispatch }) => {
	return await fireApi.callApiWithToken('home/home_data_web', {}, currentToken, _id, dispatch,'get').then( async (res) => {
		if (!res || !res.data) return;
		await dispatch(homeActions.setHomeData(res.data.data));
		return await res.data.data;
	});
};

const fetchSliderImagesFromServer = async ({ homeActions, currentToken, _id, dispatch }) => {
	return await fireApi.callApiWithToken('home/get_slider_list', {}, currentToken, _id, dispatch).then(async (res) => {
		if (!res || !res.data) return;
		await dispatch(homeActions.setHomeSliders(res.data.data));
		return await res.data.data;
	});
};

const storeRefreshToken = async ({ res, dispatch, allActions, cookies }) => {
  var after7Days = new Date();
  after7Days.setDate(after7Days.getDate() + 7);
  const newToken = res.token
  await cookies.set('token', newToken, {path: '/',expires: after7Days});
  localUserDetails['token'] = newToken
  await localStorage.setItem(localUserkey,JSON.stringify(localUserDetails))
  await dispatch(allActions.loginActions.currentUserToStore({token: newToken}));
};

export default {
	fetchSliderImagesFromServer,
	fetchHomeDataFromServer,
	storeRefreshToken,
};
