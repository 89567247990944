import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import fireApi from '../redux/index';
import LogicalFuncs from '../logicalFunctions/handleHomeData';
import allActions from '../redux/actions';
import "../css/profileNav.css";
import { RootImgUrl, localUserkey } from '../constants/const';
const cookies = new Cookies();
const localUserDetails = JSON.parse(localStorage.getItem(localUserkey))

const NavProfileComponent = () => {
    const [userDetails, setUserDetails] = useState(localUserDetails); 
    const [greetText, setGreetText] = useState('Login/');
    const [accountUrl, setAccountUrl] = useState("/login");
    const [userText, setUserText] = useState('Register');
    const currentUser = useSelector((state) => state.login.currentUser);

    const dispatch = useDispatch();

    const handleLogout = (isReload = true) => {
        const cookieExpireTime = new Date(Date.now()-2592000)
        cookies.remove("token", {path: "/", expires: cookieExpireTime});
        cookies.remove("user_id", { path: "/", expires: cookieExpireTime });
        cookies.remove("user_type", {path: "/", expires: cookieExpireTime});
        dispatch({type: "USER_LOGGED_OUT"});
        localStorage.removeItem('user_id')
        localStorage.removeItem(localUserkey)
        localStorage.removeItem('placeOrderData')
        localStorage.removeItem('customerOrder')
        localStorage.removeItem('customerOrderAddress')

        if(isReload)  window.location.reload();
    };
    

    const fetchDataFromServer = () => {
		return fireApi.callApiWithToken(
			'users/get_detail',{},
			currentUser.token || cookies.get('token'),
			currentUser._id || cookies.get('user_id')
		)
		.then((res) => {
			if (!res || !res.data || !res.data.status) return;
			if (res && res.token_refreshed) LogicalFuncs.storeRefreshToken({res,dispatch,allActions,cookies})
			const updateDetails = {...localUserDetails, ...currentUser, ...res.data.data };
            localStorage.setItem(localUserkey,JSON.stringify(updateDetails))
            setGreetText('Hello,')
			dispatch(allActions.loginActions.currentUserToStore(updateDetails));
		});
    };
    
    useEffect( () => {
        if(localUserDetails !== null && localUserDetails._id !== '101' && localUserDetails.name === undefined){
            fetchDataFromServer()
        }
    },[]);

    useEffect( () => {
        const userImage = currentUser.image !== undefined ? currentUser.image : null
        setUserDetails({...currentUser,image:userImage})
    },[currentUser]);

    useEffect( () => {
        if(userDetails !== null && userDetails._id !== '101' && userDetails.user_id !== 101 && userDetails.name !== undefined){
            let userName  = userDetails.name !== '' ? userDetails.name : 'User'
            setGreetText('Hello,')
            setAccountUrl('/profile')
            setUserText(userName)
        }
    },[userDetails]);

    

    return (
        <div className="profile-navigator">
        <Nav.Link as={Link} to={accountUrl} className="account-link profile-navigator">
            <span className="label">
                <span>{greetText}</span>
                <p className="user-name" title={userText} >{ currentUser._id === 101 ? 'User' : userText}</p>
            </span>
            { userDetails !== null && userDetails.image !== '' && userDetails.image !== undefined && userDetails.image !== null? 
            <div className="login-icon"  style = { { backgroundImage : `url(${RootImgUrl}${userDetails.image})` } } />
            : <i className="fas fa-user login-icon"></i> }
        </Nav.Link>
        
        { currentUser !== null && currentUser._id !== '101' && currentUser.user_id !== '101' &&
            <div className="profile-dropdown-content">
                <Nav.Item as="li">
                    <Nav.Link as={Link} to="/profile"><i className="fas fa-user-circle mr-2"></i>Profile</Nav.Link>
                    <a className="nav-link"  onClick={() => handleLogout()}>
                        <i className="fas fa-power-off mr-2"></i> Logout
                    </a>
                </Nav.Item>
                {/* <Nav.Link as={Link} to="/profile"><i className="fas fa-cog mr-2"></i>Settings</Nav.Link> */}
               
            </div>
        }
        </div>
     );
}
 
export default NavProfileComponent;