const transactionsHistory = (state = [], action) => {
    switch (action.type) {
    case 'TRANSACTION_DETAIL':
        return {
            ...state,
            transactons: action.transactons
        }
    default:
        return state
    }
}

export default transactionsHistory