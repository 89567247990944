import React from "react";
import {NavLink} from 'react-router-dom'

import "../../css/profileNav.css";

const ProfileNav = () => {
  return (
    <div className="profile-nav-container">
      <NavLink to="/profile"><span >Profile</span></NavLink>
      <NavLink to="/deliveryHistory"><span>Order History</span></NavLink>
      <NavLink to="/addresses"><span>Delivery Address</span></NavLink>
      <NavLink to="/balance"><span>OsudPotro Wallet</span></NavLink>
      <NavLink to="/prescription"><span>My Prescription</span></NavLink>
    </div>
  );
};

export default ProfileNav;
