import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button, Nav} from "react-bootstrap";
import Dropdown from "react-dropdown";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { useHistory,Link } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import NoDataFound from "./noDataFound";
import OrderHistoryList from "./profile/orderHistory/orderHistory";
import PurchaseSummary from "../containers/cart/purchaseTotal";
import AddAddressModal from "./profile/orderDelivery/addAddressModal";
import PrescriptionList from "../containers/prescriptionList";
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import allActions from "../redux/actions";
import fireApi from "../redux/index";
import applyOffer from "../images/offer@3x.png";
import { NavLink } from "react-router-dom";
import AlertModal from "../components/alertModal";
import "react-dropdown/style.css";
import "../css/orderHistory.css";
import "../css/checkout.css";
import _ from 'lodash'

const cookies = new Cookies();
 var FontAwesome = require("react-fontawesome");
const customerOrderPropertyToShow = ['name','address','contact','email']
const Checkout = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.login.currentUser)
  const cartFromRedux = useSelector((state) => state.cart.cartData) || []
  const isOfferApplied = useSelector((state) => state.placeOrder.placeOrderData);
  const [isGuestUser, setIsGuestUser] = useState(false)
  const [customerOrder, setCustomerOrder] = useState(false)
  const [customerOrderAddress, setCustomerOrderAddress] = useState(null)
  const [alertText,setAlertText] = useState("Please add an address");
  let userType = cookies.get("user_type")
  const addressBookRedux = useSelector((state) => state.login.currentUser.addressBook) || []

  const swipperParamsForPrescription = {
    slidesPerView: 2,
    spaceBetween: 10,
  }

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [cartData, setCartData] = useState([...cartFromRedux]);
  const [userAddress, setUserAddress] = useState(addressBookRedux || []);
  const [specificAddress, setSpecificAddress] = useState({});

  const addressFromGoogleRedux =
    useSelector((state) => state.login.currentUser.addressFromGoogle) || ""
  const [addAddress, setAddAddress] = useState(addressFromGoogleRedux ? true : false)

  const [isPrimarySelected, setIsPrimarySelected] = useState(true)
  const [addressSelected, setAddressSelected] = useState(false)
  const [selectedAddressId, setSelecedAddressId] = useState('')
  const [otherAddressOptions, setOtherAddressOption] = useState([])
  const [orderData, setOrderdata] = useState(useSelector(
    (state) => state.placeOrder.placeOrderData
  ))
  const [isPrescriptionRequired, setIsPrescriptionRequired] = useState(false);
  const [preparePrescriptionIdList, setPreparePresriptionIdList] = useState([]);

  const [modalEditData,setModalEditData] = useState({
    name:'',
    address:'',
    address2:'',
    is_primary:'',
    contact:'',
    email:''
  })

  const confirmPrescriptions = () => {
    dispatch(
      allActions.placeOrderActions.placeOrder({
        ...orderData,
        prescription_id: preparePrescriptionIdList,
      })
    );
    setShowPrescriptionListModal(false)
  };

  const [showPrescriptionListModal, setShowPrescriptionListModal] = useState(false)
  const hidePrescriptionListModal = () => {setShowPrescriptionListModal(false)}

  useEffect(() => {
		window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    setOrderdata(JSON.parse(localStorage.getItem('placeOrderData')) || {})
    // setCurrentUser(JSON.parse(localStorage.getItem(localUserkey)))
    setCustomerOrder(JSON.parse(localStorage.getItem('customerOrder')) || false)
  }, []);

  useEffect(() => {
    if(customerOrder)
      setCustomerOrderAddress(JSON.parse(localStorage.getItem('customerOrderAddress')) || null)
  }, [customerOrder])

  useEffect(() => {
    if (userType !== "user") setIsGuestUser(true)
    fetchCartDataFromServer()
    fetchAddressFromServer()
  }, [userType]);

  useEffect(() => {
    if (!selectedAddressId) {
      let primaryAdrId = userAddress.filter((adr) => adr.is_primary)
      if (primaryAdrId) {
        setSelecedAddressId(primaryAdrId[0] && primaryAdrId[0]._id)
      }
    }
  }, [userAddress]);

  useEffect(() => {
    if(customerOrder && customerOrderAddress !== null){
      const {name,address,address2,is_primary,contact,email} =  customerOrderAddress
      setModalEditData({
        name:name || '',
        address:address|| '',
        address2:address2||'',
        is_primary:is_primary||'',
        contact:contact||'',
        email:email||''
      })
    }
  }, [customerOrder,customerOrderAddress]);

  useEffect(() => {
    let tmp_obj = {};
    let tmpArray = [];
    let tmpPrimAdrId = "";
    userAddress.map((addr) => {
      if (addr.is_primary) {
        tmp_obj["primary"] = addr;
        tmpPrimAdrId = addr._id;
      } else {
        tmp_obj["other"] = tmp_obj["other"]
          ? [...tmp_obj["other"], addr]
          : [addr];
        tmpArray.push(addr.address);
      }
    });
    setSpecificAddress((t) => tmp_obj)
    setOtherAddressOption(tmpArray)
    if (!selectedAddressId) {
      setSelecedAddressId(tmpPrimAdrId)
    }
  }, [userAddress])

  useEffect(() => {
    if (selectedAddressId) {
      const selectedOrderData = {
        ...isOfferApplied,
        selectedAddress: selectedAddressId,
      }
      setOrderdata(selectedOrderData)
    }
  }, [selectedAddressId])

  useEffect(() => {
      localStorage.setItem('placeOrderData',JSON.stringify(orderData))
      dispatch(allActions.placeOrderActions.placeOrder(orderData))
  }, [orderData])


  const fetchCartDataFromServer = () => {
    return fireApi
      .callApiWithToken(
        "cart/get",
        {},
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) return;

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({res,dispatch,allActions,cookies});
        }
        res.data.data.map((cartIndivItem) => {
          if (cartIndivItem.is_prescription_required) 
            setIsPrescriptionRequired(cartIndivItem)
        })
        setCartData(res.data.data)
        dispatch(allActions.cartActions.cart(res.data.data))
      });
  };

  const fetchAddressFromServer = () => {
    return fireApi
      .callApiWithToken(
        "address/get_all",
        {},
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        setUserAddress(res.data.data);
        // dispatch(
        //   allActions.loginActions.currentUserToStore({
        //     ...currentUser,
        //     addressBook: res.data.data,
        //   })
        // );
      });
  };

  const otherAddrssChanged = (e) => {
    setAddressSelected(e.value)
  };

  const onCloseAlertModal = () => {
    setShowAlertModal(false)
  };

  const getCustomerOrderAddressView = address => {
  return _.map(address, (field,index) => {
    if(_.includes(customerOrderPropertyToShow,index))
      return <li key={index}>{field}</li>
  })
  }
  const handleInstructionChange = e =>{
    let newOrderData = {}
    if(e.target.value !== ''){
      newOrderData = { ...orderData,instruction: e.target.value}
    }else{
      newOrderData = { ...orderData,instruction: ''}
    }
    setOrderdata(newOrderData)
  }

  const getPlaceOrderUrl = () => {
    if(!customerOrder && orderData && orderData.selectedAddress !== undefined && orderData.selectedAddress !== ''){
      return "/place-your-cart-order-online-medicine-drug-in-bangladesh"
    }else if(customerOrder && customerOrderAddress !== null && customerOrderAddress.address !== '' && customerOrderAddress.address !== ''){
      return "/place-your-cart-order-online-medicine-drug-in-bangladesh"
    }else{
      return "/checkout-online-medicine-drug-store-bangladesh"
    }
  }

  const checkPlaceOrderValid = () => {
    if(!customerOrder && orderData && (orderData.selectedAddress === undefined || orderData.selectedAddress === '')){
      setShowAlertModal(true)
      return
    }
    if(customerOrder && customerOrderAddress === null ){
      setShowAlertModal(true)
      return
    }
  }

  return (
    <>
      { isGuestUser ? history.push("/Login")
       : (
        <>
          <MainHeader />
          <div className="container checkout-page">
            {cartData && cartData.length ? (
              <Row>
                <Col lg={8} md={12} sm={12} className="pl-3">
                  <div className="checkout-page-title-area address-title">
                    <div className="checkout-title">
                      <span className="checkout-title-up">Select</span>
                      <span className="checkout-title-down">{customerOrder && 'Customer'} Address </span>
                    </div>
                    <div className="custom-order">
                      { 
                        currentUser.is_custom_order_available !== undefined &&  currentUser.is_custom_order_available ?
                        <label className="theme-check-box-label">
                          <input
                            className="theme-check-box"
                            type="checkbox"
                            value={ customerOrder }
                            checked={ customerOrder }
                            onChange={()=> {
                              setCustomerOrder(currCustomerOrder => !currCustomerOrder) 
                              localStorage.setItem('customerOrder',!customerOrder)
                            }}
                          />
                          <span></span> Customer Order
                        </label> : <></>
                      }
                    </div>
                  </div>
                  {!customerOrder ? 
                  <Row className="pb-5">
                    {specificAddress && Object.keys(specificAddress).length ? (
                      <Col lg={6} md={6}>
                        <div style={{ padding: "0px 15px" }}>
                          <label className="cus-radio">
                            Primary Address.
                            <input
                              type="radio"
                              checked={isPrimarySelected}
                              value={"other"}
                              name="address"
                              onChange={() => {
                                setIsPrimarySelected(true);
                                setSelecedAddressId(
                                  specificAddress.primary._id
                                );
                              }}
                            />
                            <span
                              className="checkmark"
                              style={{ marginTop: 0 }}
                            ></span>
                            <p className="primary_adress_area">
                              {specificAddress.primary &&
                                specificAddress.primary.address}
                            </p>
                          </label>
                        </div>
                      </Col>
                    ) : <></>
                    }
                    <Col lg={6} md={6}>
                      <div
                        style={{ padding: "0px 15px", borderLeft: "2px solid #e5e5e5", }}
                      >
                        {specificAddress &&
                        specificAddress.other !== undefined &&
                        Object.keys(specificAddress).length ? (
                          <>
                            <label className="cus-radio">
                              Other Delivery Addresses.
                              <input
                                type="radio"
                                checked={!isPrimarySelected}
                                value={"other"}
                                name="address"
                                onChange={() => {
                                  setIsPrimarySelected(false);
                                  setSelecedAddressId(
                                    userAddress.filter(
                                      (adres) =>
                                        adres.address ===
                                        (addressSelected ||
                                          (specificAddress.other[0] &&
                                            specificAddress.other[0].address))
                                    )[0]._id
                                  );
                                }}
                              />
                              <span
                                className="checkmark"
                                style={{ marginTop: 0 }}
                              ></span>
                            </label>
                            <Dropdown
                              controlClassName="address-drop-down"
                              menuClassName="address-menu-class"
                              options={
                                !isPrimarySelected ? otherAddressOptions : []
                              }
                              onChange={(e) => {
                                otherAddrssChanged(e);
                                setSelecedAddressId(
                                  userAddress.filter(
                                    (adres) => adres.address === e.value
                                  )[0]._id
                                );
                              }}
                              value={
                                addressSelected ||
                                (specificAddress.other[0] &&
                                  specificAddress.other[0].address)
                              }
                              placeholder="Select an option"
                            />
                          </>
                        ) : 
                          <></>
                        }
                        <button
                          onClick={() => setAddAddress(true)}
                          className="checkout-add-address"
                        >
                          Add New Address
                        </button>
                      </div>
                    </Col>
                  </Row>
                  :  
                  <Row className="pb-5">
                    {customerOrderAddress !== null && 
                    <Col lg={12} md={12} sm={12} className="pl-3">
                      <ul className="customer-address">
                        { getCustomerOrderAddressView(customerOrderAddress) }
                      </ul>
                    </Col>
                    }
                    <Col lg={12} md={12} sm={12} className="pl-3">
                      <button onClick={() => setAddAddress(true)} className="checkout-add-address"> {customerOrder && customerOrderAddress !== null ? `Update Address`:`Add New Address`} </button> 
                    </Col>
                  </Row>
                }

                  <div
                    className="history-det-cont"
                    style={{
                      borderTop: "2px solid #b9b9b9",
                      paddingTop: "25px",
                    }}
                  >
                    <div className="checkout-page-title-area">
                      <span className="checkout-title-up">Order</span>
                      <span className="checkout-title-down">Summary</span>
                    </div>

                    {cartData.map((cartItem, index) => (
                      <OrderHistoryList
                        item={cartItem}
                        is_checkout={true}
                        key={index}
                      />
                    ))}
                  </div>

                  {isPrescriptionRequired && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        style={{ marginRight: "15px" }}
                        className="cart-add-prescriptio-button"
                        onClick={() => setShowPrescriptionListModal(true)}
                      >
                        Select Prescription
                      </button>
                      <NavLink to="/prescription">
                        <button className="cart-add-prescriptio-button">
                          Add Prescription
                        </button>
                      </NavLink>
                    </div>
                  )}
                </Col>
                <Col
                  lg={4} md={12} sm={12}
                  className="purchase-summary-checkout-area"
                >
                  <div className="special-instruction">
                    <label className="label">Special Instruction</label>
                    <textarea type="text" className="form-control" onBlur={(e)=> handleInstructionChange(e)}>
                      {/* {orderData.instruction && orderData.instruction } */}
                    </textarea>
                  </div>
                  <div className="purchase-summary-checkout">
                    {!isOfferApplied ||
                      (!isOfferApplied.offerApplied && (
                        <NavLink to="/promo-offers-or-coupon-in-online-medicine-in-bangladesh">
                          <div className="offer-area">
                              <img
                                style={{ width: "35px" }}
                                src={applyOffer}
                              />
                              <span className="aplyOffer">Apply Offer</span>
                          </div>
                        </NavLink>
                      ))}
                    {isOfferApplied && isOfferApplied.offerApplied && (
                      <div
                        style={{
                          borderTop: "2px solid #b9b9b9",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          fontSize: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          Offers Applied (
                          {isOfferApplied.offerApplied.offer_code})
                        </span>
                        <FontAwesome
                          style={{ paddingTop: "5px" }}
                          name="times-circle"
                        />
                      </div>
                    )}
                    <PurchaseSummary />
                    <div className="purchase-summary-btn-area">
                        <Nav.Item as="li" >
                            <Nav.Link as={Link} to="/" > Buy More </Nav.Link>
                       </Nav.Item>
                       <Nav.Item as="li">
                            <Nav.Link as={Link} to="/cart" > Edit your Order </Nav.Link>
                       </Nav.Item>
                       <Nav.Item as="li" >
                            <Nav.Link as={Link}  
                            to={getPlaceOrderUrl()}
                            onClick = {() => checkPlaceOrderValid()}
                            > Proceed your Payment </Nav.Link>
                       </Nav.Item>
                    </div>  
                  </div>
                </Col>
              </Row>
            ) : <NoDataFound />}
          </div>

          <AddAddressModal
            show = {addAddress}
            editData = {modalEditData}
            handleShow = {setAddAddress}
            fromCheckout = {true}
            forCustomer = {customerOrder}
            setCustomerOrderAddress = {setCustomerOrderAddress}
          />

          <Modal
            show={showPrescriptionListModal}
            onHide={hidePrescriptionListModal}
            centered
            keyboard={false}
            backdrop="static"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="guest-modal-message">
              <div className="address-list-cont">
                <div className="title-heading">
                  <span>Prescription</span>
                </div>
                <PrescriptionList
                  swipperParamsFromCheckout={swipperParamsForPrescription}
                  preparePrescriptionIdList={preparePrescriptionIdList}
                  setPreparePresriptionIdList={setPreparePresriptionIdList}
                />
              </div>
            </Modal.Body>
            <Modal.Footer
              className="guest-modal-footer"
              style={{ justifyContent: "center" }}
            >
              <Button
                className="guest-modal-footer-button"
                variant="secondary"
                onClick={() => confirmPrescriptions()}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
          
          <AlertModal
            show={showAlertModal}
            buttonText="OK"
            message={alertText}
            handleModalClose={onCloseAlertModal}
          />
          <Footer backToTopVisible={true} />
        </>
      )}
    </>
  );
};

export default Checkout;
