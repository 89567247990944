import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Row, Col, Modal, Button } from "react-bootstrap";
import Truncate from "react-truncate";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment"

import ProgressBar from "../../../components/ProgressBar";
import LogicalFuncs from "../../../logicalFunctions/handleHomeData";
import OrderItemData from "./orderItemData"
import allActions from "../../../redux/actions";
import fireApi from "../../../redux/index";
import NumberFormat from "react-number-format";
import CallForPrice from "../../../components/callPriceComponent";
import "../../../css/orderHistory.css";
import { RootThumbImgUrl} from "../../../constants/const";

const cookies = new Cookies();

const PayementMethods = {
  'cod' : 'Cash On Delivery',
  'osud_wallet' : 'Osudpotro Wallet',
  'ssl_commerz' : 'Online Payment',
}

const OrderHistoryList = ({
  item,
  is_checkout = false,
  history = false,
  notCompletedYet = false,
}) => {
  
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [priceOfProduct, setPriceOfProduct] = useState(0);
  const [regularPriceOfProduct, setRegularPriceOfProduct] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsClicked, setDetailsClicked] = useState(false);
  const [confirmWithUserModal, setConfirmWithUserModal] = useState(false);
  const [itemStatus, setItemStatus] = useState("pending");
  const [actionForUser, setActionForUser] = useState("Cancel");
  const [modalOrderId, setModalOrderId] = useState("");

  useEffect(() => {
    switch (item.order_status) {
      case 0: {
        setItemStatus("Pending");
        setActionForUser("Cancel");
        break;
      }
      case 1: {
        setItemStatus("Accepted");
        setActionForUser("Cancel");
        break;
      }
      case 2: {
        setItemStatus("Packaging");
        setActionForUser("Cancel");
        break;
      }
      case 3: {
        setItemStatus("Dispatch");
        setActionForUser("Cancel");
        break;
      }
      case 4: {
        setItemStatus("On The Way");
        setActionForUser("Track Your Order");
        break;
      }
      case 5: {
        setItemStatus("Arrived");
        setActionForUser("Track Your Order");
        break;
      }
      case 6: {
        setItemStatus("Delivered");
        setActionForUser("Order again");
        break;
      }
      case 7: {
        setItemStatus("Cancelled");
        setActionForUser("Order again");
        break;
      }
    }
  }, []);

  const handleClose = () => {
    setShowDetailsModal(false);
    setConfirmWithUserModal(false);
  };

  useEffect(() => {
    if (!history) {
      let totalQuantityTmp = 0;
      let priceOfProductTmp = 0;
      let regularPriceOfProductTmp = 0;
      let totalDiscountTmp = 0;
      item &&
        item.item_data &&
        item.item_data.map((singleData) => {
          setUnitPrice(singleData.price);
          totalQuantityTmp = totalQuantityTmp + singleData.qty;
          priceOfProductTmp += singleData.qty * singleData.price;
          totalDiscountTmp += singleData.qty * singleData.discount;
          regularPriceOfProductTmp += singleData.qty * singleData.regular_price;
        });
      setTotalQuantity(totalQuantityTmp);
      setPriceOfProduct(priceOfProductTmp);
      setRegularPriceOfProduct(regularPriceOfProductTmp);
      setTotalDiscount(totalDiscountTmp);
    } else {
      setPriceOfProduct(item.grand_total);
      setRegularPriceOfProduct(item.grand_total);
    }
    
  }, [item]);

  const handleReorder = () => {
    let apiName = "";
    if (actionForUser === "Cancel") {
      apiName = "order/cancel";
    } else if (actionForUser === "Order again") {
      apiName = "order/repeat";
    } else {
      return;
    }
    return fireApi
      .callApiWithToken(
        apiName,
        { order_id: item._id },
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) return;

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        setConfirmWithUserModal(false)
        window.location.reload()
      });
  };

  return (
    <>
      <Row className="profile-history-singel-order-area mx-0 mx-lg-3">
        <Col
            lg={2} xs={2}
            className="history-singel-medical-img-area align-self-center"
            style={{
            backgroundImage: is_checkout ? `url("${RootThumbImgUrl}${item.item_image}")` : `url("${RootThumbImgUrl}appImage/deli.png")`,
            }}
        ></Col>
        <Col lg={6} xs={5} className="history-singel-title-area align-self-center">
          {
            is_checkout ? (
              <>
                <h4 className="mb-1">{item.item_name}</h4>
                <p className="history-item-desc mb-1">{ 
                <Truncate lines={2} ellipsis={<span>... </span>}>
                    {decodeURIComponent(item.item_desc) || ""}
                </Truncate>
                } </p>
                {history && <span className="order-denger-Btn"> { itemStatus || "" } </span>}
              </>
            ) : (
              <>
                <h4 className="mb-1">{item.order_ref}</h4>
                <p className="history-item-desc mb-1"> <strong>Order Date: </strong> 
                  { moment(new Date(item.placed_on * 1000)).format("DD-MMM -YYYY") } 
                </p>
                {history && <span className="order-denger-Btn"> { itemStatus || "" } </span>}
              </>
            )
          }
            
        </Col>
        <Col lg={4} xs={5} className="history-singel-pricing-area align-self-center">
        {!item.is_showcasing ?
          <p className="pro-pricing-number product-price">
            
          <NumberFormat
              value={priceOfProduct}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`৳`}
              decimalScale={2}
              fixedDecimalScale={true}
              type="currency"
              renderText={(value) => value}
          />
          {!history && totalDiscount>0 &&
          <span className='regular-price'>
            <NumberFormat
                value={regularPriceOfProduct}
                displayType={"text"}
                thousandSeparator={true}
                prefix={`৳`}
                decimalScale={2}
                fixedDecimalScale={true}
                type="currency"
                renderText={(value) => value}
            />
          </span>
          }
          </p> : <CallForPrice />}
          <p>
            {!is_checkout ? 
              <>
              {
                itemStatus !== 'Cancelled' && 
                  <button className="history-btn" type="button"  onClick={() => { 
                    setModalOrderId(item._id)
                    setShowDetailsModal(true)
                    setDetailsClicked(false)
                    }}>
                    {actionForUser} 
                  </button>
              }
              
              <button className="history-btn" type="button"  onClick={() => { 
                setModalOrderId(item._id)
                setShowDetailsModal(true)
                setDetailsClicked(true)
                }}>
                Order Details
              </button>
              </>
              : <></>
            }
            
          </p>
          
        </Col>
      </Row>
      <Modal show={showDetailsModal} centered onHide={handleClose} keyboard={false} size="lg" className="historyAction">
        <Modal.Header closeButton >
          <div style={{ width: "95%" }}>
            <div className="track-details-modal">
              <span>
                <b>Order Ref:</b>
              </span>
              <span>{item.order_ref}</span>
            </div>
            <div className="track-details-modal">
              <span>
                <b>Order Placed On:</b>
              </span>
              <span>
                {new Date(item.placed_on * 1000).toDateString().slice(4)}
              </span>
            </div>
            <div className="track-details-modal">
              <span>
                <b>Payment Method:</b>
              </span>
              <span>{PayementMethods[item.payment_method]}</span>
            </div>
            <div className="track-details-modal">
              <span>
                <b>Order Status:</b>
              </span>
              <span>{itemStatus}</span>
            </div>
            <div style={{ paddingTop: "10px" }}>
              {notCompletedYet && (
                <ProgressBar
                  bgcolor={"#ed1c24"}
                  completed={(item.order_status * 100) / 5}
                />
              )}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="itemsWrapper">
            <OrderItemData order_id={modalOrderId} />
          </div>
        </Modal.Body>
        {!detailsClicked && <Modal.Footer
          className="guest-modal-footer"
          style={{ justifyContent: "center" }}
        >
          <Button
            className="guest-modal-footer-button"
            variant="secondary"
            onClick={() => {
              setShowDetailsModal(false)
              setConfirmWithUserModal(true)
            }}
          >
            {actionForUser}
          </Button>
        </Modal.Footer> }
      </Modal>
      
      <Modal show={confirmWithUserModal} centered onHide={handleClose} keyboard={false}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="guest-modal-message">
          Do you Really Want to {actionForUser} the Current Order.
        </Modal.Body>
        <Modal.Footer
          className="guest-modal-footer"
          style={{ justifyContent: "center" }}
        >
          <Button
            className="guest-modal-footer-button"
            variant="secondary"
            onClick={() => handleReorder()}
          >
            {actionForUser}
          </Button>
          <Button
            className="guest-modal-footer-button" variant="secondary"
            onClick={() => setConfirmWithUserModal(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderHistoryList;
