import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";

import OffersList from "../containers/offersContainer";

import LogicalFuncs from "../logicalFunctions/handleHomeData";
import allActions from "../redux/actions";
import fireApi from "../redux/index";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import { useHistory } from "react-router-dom";
import "../css/offer.css";

const cookies = new Cookies();

const OfferAndDiscount = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const currentOffersFromRedux =
    useSelector((state) => state.offers.offersData) || [];

  const [isGuestUser, setIsGuestUser] = useState(false);
  const [offersDetail, setOffersDetail] = useState([...currentOffersFromRedux]);
  const history = useHistory();
  const userType = cookies.get("user_type");

  useEffect(() => {
    if (userType !== "user") setIsGuestUser(true);
  }, [userType]);

  useEffect(() => {
    fetchdataFromServer();
  }, []);

  useEffect(() => {
    currentOffersFromRedux.length>0 && setOffersDetail(currentOffersFromRedux);
  }, [currentOffersFromRedux]);


  const fetchdataFromServer = () => {
    return fireApi
      .callApiWithToken(
        "offers/get_available",
        {},
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) return;

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        dispatch(allActions.offersActions.offers(res.data.data));
      });
  };

  return (
    <>
      {isGuestUser ? history.push("/Login")
       : <>
          <MainHeader />
          <OffersList offerList={offersDetail} />
          <Footer backToTopVisible />
        </>
      }
    </>
  );
};

export default OfferAndDiscount;
