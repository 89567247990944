import loginActions from './loginActons';
import homeActions from './homeDataActions';
import navHeaderActions from './topNavHeaderAction';
import cartInventoryActions from './cartinventoryActions';
import cartActions from './cartActions';
import offersActions from './offersActions';
import transactionActions from './transationActions';
import prescriptionActions from './prescriptionActions';
import placeOrderActions from './placeOrderActions';
import socketActions from './soketActions';

const allActions = {
  loginActions,
  homeActions,
  navHeaderActions,
  cartActions,
  cartInventoryActions,
  offersActions,
  transactionActions,
  prescriptionActions,
  placeOrderActions,
  socketActions
}

export default allActions