import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import Cookies from "universal-cookie";

import allActions from "../redux/actions";
import fireApi from "../redux/index";

import NoDataFound from "./noDataFound";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";

import doctorDefaultImg from "../images/t1.png";

import "../css/doctor.css";

const cookies = new Cookies();

const DoctorDescription = () => {
  let { docId } = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);

  const [docDetail, setDocDetail] = useState();
  const [requestedForCallMessage, setRequestedForCallMessage] = useState();
  const [requestedForCallWarning, setRequestedForCallWarning] = useState();
  const [showMessage, setShowMessage] = useState(false);

  const handleClose = () => {
    setShowMessage(false);
  };

  const fetchDocData = () => {
    if (
      (currentUser &&
        currentUser._id &&
        currentUser._id !== "101" &&
        currentUser.token) ||
      (cookies.get("user_id") && cookies.get("user_id") !== "101")
    ) {
      return fireApi
        .callApiWithToken(
          "doctors/get_detail",
          { doc_id: docId },
          currentUser.token || cookies.get("token"),
          currentUser._id || cookies.get("user_id")
        )
        .then((res) => {
          if (!res || !res.data || !res.data.status) {
            return;
          }

          if (res && res.token_refreshed) {
            var after7Days = new Date();
            after7Days.setDate(after7Days.getDate() + 7);
            cookies.set("token", res.token, {
              path: "/",
              expires: after7Days,
            });
            dispatch(
              allActions.loginActions.currentUserToStore({
                token: res.token,
              })
            );
          }
          setDocDetail(res.data.data);
        });
    } else return;
  };
  useEffect(() => {
    if (docId) {
      fetchDocData();
    }
  }, [docId]);

  const requestForCall = () => {
    if (docDetail && docDetail.doc_is_online) {
      return fireApi
        .callApiWithToken(
          "doctors/req_callback",
          { doc_id: docId },
          currentUser.token || cookies.get("token"),
          currentUser._id || cookies.get("user_id")
        )
        .then((res) => {
          if (!res || !res.data) {
            return;
          }

          if (res && res.token_refreshed) {
            var after7Days = new Date();
            after7Days.setDate(after7Days.getDate() + 7);
            cookies.set("token", res.token, {
              path: "/",
              expires: after7Days,
            });
            dispatch(
              allActions.loginActions.currentUserToStore({
                token: res.token,
              })
            );
          }
          //   setDocDetail(res.data.data);
          if (res.data.status)
            setRequestedForCallMessage(res.data && res.data.message);
          else setRequestedForCallWarning(res.data && res.data.message);
          setShowMessage(true);
        });
    } else {
      return;
    }
  };

  return (
    <>
      <MainHeader />
      {/* <AdvertismentPanel /> */}

      <div className="doc-detail-description">
        {docDetail ? (
          <Row style={{ paddingBottom: "30px" }}>
            <Col lg={6} ms={12}>
              <div className="doc-details-cont">
                <span style={{ position: "relative" }}>
                  <img src={docDetail.doc_image || doctorDefaultImg} alt="Doctor"/>
                  <span
                    className={`active-dot ${
                      docDetail.doc_is_online ? "online-dot" : "inactive-dot"
                    }`}
                  ></span>
                </span>
                <div className="doc-name-img">
                  <h1>{docDetail.doc_name || ""}</h1>
                  <button
                    className={`${
                      docDetail.doc_is_online
                        ? "call-request-button-active"
                        : "call-request-button"
                    }`}
                    onClick={() => requestForCall()}
                  >
                    Request for call
                  </button>
                </div>
              </div>
              <div className="doc-desig-cont">
                <div className="doc-in-detail-desig">
                  <h3>Designation</h3>
                  <span>{docDetail.doc_designation || ""}</span>
                </div>
                <div className="doc-in-detail-desig mar-t-20">
                  <h3>Experience</h3>
                  <span>{docDetail.doc_experience || ""}</span>
                </div>
              </div>
            </Col>
            <Col lg={6} ms={12} className="pad-left-100 pr-5">
              <div>
                <div className="doc-in-detail-desig mar-t-20">
                  <h3>Specialty</h3>
                  <span>{docDetail.doc_expertise || ""}</span>
                </div>
              </div>
              <div className="doc-qualification-cont">
                <div className="doc-in-detail-desig">
                  <h3>Qualification</h3>
                  <p>{docDetail.doc_qualification || ""}</p>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <NoDataFound />
          </>
        )}
      </div>
      <Footer backToTopVisible />

      <Modal
        show={showMessage}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="guest-modal-header" closeButton></Modal.Header>
        <Modal.Body
          className="guest-modal-message"
          style={{ color: `${requestedForCallWarning ? "red" : "#005e6a"} ` }}
        >
          {requestedForCallWarning || requestedForCallMessage || ""}
        </Modal.Body>
        <Modal.Footer
          className="guest-modal-footer"
          style={{ justifyContent: "center" }}
        >
          <Button
            className="guest-modal-footer-button"
            variant="secondary"
            onClick={handleClose}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DoctorDescription;
