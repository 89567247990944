import React, { useState, useEffect} from 'react';
import {Container, Row, Col } from 'react-bootstrap';
import Cookies from 'universal-cookie';

import MainHeader from '../../../components/MainHeader';
import Footer from '../../../components/footer';
import ProfileNav from '../profileNav';
import OrderDeliveryAddressList from './orderDelivery';
import { useHistory } from "react-router-dom";
import '../../../css/orderDelivery.css';
const cookies = new Cookies();

const OrderDelivery = () => {
	const [isGuestUser, setIsGuestUser] = useState(false);
	const history = useHistory();
	const userType = cookies.get("user_type");

	useEffect(() => {
		if (userType !== "user") setIsGuestUser(true);
	}, [userType]);

	return (
		<>
			{isGuestUser ? (
			history.push("/Login")
			) : (
				<>
					<MainHeader />
					<Container className="profile-pages-content-area-container">
						
							<Row>
								<Col lg={3} md={12} className="profile-pages-content-area-item-nav">
									<ProfileNav />
								</Col>
								<Col lg={9} md={12} className="profile-pages-content-area-item-content">
									<div className="address-list-cont">
									<div className="title-heading">
										<span className="title-heading-small-styles">Other</span>
										Address
									</div>
										<OrderDeliveryAddressList />
									</div>
								</Col>
							</Row>
						
					</Container>
					<Footer backToTopVisible />
				</>
			)}
		</>
	);
};

export default OrderDelivery;
