import React from 'react'

const ProgressBar = (props) => {
    const { bgcolor, completed } = props;
  
    const containerStyles = {
      height: 5,
      backgroundColor: "#e0e0de",
      border:"1px #c2c2c2 solid",
      borderRadius: 50,
      maxWidth: '100%'
    //   margin: "6px 0px 14px 0px"
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right'
    }
  
    // const labelStyles = {
    //   padding:"0px 5px 0px 5px",
    //   color: 'white',
    //   fontWeight: 'bold'
    // }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          {/* <span style={labelStyles}>{`${completed}%`}</span> */}
        </div>
      </div>
    );
  };
  
  export default ProgressBar;