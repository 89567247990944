import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Nav } from 'react-bootstrap';
import allActions from "../redux/actions";
import handleLoginLogics from "../logicalFunctions/handleLogin";

import OtpInput from "react-otp-input";
import Cookies from "universal-cookie";

import { Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import companyLogo from "../images/logo.png";
import "../css/login.css";

const cookies = new Cookies();

const checkBoxStyles = (theme) => ({
  root: {
    "&$checked": {
      color: "#005e6a",
    },
  },
  checked: {},
});
const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const Login = () => {
  const dispatch = useDispatch();
  const [isPhoneNumberWrong, setPhoneNumberWrong] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);
  const [isOtpMatched, setIsOtpMatched] = useState(true);
  const [otpSendResponse, setOtpSendResponse] = useState("");
  const [fullMobileNumber, setFullMobileNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode] = useState("+88");

  const onPhoneNumberChange = (e) => {
    e.persist();
    setPhoneNumber(e.target.value);
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter" && phoneNumber.length === 11) sendOtp();
  };

  const [otp, setOTP] = useState("");
  const handleOTP = async (otpValue) => {
    setOTP(otpValue);

    if (otpValue.length == 5) {
      let isSuccess = await handleLoginLogics.handleLoginOTP({
        fullMobileNumber,
        otpValue,
        dispatch,
        setPhoneNumberWrong,
        setOTP,
        setIsOtpMatched,
        setIsOtpSent,
        allActions,
        cookies,
      });

      if (isSuccess && isSuccess.loginSuccess) {
        window.location.replace("/");
      }
      return;
    }
  };

  const sendOtp = async () => {
    await handleLoginLogics.handleSendOTP({
      phoneNumber,
      setPhoneNumberWrong,
      setIsOtpSent,
      setFullMobileNumber,
      setPhoneNumber,
      setOtpSendResponse,
      setErrorResponse,
    });
    return;
  };

  return (
    <div className="login-page-container">
      <div className="login-page-company-logo">
        <Nav.Link as={Link} to="/"  style={{ textAlign: "center" }}>
            <img style={{ width: "30%" }} src={companyLogo}/>
        </Nav.Link>
      </div>
      <div className="login-page-user-form">
        <div className="user-form-content">
          <Nav.Link as={Link} to="/"  className="mobile-logo d-block d-md-none" >
              <img  src={companyLogo}/>
          </Nav.Link>
          <h3 className="login-signup-heading">Sign In</h3>
          <div className="phone-number-input">
            <input
              className="fix-country-code"
              type="text"
              name="country_code"
              value={countryCode}
              width="20%"
              readOnly={true}
            />
            <input
              className="input-mobile-number"
              maxLength="11"
              type="text"
              value={phoneNumber}
              name="phone_number"
              onChange={(e) => onPhoneNumberChange(e)}
              onKeyPress={(e) => handleKeypress(e)}
            />
            <CustomCheckbox className="remember-me-checkbox" />
          </div>
          {isPhoneNumberWrong && (
            <p style={{ color: "red" }}>Please Enter a valid phone number</p>
          )}
          <button className="send-otp-button" onClick={sendOtp}>Send OTP</button>
          {otpSendResponse !== "" && (
            <p className={`otpSentMsg ${errorResponse ? 'error':''}`}>{ otpSendResponse }</p>
          )}
          <div className="otp-input">
            <OtpInput
              className="abc"
              value={otp}
              onChange={handleOTP}
              numInputs={5}
              separator={<span className="otp-bars-seperator"></span>}
              inputStyle={{
                border: "none",
                width: "70%",
                borderBottom: "1px solid grey",
                margin: "5px",
              }}
              isInputNum={true}
            />
          </div>
          {!isOtpMatched && (<p className="invalidOtpMsg">Please Enter a valid OTP</p>)}
          <div className="resend-otp-link">
            <span style={{ color: "#8b8b8b" }}>Didn't receive the OTP? </span>
            <a className="link-space" href="#">
              Resend
            </a>
          </div>
        </div>

        <div className="terms-condition">
          <span>A</span>
          <a>Japan - Bangladesh</a>
          <span> Joint Venture</span>
        </div>
      </div>
    </div>
  );
};

export default Login;
