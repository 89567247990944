import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { RootImgUrl } from "../../../constants/const";

import "../../../css/orderHistory.css";

const OrderHistoryListModal = ({ item }) => {
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [priceOfProduct, setPriceOfProduct] = useState(0);

  return (
    <>
      <Row className="order-history-list-cont checkout-single-product-area">
        {totalQuantity !== 0 && (
          <>
            <Col
              lg={2}
              md={2}
              sm={12}
              className="checkout-product-image-area checkout-single-product-content"
              style={{
                backgroundImage: `url(${RootImgUrl}${item.item_image})`,
              }}
            ></Col>
            <Col
              lg={4}
              md={4}
              sm={12}
              className="checkout-product-details-area checkout-single-product-content align-self-center"
            >
              <h4>{item.item_name || item.order_ref}</h4>

              <p>
                {(item.item_desc && decodeURIComponent(item.item_desc)) ||
                  new Date(item.placed_on * 1000).toDateString().slice(4)}
              </p>
            </Col>

            <Col
              lg={1}
              md={4}
              sm={12}
              className="checkout-product-quantity-area checkout-single-product-content align-self-center"
            >
              <p className="pro-pricing-number">
                <span className="quantity">X</span>
                <span className="quantity">{totalQuantity}</span>
              </p>
            </Col>

            <Col
              lg={2}
              md={4}
              sm={12}
              className="checkout-product-total-area checkout-single-product-content align-self-center"
            >
              <p className="pro-pricing-number">
                <NumberFormat
                  value={priceOfProduct}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={`৳`}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  type="currency"
                  renderText={(value) => value}
                />
              </p>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default OrderHistoryListModal;
