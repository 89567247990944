import React from "react";
import { Modal, Button } from "react-bootstrap";

const GeneralAlertModal = ({
  show,
  message,
  buttonText,
  handleModalClose,
  handlePositiveAction
}) => {
  
  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      className="arrange-me-modal-area"
    >
      <Modal.Header className="guest-modal-header" closeButton></Modal.Header>
      <Modal.Body className="guest-modal-message justify-content-center">
        {message}
      </Modal.Body>
      <Modal.Footer
        className="guest-modal-footer"
      >
        <Button
          className="guest-modal-footer-button"
          variant="secondary"
          onClick={handlePositiveAction}
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default GeneralAlertModal;
