import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "./../app/noDataFound";
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import allActions from "../redux/actions";
import fireApi from "../redux/index";
import MetaDecorator from '../components/MetaDecorator';
import { seo } from "../helpers/seo";

import ProductListComponent from "../components/ProductList";
import { RootImgUrl } from "../constants/const";

import "../css/diseaseDetail.css";

const cookies = new Cookies();

const DiseaseDetailPage = () => {
  const { disId } = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);

  const [currentDiseaseDetail, setCurrentDiseaseDetail] = useState({});
  const [relatedMedicines, setRelatedMedicines] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [seoData, setSeoData] = useState(null)

  const fetchdataFromServer = () => {
    if (!disId) return;
    
    return fireApi
      .callApiWithToken(
        "otc_disease/get_by_alias",
        { alias: disId },
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        const diesesDetails = res.data.data
        setSeoData({
          title: diesesDetails.seo_title,
          description: diesesDetails.seo_description,
          keywords: diesesDetails.seo_keywords,
          og_url:`/disease/${disId}`,
          og_imageUrl:`${RootImgUrl}${diesesDetails.dis_image}`
        })
        setCurrentDiseaseDetail(diesesDetails);
      });
  };

  const fetchRelatedMedicines = () => {
    if (!disId || !currentDiseaseDetail) {return;}
    return fireApi
      .callApiWithToken(
        "medicines/get_med_by_dis_alias_w_pag",
        { alias: disId, page: page, limit: 10 },
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          setHasMore(false);
          return;
        }

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        setPage(page + 1);
        let newData = [...relatedMedicines, ...res.data.data];
        setRelatedMedicines(newData);
      });
  };

  useEffect(() => {
    fetchRelatedMedicines();
    fetchdataFromServer();
  }, [disId]);

  return (
    <Container className="disease-inner-page">
      {seoData !==null && <MetaDecorator seo = {seoData} /> }
      {currentDiseaseDetail.dis_image && (
        <Row className="disease-details">
          <Col lg={4}>
            <div className="disease-image-cont-compo">
              <img src={`${RootImgUrl}${currentDiseaseDetail.dis_image}`} alt={currentDiseaseDetail.dis_name}/>
            </div>
          </Col>
          <Col lg={8} className="disease-name-detail">
            <h3 className="">{currentDiseaseDetail.dis_name || ""}</h3>
            <div className="disease-description">
              <span>
                <b>{currentDiseaseDetail.dis_desc && "Description:"}</b>
              </span>
              <ShowMoreText
                lines={2}
                more="Show more"
                less="Show less"
                expanded={false}
              >
                {decodeURIComponent(currentDiseaseDetail.dis_desc || "")}
              </ShowMoreText>
            </div>
          </Col>
        </Row>
      )}

      {relatedMedicines && relatedMedicines.length ? (
        <Row>
          <Col>
            <InfiniteScroll
              dataLength={relatedMedicines.length}
              next={fetchRelatedMedicines}
              hasMore={hasMore}
              scrollThreshold={1}
              loader={ <h4 style={{ display: "flex", justifyContent: "center" }}>Loading...</h4>}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div className="all-prod-list-cont">
                {relatedMedicines.map((item) => {
                  let imageToShow =
                    (item &&
                      (item.dis_image ||
                        (item.images && item.images[0] && item.images[0].img) ||
                        item.doc_image)) ||
                    "";
                  let name =
                    (item &&
                      (item.dis_name ||
                        item.brand_name ||
                        item.prod_name ||
                        item.doc_name)) ||
                    "";
                  let description =
                    (item &&
                      (item.dis_desc ||
                        item.description ||
                        item.prod_desc ||
                        item.doc_designation)) ||
                    "";
                  let price =
                    (item &&
                      item.inventory &&
                      item.inventory[0] &&
                      item.inventory[0].price) ||
                    "";
                    let regular_price =
                    (item &&
                      item.inventory &&
                      item.inventory[0] &&
                      item.inventory[0].regular_price) ||
                    "";
                  let discount =
                    (item &&
                      item.inventory &&
                      item.inventory[0] &&
                      item.inventory[0].discount) ||
                    0;
                  return (
                    <div className="single-prod-cont" key={item._id}>
                      <ProductListComponent
                        item={{
                          cat_name: "otc-medicines",
                          cat_type: "prescribed",
                          alias: item.alias,
                          is_showcasing:item.is_showcasing
                        }}
                        name={name}
                        imageToShow={imageToShow}
                        description={description}
                        itemId={item._id}
                        price={price}
                        regular_price={regular_price}
                        discount={discount}
                      />
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          </Col>
        </Row>
      ) : (
        <NoDataFound />
      )}
    </Container>
  );
};

export default DiseaseDetailPage;
