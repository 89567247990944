import React, { useState } from "react";
import Cookies from "universal-cookie";
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import { NavLink, Link } from "react-router-dom";
import {Row, Col, Modal, Button, Container, Nav, } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import fireApi from "../redux/index";
import allActions from "../redux/actions";
const baseOfferImageUrl = `https://dummyimage.com/200x180/dddddd/969696.jpg&text=`

const cookies = new Cookies();

const OffersList = ({ offerList = [] }) => {
    const dispatch = useDispatch();
    const isOfferApplied = useSelector((state) => state.placeOrder.placeOrderData);
    const currentUser = useSelector((state) => state.login.currentUser);
    const [viewDetails, setViewDetails] = useState(false);
    const [showIndex, setShowIndex] = useState();
    const hideDetails = () => {
        setViewDetails(false);
    }

    const handleApplyOffer = (offer) => {
        return fireApi
        .callApiWithToken(
            "offers/apply",
            {},
            currentUser.token || cookies.get("token"),
            currentUser._id || cookies.get("user_id")
        )
        .then((res) => {
            if (!res || !res.data || !res.data.status)  return;

            if (res && res.token_refreshed) {
                LogicalFuncs.storeRefreshToken({
                    res,dispatch,allActions,cookies,
                });
            }
            dispatch(allActions.placeOrderActions.placeOrder({ ...isOfferApplied, offerApplied: offer }))
        });
        
    }

    return (
        <>
        <Container className="offer-page-container">
            <Row className="breadcum-title-area">
                <Col xl={4} sm={12} className="breadcum-title">
                <h1>Available Offers</h1>
                </Col>
            </Row>
            <Row>
             {offerList && offerList.map((offer, offerIndex) => (
                <Col xl={4} lg={6} md={12} sm={12} className="single-offer-area">
                    <Row>
                       <Col xl={4} lg={4} md={4} className="single-offer-img-area align-self-center" style={{backgroundImage: `url(${baseOfferImageUrl}Osudpotro${offer.discount})`}}>
                       </Col> 
                       <Col xl={8} lg={8} md={8} className="single-offer-content-area align-self-center">
                           <span className="discount-offer-name">Welcome Bonus-{offerIndex+1}</span>
                           <span className="offer-disount-quanity">{offer.offer_desc}</span>
                           <span className="offer-validity">valid on orders avobe ৳2500</span>
                           <div className="offerActionBtn">
                                <Nav.Link as={Link}  onClick={() => handleApplyOffer(offer)}> {offer.offer_code}</Nav.Link>
                                <NavLink to='#'
                                onClick={() => {
                                    setViewDetails(true);
                                    setShowIndex(offerIndex)
                                }}
                                >View Detail</NavLink>
                           </div>
                        </Col> 
                    </Row>
                </Col>
                ))}
                
                 
            </Row>
        
            </Container>

            <Modal
                show={viewDetails}
                onHide={hideDetails}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body >
                    <div className='offer-name' style={{ textAlign: "center" }}>{showIndex !== undefined && offerList[showIndex].offer_code}</div>
                    <div className='offer-desc'>{showIndex !== undefined && offerList[showIndex].offer_desc}</div>
                </Modal.Body>
                <Modal.Footer
                    className="guest-modal-footer"
                    style={{ justifyContent: "center" }}
                >
                    <Button
                        className="guest-modal-footer-button"
                        variant="secondary"
                        onClick={hideDetails}
                    >OK</Button>
                </Modal.Footer>
            </Modal>
          
        </>
    )
}

export default OffersList;