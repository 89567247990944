const defaultState = {
    homeData: [],
    sliders:[],
    mobileSliders:[]
  }
  
  const home = (state = defaultState, action) => {
    switch (action.type) {
    case 'SET_HOME_DATA':
        return {
            ...state,
            homeData: action.homeData
        }
    case 'SET_HOME_SLIDERS':
        let sliders = [],
        mobileSliders = [];
        action.sliders.map(slider=>{
            if(slider.is_mobile)
                mobileSliders.push(slider)
            else
                sliders.push(slider)
        })
        return {...state,sliders,mobileSliders}
    default:
        return state
    }
}

export default home