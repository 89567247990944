import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Container, Row, Col, Form } from "react-bootstrap";
import {CATEGORY_URLS} from "../constants/const"
import SearchComponent from "../components/SearchComponent";
import "../css/top-nav-header.css";
const FontAwesome = require("react-fontawesome");

const TopNavHeader = () => {
  const [leftSideMenu, setLeftSideMenu] = useState(false);

  const getNavItem = (start = "", end = "") =>{
   return CATEGORY_URLS.map((navItem,index) => {
     
      if(start === "" && end === ""){
        return (
          <Nav.Item as="li" key={index}>
            <NavLink to={navItem.path} replace>
              {navItem.name.split("_").join(" ")}
            </NavLink>
          </Nav.Item>
        );
      }
      if((start !== "" && end !=="") && (index >= start && index < end)){
        return (
          <Nav.Item as="li" key={index}>
            <NavLink to={navItem.path} replace>
              {navItem.name}
            </NavLink>
          </Nav.Item>
        );
      } 
    })
  }

  return (
    <>
      <div className="nav-menu-header-cont-higher-width">
        <Container>
          <Row>
            <Col>
              <Navbar>
                <Nav className="m-auto main-menu-area navbar-nav d-flex justify-content-between" as="ul">
                  <Nav.Item as="li" className="home-static-menu" >
                    <NavLink to="/"><i className="material-icons">home</i></NavLink>
                  </Nav.Item>
                  { getNavItem(0,8) }

                  <div className="more-menu-btn">
                    <i className="material-icons double_arrow">double_arrow</i>
                    <div className="home-dropdown-menu">{ getNavItem(8,12) }</div>
                 </div>
                </Nav>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="nav-menu-header-cont-smaller-width">
        <Form inline className="top-schBar"><SearchComponent /></Form>
        <FontAwesome name={leftSideMenu ? "times" : "bars" } className="bar-icon" onClick={() => setLeftSideMenu(currLeftSideMenu=> !currLeftSideMenu)} />
      </div>
      {leftSideMenu && ( <ul className="mbl-Menu" >{ getNavItem() }</ul>)}
    </>
  )
}

export default TopNavHeader;
