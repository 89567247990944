const offers = (state = [], action) => {
    switch (action.type) {
    case 'OFFERS':
        return {
            ...state,
            offersData: action.offersData
        }
    default:
        return state
    }
}

export default offers