import React, { useState, useEffect } from "react";

import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../css/index.css";

const AlertModal = ({
  closeBtn = false,
  show,
  handleModalClose,
  message,
  buttonText,
  link,
  linkText,
}) => {
  const [showModal, setShowModal] = useState(false);
  const history = new useHistory();

  const handleOk = () => {
    handleModalClose();
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);
  const handleWallet = () => {
    history.push(link);
  };
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop="static"
      keyboard={false}
      centered={true}
      className="arrange-me-modal-area"
    >
      {closeBtn && <Modal.Header className="guest-modal-header" closeButton></Modal.Header> }
      <Modal.Body className="guest-modal-message justify-content-center" dangerouslySetInnerHTML={{__html: message}}>
      </Modal.Body>
      <Modal.Footer
        className="guest-modal-footer"
      >
        {link && (
          <Button
            className="guest-modal-footer-button"
            variant="secondary"
            onClick={handleWallet}
          >
            {linkText}
          </Button>
        )}
        {buttonText && (
          <Button
          className="guest-modal-footer-button"
          variant="secondary"
          onClick={handleOk}
        >
          {buttonText}
        </Button>
        )}
        
      </Modal.Footer>
    </Modal>
  );
};
export default AlertModal;
