import React, { useEffect, useState } from "react";
import { Row, Col, Container, ListGroup, NavLink } from "react-bootstrap";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";

import fireApi from "../redux/index";
import allActions from "../redux/actions";
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import ProfileNav from "./profile/profileNav";
import "../css/orderHistory.css";
import { useHistory } from "react-router-dom";
import TrackOderItem from "../components/trackOrderItem";
const cookies = new Cookies();

const TrackOrder = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);

  const [isGuestUser, setIsGuestUser] = useState(false);
  const [trackOrderList, setTrackOrderList] = useState([]);
  const history = useHistory();
  useEffect(() => {
    fetchDataFromServer();
    let _id = cookies.get("user_id") || localStorage.getItem("user_id");
    if (_id === "101") setIsGuestUser(true);
  }, []);

  const fetchDataFromServer = () => {
    return fireApi
      .callApiWithToken(
        "order/history",
        {},
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        let apiResponse = res.data.data;
        if (apiResponse) {
          let historyRelevantData = apiResponse.filter(
            (data) => data.order_status !== 6 || data.order_status !== 7
          );
          setTrackOrderList(historyRelevantData);
        }
      });
  };
  return (
    <>
      {isGuestUser ? (
        history.push("/Login")
      ) : (
        <>
          <MainHeader />
          <Container className="profile-pages-content-area-container">
            <Row>
              <Col
                lg={3}
                md={12}
                className="profile-pages-content-area-item-nav"
              >
                <ProfileNav />
              </Col>
              <Col
                lg={9}
                md={12}
                className="profile-pages-content-area-item-content"
              >
                <div className="title-heading">
                  <span className="title-heading-small-styles">Track</span>
                  order
                </div>
                <Container>
                  {trackOrderList.map((item) => (
                    <TrackOderItem item={item} key={item._id} />
                  ))}
                </Container>
              </Col>
            </Row>
          </Container>
          <Footer backToTopVisible />
        </>
      )}
    </>
  );
};

export default TrackOrder;
// 0 -> Pending, 1 -> Accepted, 2 -> Packaging, 3 -> Dispatched, 4 -> On the Way, 5 -> Arrived,
// 6 -> Delivered, 7 -> Cancelled, 8 -> Unknown State;
