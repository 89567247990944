import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Container, Row, Col ,Modal,Button} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { localUserkey } from "../../../constants/const";
import LogicalFuncs from '../../../logicalFunctions/handleHomeData';
import allActions from '../../../redux/actions';
import fireApi from '../../../redux/index';
import MainHeader from '../../../components/MainHeader';
import Footer from '../../../components/footer';
import ProfileNav from '../profileNav';
import pocketTotal from '../../../images/pocket-total.png';
import NumberFormat from 'react-number-format';
import { useHistory } from "react-router-dom";
import '../../../css/balance.css';

const cookies = new Cookies();
const localUserDetails = JSON.parse(localStorage.getItem(localUserkey))

const Balance = () => {
	const dispatch = useDispatch();
	const [currentUser, setCurrentUser] = useState(useSelector((state) => state.login.currentUser));
	const [isGuestUser, setIsGuestUser] = useState(false);
	const history = useHistory();
	const userType = cookies.get("user_type");
	const [show, setShow] = useState(false);
	const [amount,setAmount] = useState(0);
	
	useEffect(() => {
		fetchDataFromServer();
	}, []);

	useEffect(() => {
		if (userType !== "user") setIsGuestUser(true);
	}, [userType]);
	
	const fetchDataFromServer = () => {
		return fireApi.callApiWithToken(
			'users/get_detail',{},
			currentUser.token || cookies.get('token'),
			currentUser._id || cookies.get('user_id')
		)
		.then((res) => {
			if (!res || !res.data || !res.data.status) return;
			if (res && res.token_refreshed) LogicalFuncs.storeRefreshToken({res,dispatch,allActions,cookies})
			const updateDetails = {...localUserDetails,...currentUser, wallet_amount: res.data.data.wallet_amount };
			setCurrentUser(updateDetails)
			localStorage.setItem(localUserkey,JSON.stringify(updateDetails))
			dispatch(allActions.loginActions.currentUserToStore(updateDetails));
		});
	};
	
	const makePayment = async () => {
		const timestamp = new Date().getTime();
		const params = {
			amount: parseFloat(amount).toFixed(2),
			trx_id: "OPWA" + timestamp,
			payment_status:'true',
		};
		return await fireApi.callApiWithToken(
			"wallet/add_money_web",
			params,
			currentUser.token || cookies.get("token"),
			currentUser._id || cookies.get("user_id") || localStorage.getItem("user_id")
		).then((response) => {
			window.location.replace(response.data.gatewayPageUrl)
		});
	};

	return (
		<>
			{isGuestUser ? history.push("/Login")
			: (
				<>
                    <MainHeader />
					<Container className="profile-pages-content-area-container profile-wallet-content-container">
						<Row>
							<Col lg={3} md={12} className="profile-pages-content-area-item-nav">
								<ProfileNav />
							</Col>
							<Col lg={9} md={12} className="profile-pages-content-area-item-content">
								<div className="address-list-cont">
								<div className="title-heading">
									<span className="title-heading-small-styles">OsudPotro</span>
									Wallet
								</div>
								<Container className="wallet-area">
									<Row className="profile-history-singel-order-area total-balance-area pb-0">
										<Col xl={2} lg={2} md={2} sm={12} xs={12} className="history-singel-medical-img-area balance-area-styles align-self-center">
											<img src={pocketTotal} />
										</Col>
										<Col xl={6} lg={6} md={6} sm={12} xs={12} className="history-singel-title-area align-self-center">
											<span className="product-name">Total Balance</span>
										</Col>
										<Col xl={4} lg={4} md={4} sm={12} xs={12} className="history-singel-pricing-area align-self-center">
											<p className="pro-pricing-number wallaet-number-profile">
												<NumberFormat
													value={currentUser.wallet_amount}
													displayType={'text'}
													thousandSeparator={true}
													prefix={`৳`}
													decimalScale={2}
													fixedDecimalScale={true}
													type="currency"
													renderText={(value) => value}
												/>
											</p>
										</Col>
									</Row>

									<hr/>
																		
									<Row className="profile-history-singel-order-area total-balance-area pt-0">
										<Col xl={2} lg={2} md={2} sm={12} xs={12} className="history-singel-medical-img-area balance-area-styles align-self-center">
											<img src={pocketTotal} />
										</Col>
										<Col xl={6} lg={6} md={6} sm={12} xs={12} className="history-singel-title-area align-self-center">
											<span className="product-name">Wallet bonus</span>
											<p>You can spend this bonus after purchasing 1000 BDT</p>
										</Col>
										<Col xl={4} lg={4} md={4} sm={12} xs={12} className="history-singel-pricing-area align-self-center">
											<p className="pro-pricing-number wallaet-number-profile">
												<NumberFormat
													value={currentUser.wallet_gift}
													displayType={'text'}
													thousandSeparator={true}
													prefix={`৳`}
													decimalScale={2}
													fixedDecimalScale={true}
													type="currency"
													renderText={(value) => value}
												/>
											</p>
										</Col>
									</Row>


									<Row className="wallet-btn-action">
										<Col lg={12} sm={12}>
										<button className="add-address-button" onClick={() => setShow(true)}>
											Add Money
										</button>
										<button className="add-address-button">
										<NavLink to="/transaction">View History</NavLink>
										</button>
										</Col>
									</Row>
									<Row>
										<Col lg={12} sm={12} className="ousud-potro-details">
											<h2>What is OsudPotro Cash?</h2>
											<p>OSUDPOTRO Nagad is a digital wallet that you can use for your transaction at OSUDPOTRO. You can add credit to your wallet &amp; spend buying medicine only from OSUDPOTRO. Whenever you will receive cashback from OSUDPOTRO that will automatically be added to your wallet.</p>
										</Col>
									</Row>
								</Container>
							</div>
						</Col>
						</Row>
					</Container>
					<Footer backToTopVisible />	
				</>
			)}

			<Modal show={show} keyboard={true} onHide={() => setShow(false)}  size="xs" centered className="wallet-modal">
					<Modal.Header closeButton>
					<Modal.Title>Add Wallet Money</Modal.Title>
					</Modal.Header>
					<Modal.Body className="addmoneyModalBody">
						<input
							type="number"
							name="name"
							value={amount}
							className="addmoneyinput"
							placeholder="Enter Ammount"
							onChange={(e) => setAmount(e.target.value)}
						/>
					</Modal.Body>
					<Modal.Footer>
					<Button
					onClick={makePayment}
					className="add-address-modal-footer-button"
					variant="secondary"
					>
						Add Money
					</Button>
					</Modal.Footer>
			</Modal>
		</>
	);
};

export default Balance;
