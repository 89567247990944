import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import CartListCont from "../containers/cart/cartListCont";
import PurchaseSummary from "../containers/cart/purchaseTotal";

import { useHistory } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";

import "../css/cart/cart.css";

const cookies = new Cookies();

const Cart = ({ orderHistory = false }) => {
  const [isGuestUser, setIsGuestUser] = useState(false);
  const currentCart = useSelector((state) => state.cart);
  const cartData = currentCart.cartData;
  let userType = cookies.get("user_type");
  const history = useHistory();

  useEffect(() => {
		window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    if (userType !== "user") setIsGuestUser(true);
  }, [userType]);

  return (
    <>
      {isGuestUser ? history.push("/Login")
      : (
        <>
          {!orderHistory &&  <MainHeader /> }
          <Container className="px-0">
            {!orderHistory && <p className="cart-heading">Cart</p>}
            <Row className="cart-page-content-area">
              <Col lg={8}>
                <CartListCont />
              </Col>
              {cartData && cartData.length ?  
              <Col lg={4} className="purchase-summary-checkout-area mx-5 mx-lg-0">
                <div className="purchase-summary-checkout purchase-summary-checkout-btn">
                  <PurchaseSummary />
                  <div className="d-flex justify-content-center purchase-summary-checkout-btns">
                    <NavLink to="/">
                      <button className="checkout-edit-order buy-more mt-5">
                        Buy More
                      </button>
                    </NavLink>
                    <span className="d-flex justify-content-center purchase-summary-checkout-btns">
                      <NavLink to="/checkout-online-medicine-drug-store-bangladesh">
                        <button className="checkout-proceed-btn mt-5">
                          Checkout
                        </button>
                      </NavLink>
                    </span>
                  </div> 
                </div>
              </Col>: <></> }
            </Row>
          </Container>
          {!orderHistory ? <Footer backToTopVisible /> : <></>}
        </>
      )}
    </>
  );
};

export default Cart;
