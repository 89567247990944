import { RootImgUrl } from "../constants/const";

export function seo(data = {}) {
    data.title = data.title || 'Online Pharmacy|Ordering Medicines Online In Dhaka| OsudPotro'
    data.metaDescription = data.metaDescription || `Online Medicine orders: You can order medicines online with Osudpotro and they will be delivered to you within 4-5 hours in Dhaka Cities`;
    data.metaKeywords = data.metaKeywords || `pharmacy, online pharmacy, ordering medicines online`
    data.pageImage = data.pageImage || `${RootImgUrl}ogimage/home-og.webp`
    data.pageUrl = data.pageUrl || '/'

    document.title = data.title;
    document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
    document.querySelector('meta[name="keywords"]').setAttribute('content', data.metaKeywords);
    document.querySelector('meta[property="og:title"]').setAttribute('content', data.title);
    document.querySelector('meta[property="og:description"]').setAttribute('content', data.metaDescription);
    document.querySelector('meta[property="og:image"]').setAttribute('content', data.pageImage);
    document.querySelector('meta[property="og:url"]').setAttribute('content', data.pageUrl);
  }