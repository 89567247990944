import React, { useEffect } from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import "../css/footerPagesStyles.css";
import { Row, Col, Container } from "react-bootstrap";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MainHeader />
      <Container className="footer-related-page-area">
        <Row>
          <Col>
            <span className="page-title">Disclaimer</span>
            <span className="page-subtitles-bold">
              LEGAL DISCLAIMER CONDITIONS FOR ACCESS AND USE:
            </span>

            <p>
              Welcome to the Osudpotro website. All of the information provided
              in and through the Website located at www.osudpotro.com is
              intended solely for general information and should NOT be relied
              upon for any particular diagnosis, treatment, or care. This
              website is a substitute for medical advice. The website is only
              for general medicine informational purposes.
            </p>
            <p>
              The information contained on this Website is a substitute for
              medical advice or treatment, and again the author of
              www.osudpotro.com strongly encourages clients and their families
              to consult with qualified medical professionals for treatment and
              related advice on individual cases.
            </p>
            <p>
              It is strongly encouraged that clients and their families consult
              with qualified medical professionals for treatment and related
              advice on individual cases before beginning any medicine.
              Decisions relating to the prevention, detection and treatment of
              all health issues should be made only after discussing the risks
              and benefits with your healthcare provider, taking into account
              your personal medical history, your current situation and your
              future health risks and concerns. If you are pregnant, nursing,
              diabetic, on medication, have a medical condition, or are
              beginning a health or weight control program, consult your
              physician before using medicine or services discussed on this
              website and before making any other changes. This medicine is
              recommended or supported for those of any age. By using this Web
              site, you represent that you are any years old.
            </p>
            <p>
              The authors can guarantee that the information on this Website is
              safe and proper for every reader. For this reason, this Website is
              offered without warranties or guarantees of any kind, express or
              implied, and the authors disclaim any liability, loss or damage
              caused by the contents, either directly or consequentially. The
              Bangladesh government regulatory body has not evaluated statements
              made in this Website. Products, services and methods discussed in
              this Website are intended to diagnose, treat, cure or prevent any
              disease.
            </p>

            <span className="page-paragraph-title">
              Disclaimer of Liability:
            </span>
            <p>
              Neither Osudpotro nor its contributors shall be held liable for
              any improper or incorrect use of the information described and/or
              contained herein and assumes no responsibility for anyone's use of
              the information
            </p>
            <p>
              In no event shall Osudpotro website or its contributors be liable
              for any direct, indirect, incidental, special, exemplary, or
              consequential damages (including, but not limited to: procurement
              of substitute goods or services; loss of use, data, or profits; or
              business interruption) however caused and on any theory of
              liability, whether in contract, strict liability, tort (including
              negligence or otherwise), or any other theory arising in any way
              out of the use of this system, even if advised of the possibility
              of such damage.
            </p>
            <p>
              This disclaimer of liability applies to any damages or injury,
              whether based on alleged breach of contract, tortuous behavior,
              negligence or any other cause of action, including but not limited
              to damages or injuries caused by any failure of performance,
              error, omission, interruption, deletion, defect, delay in
              operation or transmission, computer virus, communication line
              failure, and/or theft, destruction or unauthorized access to,
              alteration of, or use of any record.
            </p>
            <span className="page-paragraph-title">Indemnification:</span>
            <p>
              User agrees to defend, indemnify, and hold harmless, Osudpotro
              website, its contributors, any entity jointly created by them,
              their respective affiliates and their respective directors,
              officers, employees, and agents from and against all claims and
              expenses, including attorneys' fees, arising out of the use of the
              on-line service by user or user's account.
            </p>
            <span className="page-paragraph-title">
              Disclaimer of Warranties/Accuracy and Use of Data/Computer
              Viruses:
            </span>
            <p>
              Although the data found using Osudpotro website has been produced
              and processed from sources believed to be reliable, no warranty
              expressed or implied is made regarding accuracy, adequacy,
              completeness, legality, reliability or usefulness of any
              information.
            </p>
            <p>
              This disclaimer applies to both isolated and aggregate uses of the
              information. Osudpotro provide this information on an "AS IS"
              basis. All warranties of any kind express or implied, including
              but not limited to the IMPLIED WARRANTIES OF MERCHANT ABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, freedom from contamination by
              computer viruses and non-infringement of proprietary rights ARE
              DISCLAIMED.
            </p>
            <p>
              Changes may be periodically added to the information herein; these
              changes may or may not be incorporated in any new version of the
              publication. If the user has obtained information from Osudpotro
              from a source other than Osudpotro, the user must be aware that
              electronic data can be altered subsequent to original
              distribution. Data can also quickly become out-of-date.
            </p>
            <p>
              It is recommended that the user pay careful attention to the
              contents of any metadata associated with a file, and that the
              originator of the data or information can be contacted with any
              questions regarding appropriate use.
            </p>
            <span className="page-paragraph-title">
              Disclaimer of Endorsement:
            </span>
            <p>
              Osudpotro is a distributor of content sometimes supplied by third
              parties and users. Any opinions, advice, statements, services,
              offers, or other information or content expressed or made
              available by third parties, including information providers,
              users, or others, are those of the respective author(s) or
              distributor(s) and do not necessarily state or reflect those of
              Osudpotro.
            </p>
            <p>
              Reference herein to any specific commercial products, process, or
              service by trade name, trademark, manufacturer, or otherwise, does
              not constitute or imply its endorsement, recommendation, or
              favoring by Osudpotro , and such reference shall not be used for
              advertising or product endorsement purposes.
            </p>
            <span className="page-paragraph-title">
              Disclaimer for Hypertext Links:
            </span>
            <p>
              Neither Osudpotro nor its contributors is responsible for the
              contents of any off-site pages that reference, or that are
              referenced by Osudpotro. The user specifically acknowledges that
              neither Osudpotro nor its contributors is liable for any
              defamatory, offensive, misleading or illegal conduct of other
              users, links, or third parties and that the risk of injury from
              the foregoing rests entirely with the user.
            </p>
            <p>
              Links from Osudpotro on the World Wide Web to other sites, or from
              other sites to Osudpotro, do not constitute an endorsement by
              Osudpotro . These links are for convenience only. It is the
              responsibility of the user to evaluate the content and usefulness
              of information obtained from other sites.
            </p>
            <span className="page-paragraph-title">
              Disclaimer of Duty to Continue Provision of the Data:
            </span>
            <p>
              Due to the dynamic nature of the Internet, resources that are free
              and publicly available one day may require a fee or restrict
              access the next, and the location of items may change as menus,
              home-pages, and files are reorganized.
            </p>
            <p>
              User expressly agrees that use of Osudpotro is at the user's sole
              risk. Neither Osudpotro , any entity jointly created by them, any
              of their affiliates, or any of their respective directors,
              officers, employees, agents, third party content providers or
              licensees warrants that the service will be uninterrupted or error
              free.
            </p>
            <span className="page-paragraph-title">Choice of Law:</span>
            <p>
              Construction of the disclaimers above and resolution of disputes
              thereof are governed by the laws of the State of Wyoming.
            </p>
            <span className="page-subtitles-bold">
              ADDITIONAL LEGAL INFORMATION AND TERMS OF USE
            </span>
            <span className="page-paragraph-title">Scope of use:</span>
            <p>
              Osudpotro invites you to view, use and download a single copy of
              this Website for your personal information, non-commercial use
              provided that you keep intact all copyright, trademark and other
              proprietary rights notices.
            </p>
            <span className="page-paragraph-title">
              Rules of conduct: No Warranties:
            </span>
            <p>
              All content on this Website is provided to you on an “as is,” “as
              available” basis without warranty of any kind, either express or
              implied, including but not limited to the implied warranties of
              merchantability , fitness for a particular purpose, and
              non-infringement. Osudpotro makes no warranty as to the accuracy,
              completeness, currency, or reliability of any content available
              through this web site. Osudpotro makes no representations or
              warranties that use of the Website will be uninterrupted or
              error-free. You are responsible for taking all precautions
              necessary to ensure that any content you may obtain from the
              Website is free of viruses.
            </p>
            <span className="page-paragraph-title">
              Limitation of Liability:
            </span>
            <p>
              Osudpotro specifically disclaims any and all liability, whether
              based in contract, strict liability or otherwise, for any direct,
              indirect, incidental, consequential, or special damages arising
              out of or in any way connected with access to or use of the
              Website even if Osudpotro has been advised of the possibility of
              such damages, including but not limited to reliance by any party
              on any content obtained through use of this Website, or that
              arises in connection with mistakes or omissions in, or delays in
              transmission of, information to or from the user, interruptions in
              telecommunications connections to the Website or viruses, whether
              caused in whole or in part by negligence, acts of god,
              telecommunications failure, theft or destruction of, or
              unauthorized access to the Website or related information.
            </p>
            <span className="page-paragraph-title">Email:</span>
            <p>
              You understand that the Internet is public and that e-mail
              communications between you and Osudpotro via this Website are
              subject to the risk of being viewed by other parties. Accordingly,
              you agree not to transmit confidential or proprietary information
              relating to you or your business to Osudpotro via e-mail.
            </p>
            <span className="page-paragraph-title">Copyright:</span>
            <p>
              Osudpotro website and its logo is copyright protected. You may not
              modify, perform, display, reproduce or distribute (a) content on
              this Website (other than as set forth herein), (b) the design or
              layout of the Website or individual sections of the design or
              layout of the Website or (c) Osudpotro logos without Osudpotro
              written permission.
            </p>
            <span className="page-paragraph-title">Modifications:</span>
            <p>
              Osudpotro reserves the right to modify this Website and the rules
              and regulations governing its use at any time. Modifications will
              be posted on the Website, and users are deemed to be apprised of
              and bound by any changes to the Web Site. Your continued use of
              this Website following the posting of changes to these Terms of
              Use constitutes your acceptance of those changes. Osudpotro may
              make changes in the medicine or products and/or services described
              in this Website at any time
            </p>
            <span className="page-paragraph-title">
              Violation of Rules and Regulations:
            </span>
            <p>
              Osudpotro reserves the right to seek all remedies available at law
              and in equity for violations of the rules and regulations set
              forth in this Website, including the right to block access from a
              particular Internet address to the Web Site.
            </p>
            <span className="page-paragraph-title">
              Third-party References:
            </span>
            <p>
              Any description or reference to non-Osudpotro entities or their
              respective products or services in this Website is for
              informational purposes only and does not constitute an endorsement
              or a recommendation of such medicine or services by Osudpotro, nor
              does it constitute any endorsement or recommendation of Osudpotro
              or this Website by the non-Osudpotro entities referenced in this
              Website.
            </p>
            <p>
              Any opinions, advice, statements, services, offers or other
              information expressed or made available by third parties,
              including information providers, users or others, are those of the
              respective author(s) or distributor(s) and do not necessarily
              state or reflect the views, findings or opinions of Osudpotro.
            </p>
            <span className="page-paragraph-title">Linked Internet Sites:</span>
            <p>
              There may be links in this Website which will let you leave the
              web site. The linked sites are not under the control of Osudpotro,
              and the content available on the internet sites linked to this
              Website do not necessarily reflect the opinion of Osudpotro .
            </p>
            <p>
              Osudpotro is providing these links to other internet sites as a
              convenience to you, and access to any other internet sites linked
              to this Website is at your own risk. Osudpotro assumes no
              responsibility for, or any obligation to monitor, the content of
              such linked internet sites. The inclusion of any link does not
              imply a recommendation or endorsement by Osudpotro of the linked
              site
            </p>
            <span className="page-paragraph-title">
              Governing Law, Forum Selection, and General Provisions:
            </span>
            <p>
              This website was created and uploaded by Octopi Technologies from
              its place of business in Dhaka, Bangladesh. Osudpotro makes no
              representation that the information in the Publication is
              appropriate or available for use in other locations. Access to the
              Website from territories where the contents of the Website may be
              illegal is prohibited. Those who choose to access this Website
              from other locations do so at their own initiative and are
              responsible for compliance with applicable local laws. This
              Agreement shall be governed by and interpreted in accordance with
              the laws of Bangladesh.
            </p>
            <span className="page-paragraph-title">Severability</span>
            <p>
              If a section of this disclaimer is determined by any court or
              other competent authority to be unlawful and/or unenforceable, the
              other sections of this disclaimer shall continue in effect.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer backToTopVisible={true} />
    </>
  );
};

export default Disclaimer;
