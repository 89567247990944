import React, {useState} from "react";
import { Row, Col, ListGroup, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrderImg from "../images/order-img.webp";
import GoogleImg from "../images/google.webp";
import AppleImg from "../images/apple.webp";
import fireApi from "../redux/index";

import "../css/orderFromPhoneFooter.css";

const OrderFromPhone = () => {
  const [phoneNumber,setPhoneNumber] = useState("")
  const [successMessage,setSuccessMessage] = useState("")
  const [errorMessage,setErrorMessage] = useState("")

  const onPhoneNumberChange = (e) => {
    e.persist();
    setPhoneNumber(e.target.value);
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter" && phoneNumber.length === 11) onSubmitPhone();
  };
  
  const onSubmitPhone = () => {
    if(phoneNumber.length === 11){
      const fullMobileNumber = `${process.env.REACT_APP_COUNTRY_CODE}-${phoneNumber}`

      fireApi.callImageApi("users/send_app_link",{mobile:fullMobileNumber})
        .then(async ({data}) => {
          await setSuccessMessage(data.message)
          await setErrorMessage("")
        }).catch(async ({response})=>{
          await setErrorMessage(response.message)
          await setSuccessMessage("")
        });
    }else{
      setSuccessMessage("")
      setErrorMessage("Phone number is incorrect")
    }
  };

  return (
    <>
      <div className="container category-section border-bottom-0">
        <Row>
          <Col
            md={6}
            sm={12}
            className="d-flex justify-content-center align-items-center"
          >
            <img className="img-fluid footer-img" src={OrderImg}  alt="Order"/>
          </Col>
          <Col md={6} sm={12}>
            <div className="order-msg">
              <h2>
                Order Medicines From <br /> Your Phone
              </h2>
              <p>
                Enter your mobile number to recieve the app <br /> download link
              </p>
            </div>
            <div
              className="order-footer-form pt-5 pb-3 mr-5"
            >
              <label htmlFor="medicine-link">
                {process.env.REACT_APP_COUNTRY_CODE}
              </label>
              <input
                maxLength="11"
                type="text"
                id="medicine-link"
                autoComplete="off"
                value={phoneNumber}
                name="phone_number"
                onChange={(e) => onPhoneNumberChange(e)}
                onKeyPress={(e) => handleKeypress(e)}
              />
              <button className="btn-secondary" id="send-btn" onClick={onSubmitPhone}>Send</button>
              {successMessage !== "" ? <p className="resp-mess success">{ successMessage }</p> : ""}
              {errorMessage !== "" ? <p className="resp-mess failed">{ errorMessage }</p> : ""}
            </div>
            <div className="going-for-app-store">
              <ListGroup horizontal as="ul">
                <ListGroup.Item as="li">
                  <Nav.Link as={Link} to="/android"  target="_blank">
                    <img src={GoogleImg} alt="Google Icon"/>
                  </Nav.Link>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Nav.Link as={Link} to="/ios" target="_blank">
                    <img src={AppleImg} alt="Apple Icon"/>
                  </Nav.Link>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OrderFromPhone;
