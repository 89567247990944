import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Login from "../app/Login";
import Home from "../app/index";
import Profile from "../app/profile/index";
import OrderDelivery from "../app/profile/orderDelivery/index";
import OrderHistory from "../app/profile/orderHistory/index";
import Balance from "../app/profile/balanceAndTransaction/balance";
import TransactionHistory from "../app/profile/balanceAndTransaction/transactionHistory";
import ContactUs from "../app/contactUs";
import DoctorDescription from "../app/doctor";
import DiseaseDetail from "../app/diseaseDetail";
import Cart from "../app/cart";
import OfferAndDiscount from "../app/offersAndDiscount";
import OfferList from "../app/offerList";
import Prescription from "../app/prescription";
import TrackOrder from "../app/trackYourOrder";

import ProductList from "../app/productList";
import ProductDetailPage from "../app/productDetail";
import Checkout from "../app/checkout";
import PlaceOrder from "../app/placeOrder";
import OnlineDoctor from "../app/onlineDoctor";

import PaymentSuccess from "../app/PaymentSuccess";
import PaymentCancel from "../app/PaymentCancel";
import PaymentFail from "../app/PaymentFail";
import PrivacyPolicy from "../app/PrivacyPolicy";
import RefundPolicy from "../app/RefundPolicy";
import Disclaimer from "../app/Disclaimer";

import GoogleMaps from "../components/GoogleMap";
import GoogleMapsTrackOrder from "../components/GoogleMapTrackOrder";
import WalletRecharged from "../app/WalletRecharged";
import WalletRechargedFailed from "../app/WalletRechargedFailed";
import {CATEGORY_URLS} from "../constants/const"

const WebRouter = () => {
  // const productListPath = [
  //   "/productList/:productType/",
  //   "/productList/:productType/:productId",
  // ];

  const productDetailsPath = [
    "/:cat_type/:cat_alias/:alias",
    "/:cat_type/:cat_alias/:path",
    "/:cat_type/:cat_alias/:cat_id/:alias",
  ];
  const checkoutPath = [
    "/checkout",
    "/checkout-online-medicine-drug-store-bangladesh",
  ];
  const paymentPath = [
    "/placeOrder",
    "/place-your-cart-order-online-medicine-drug-in-bangladesh",
  ];
  const offersPath = [
    "/offers",
    "/promo-offers-or-coupon-in-online-medicine-in-bangladesh",
  ];
  const offersDiscountPath = [
    "/offers-and-discount/:offer_id",
    "/offers-and-discount-online-medicine-order-in-bangladesh/:offer_id",
  ];
  const contactPath = [
    "/contact",
    "/contact-us-for-medicine-home-delivery",
  ];

  const catUrls = CATEGORY_URLS.map((url,index)=>{
    return <Route exact={true} replace path={url.path} key={index}>
      <ProductList category={url.alias} category_id={url.id} cat_alias={url.alias} seoObj={url.seo}/>
    </Route>
  })

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/addresses">
          <OrderDelivery />
        </Route>
        <Route path={checkoutPath}>
          <Checkout />
        </Route>
        <Route path={paymentPath}>
          <PlaceOrder />
        </Route>
        <Route path="/deliveryHistory">
          <OrderHistory />
        </Route>
        <Route path="/trackOrder">
          <TrackOrder />
        </Route>
        <Route path="/balance">
          <Balance />
        </Route>
        <Route path="/transaction">
          <TransactionHistory />
        </Route>
        <Route path="/prescription">
          <Prescription />
        </Route>
        <Route path="/doctor/:docId">
          <DoctorDescription />
        </Route>
        <Route path="/googleMaps">
          <GoogleMaps />
        </Route>
        <Route path="/googleMapsTrackOrder">
          <GoogleMapsTrackOrder />
        </Route>
        <Route path="/cart">
          <Cart />
        </Route>
        <Route path={contactPath}>
          <ContactUs />
        </Route>
        <Route path="/consult-doctor-online-get-expert-doctors-help">
          <OnlineDoctor />
        </Route>
        <Route path={offersPath}>
          <OfferAndDiscount />
        </Route>
        <Route path={offersDiscountPath}>
          <OfferList />
        </Route>
        <Route exact={true} path="/disease/:disId">
          <DiseaseDetail />
        </Route>

        { catUrls }

        <Route exact={true} path={productDetailsPath} component={ProductDetailPage} />

        <Route exact={true} path="/payment-success">
          <PaymentSuccess />
        </Route>
        <Route exact={true} path="/payment-cancel">
          <PaymentCancel />
        </Route>
        <Route exact={true} path="/payment-fail">
          <PaymentFail />
        </Route>
        <Route exact={true} path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact={true} path="/refund-policy">
          <RefundPolicy />
        </Route>
        <Route exact={true} path="/disclaimer">
          <Disclaimer />
        </Route>
        <Route exact={true} path="/recharge-successfull">
          <WalletRecharged/>
        </Route>
        <Route exact={true} path="/recharge-failed">
          <WalletRechargedFailed />
        </Route>
        <Route exact path="/ios" render={() => {
          { window.location.replace("https://apps.apple.com/us/app/osudpotro-online-pharmacy/id1545307956") }
        }} /> 
        <Route exact path="/android" render={() => {
          { window.location.replace("https://play.google.com/store/apps/details?id=com.osudpotro") }
        }} /> 
      </Switch>
    </Router>
  );
}

export default WebRouter