import React, { useState, useEffect } from "react";

import Cookies from "universal-cookie";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import CallForPrice from '../../components/callPriceComponent';
import _ from "lodash";
import "../../css/orderDelivery.css";
import "../../css/productDetailComponent.css";
import "swiper/swiper.scss";

var FontAwesome = require("react-fontawesome");
const cookies = new Cookies();

const CartProdQuantityMgt = ({ item, setParamsArray }) => {
  const [showArrangeForMeModal, setShowArrangeForMeModal] = useState(false);

  const [productDetail, setProductDetail] = useState({ ...item });

  const [inventory, setInventory] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [localInventory, setLocalInventory] = useState({ ...inventory });

  useEffect(() => {
    if (productDetail.item_data) {
      setInventory(productDetail.item_data);
      setLocalInventory(productDetail.item_data);
    }
  }, [productDetail]);

  const incrementProduct = (inventoryIndex, operator = "p") => {
    setActiveIndex(inventoryIndex)
    setShowArrangeForMeModal(false);

    let tmpObject = localInventory[activeIndex];
    let tempArray = [...localInventory];
    tempArray[inventoryIndex] = {
      ...localInventory[inventoryIndex],
      qty:
        operator == "p"
          ? localInventory[inventoryIndex].qty + 1
          : localInventory[inventoryIndex].qty - 1 < 0
          ? 0
          : localInventory[inventoryIndex].qty - 1,
    };
    setLocalInventory(tempArray);
    setParamsArray(tempArray);

    return;
  };

  const purchaseTypeChange = (e) => {
    e.persist();
    setActiveIndex(parseInt(e.target.value));
  };

  const arrangeForMeHandle = () => {
    let tmpArray = [...localInventory];
    tmpArray[activeIndex] = {
      ...tmpArray[activeIndex],
      arrange: !tmpArray[activeIndex].arrange,
    };
    setLocalInventory(tmpArray);
  };

  const getItemPrice = inventory =>{
    return _.sumBy(inventory, invData => {
      if(invData.qty > 0) return invData.price * invData.qty
      return 0
    })
  }


  return (
    <>
      {productDetail && (
        <div className="product-info-cont-compo cart-modal-body-area pl-2">
          <h3 className="product-info-heading-compo">
            {productDetail.item_name || ""}
          </h3>
          {!item.is_showcasing ?
          <p className="product-price-info product-price">
            {localInventory &&
              localInventory[activeIndex] && (
                <NumberFormat
                  value={ getItemPrice(localInventory) }
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={`৳`}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  type="currency"
                  renderText={(value) => value}
                />
              )}
          </p> : <CallForPrice /> }
          
          <div style={{ marginTop: "30px" }}>
            {inventory &&
              inventory.map((purchaseType, inventoryIndex) => (
                <Row style={{ marginTop: "10px" }} key={inventoryIndex}>
                  <Col lg={6} md={6} sm={9} xs={6}>
                    <div>
                      <label className="cus-radio radio-label-text">
                        {purchaseType && purchaseType.capacity}
                        <input
                          type="radio"
                          checked={
                            inventoryIndex === activeIndex
                          }
                          value={inventoryIndex}
                          name="radio"
                          onChange={purchaseTypeChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={3}
                    xs={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="qty-control-button">
                      <span
                        onClick={() => incrementProduct(inventoryIndex, "m")}
                      >
                        <FontAwesome
                          className="decrement-button"
                          name="minus"
                        />
                      </span>
                      <input
                        className="qyt-input"
                        value={
                          localInventory && localInventory[inventoryIndex].qty
                            ? localInventory[inventoryIndex].qty
                            : 0
                        }
                        maxLength={4}
                        maxLength={1}
                        readOnly
                      />
                      <span
                        onClick={() => incrementProduct(inventoryIndex, "p")}
                      >
                        <FontAwesome className="decrement-button" name="plus" />
                      </span>
                    </div>
                  </Col>
                </Row>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CartProdQuantityMgt;
