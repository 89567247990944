import React, { useEffect, useState} from "react";
import { Row, Col, Container, Modal, Button, Nav } from "react-bootstrap";
import { Link as PageLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import MainHeader from "../components/MainHeader";
import Footer from "../components/footer";
import allActions from "../redux/actions";
import LogicalFuncs from "../logicalFunctions/handleHomeData";
import fireApi from "../redux/index";
import codImage from "../images/pay-icon1.png";
import walletImage from "../images/chatNewLogo.png";
import SslImg from "../images/ssl-icon.png";
import { useHistory, Link } from "react-router-dom";
import OrderHistoryList from "./profile/orderHistory/orderHistoryModal";
import AlertModal from "../components/alertModal";
import MetaDecorator from '../components/MetaDecorator';
import _ from "lodash";
import "../css/checkout.css";
import "../css/placeOrder.css";
var FontAwesome = require("react-fontawesome");
const cookies = new Cookies();

//btnElem.setAttribute("postdata", pData)

const localPlaceOrderData = JSON.parse(localStorage.getItem('placeOrderData'))

const PlaceOrder = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const cartFromRedux = useSelector((state) => state.cart.cartData) || [];
  const cartTotalFromRedux = useSelector((state) => state.cart.cartTotal);
  const [customerOrder, setCustomerOrder] = useState(false);
  const [customerOrderAddress, setCustomerOrderAddress] = useState(null);
  const [isAccepted,setIsAccepted] =  useState(false)
  const [acceptedErorr,setAcceptedErorr] = useState(false)
  const [placeOrderDetails,setPlaceOrderDetails] = useState(useSelector(
    (state) => state.placeOrder.placeOrderData
  ));
  const [activeMethod, setActiveMethod] = useState("cod");
  const [cartTotal, setCartTotal] = useState(
    placeOrderDetails ? placeOrderDetails.cartTotal : null
  );
  const [link, setLink] = useState(null);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [orerStatusModal, setOrderStatusModal] = useState(false);
  const [isGuestUser, setIsGuestUser] = useState(false);
  const [postData, setpostData] = useState({});
  const [cartData, setCartData] = useState(cartFromRedux);
  const [hasCatalogueProduct, setHasCatalogueProduct] = useState(false);
  let userType = cookies.get("user_type");

  const seoData = {
    title:`Offer & Discount On Online Medicine Order In Dhaka | Osudpotro`,
    description:`Online Medicine Shopping - Buy medicines online with home delivery at great discounts & offers from Bangladesh's top online pharmacy & most trusted medical store, Osudpotro. `,
    keywords: `buy medicines in dhaka, Offer On Purchase, Discountsand offers`,
    og_url:`/place-your-cart-order-online-medicine-drug-in-bangladesh`,
    og_imageUrl: ``
  }
  
  const fetchCartTotalFromServer = () => {
    return fireApi
      .callApiWithToken(
        "cart/get_total",
        {},
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data || !res.data.status) {
          return;
        }

        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        var { total, cart_count, delievery_fee, discount } = res.data.data;
        setCartTotal(total);
        dispatch(
          allActions.cartActions.calculateCartTotal({
            sub_total: cart_count,
            delievery_fee: delievery_fee,
            discount: discount,
            cartTotal: total,
          })
        );
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setCustomerOrder(JSON.parse(localStorage.getItem('customerOrder')) || false)
  }, []);

  useEffect(() => {
    if(placeOrderDetails === undefined){
      setPlaceOrderDetails(JSON.parse(localStorage.getItem('placeOrderData')))
    }
  }, [placeOrderDetails]);

  useEffect(() => {
    if(customerOrder)
      setCustomerOrderAddress(JSON.parse(localStorage.getItem('customerOrderAddress')) || null)
  }, [customerOrder]);

  useEffect(() => {
    if (userType !== "user") setIsGuestUser(true);
    if (cartTotal === null) fetchCartTotalFromServer();
  }, [userType]);

  useEffect(() => {
    const pData = {
      amount: cartTotal,
      cus_name: currentUser.name,
      cus_email: currentUser.email,
      cus_phone: currentUser.mobile,
    };
    setpostData(pData);
  }, [cartTotal]);

  useEffect(() => {
      const newOrderData = {
        ...placeOrderDetails,selectedPaymentMethod: activeMethod,
      }
      setPlaceOrderDetails(newOrderData)
      dispatch(allActions.placeOrderActions.placeOrder(newOrderData));
      localStorage.setItem('placeOrderData',JSON.stringify(newOrderData))
  }, [activeMethod]);

  const placeOrderToServer = () => {
    const {cartTotal} = cartTotalFromRedux;
    if (!cartTotal) return
    if(!isAccepted){
      setAcceptedErorr(true)
      return;
    }else
      setAcceptedErorr(false)

    if (activeMethod === "osud_wallet") {
      if (currentUser.wallet_amount < cartTotal) {
        setShowAlertModal(true);
        setAlertMessage("Insufficient balance, Kindly add money in your wallet");
        setLink("/balance");
        return;
      }
    }
    let timestamp = new Date().getTime();
    dispatch(allActions.placeOrderActions.placeOrder({...placeOrderDetails,trx_id: "OSPN" + timestamp}));

    let params = {
      address_id: placeOrderDetails.selectedAddress,
      payment_method: placeOrderDetails.selectedPaymentMethod,
      trx_id: placeOrderDetails.trx_id || "OSPN" + timestamp,
      instruction:placeOrderDetails.instruction || '',
      offer_code:
        placeOrderDetails.offerApplied &&
        placeOrderDetails.offerApplied.offer_code || "",
      prescription_id: JSON.stringify(placeOrderDetails.prescription_id || []),
    };
    let apiName = "order/order_web";

    if(customerOrder){
      params = {
        is_customer_care_order: 1,
        address: customerOrderAddress.address,
        address2:customerOrderAddress.address2,
        payment_method: placeOrderDetails.selectedPaymentMethod,
        mobile: customerOrderAddress.contact,
        name: customerOrderAddress.name,
        lat:customerOrderAddress.lat,
        long:customerOrderAddress.long,
        email:customerOrderAddress.email,
        instruction:placeOrderDetails.instruction || '',
      }; 
    }

    return fireApi
      .callApiWithToken(
        apiName, params,
        currentUser.token || cookies.get("token"),
        currentUser._id || cookies.get("user_id")
      )
      .then((res) => {
        if (!res || !res.data) {
          return;
        }
        
        if (res && res.token_refreshed) {
          LogicalFuncs.storeRefreshToken({
            res,
            dispatch,
            allActions,
            cookies,
          });
        }
        
        if(!res.data.status){
          setShowAlertModal(true);
          setAlertMessage(res.data.message);
          return;
        }

        let date = new Date();
        cookies.set("tran_id", res.data.tran_id, {
          path: "/",
        });
        localStorage.removeItem('placeOrderData')
        localStorage.removeItem('selectedAddressId')
        if(customerOrder){
          localStorage.removeItem('customerOrderAddress')
          localStorage.removeItem('customerOrder')  
        }
        
        fetchCartTotalFromServer();
        if (activeMethod === "ssl_commerz" && res.data.gatewayPageUrl) {
          window.location.replace(res.data.gatewayPageUrl);
        }
        if (activeMethod === "cod") {
          setTimeout(() => {
            history.push("/payment-success");
          }, 1000);
        }
        if (activeMethod === "osud_wallet") {
          if (res.data.status) {
            setTimeout(() => {
              history.push("/payment-success");
            }, 1000);
          }else{
            setShowAlertModal(true);
            setAlertMessage(res.data.message);
            setLink("/balance");
          }
        }
        fetchCartTotalFromServer();
      });
  };
  useEffect(() => {
    if (cartFromRedux.length > 0) setCartData(cartFromRedux);
    if (cartFromRedux.length > 0 && _.findIndex(cartFromRedux, item=> item.is_showcasing === 1) !== -1) setHasCatalogueProduct(true);
  }, [cartFromRedux]);

  const onCloseAlertModal = () => {
    setShowAlertModal(false);
  };
  const setPaymentMethod = (method) =>{
    if(hasCatalogueProduct && (method === 'ssl_commerz' || method === 'osud_wallet')){
      setShowAlertModal(true);
      setAlertMessage("Please select cash on delivery. <span><strong>NB:</strong> Some of the cart item got no price, Our Customer care will contact you with the updated price shortly!</span></p>");
      setLink(null);
      return
    }
    setActiveMethod(method)
  }

  return (
    <>
      {isGuestUser ? history.push("/Login") : (
        <>
          <MetaDecorator seo = {seoData} />
          <MainHeader />
          <div style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <Container>
              <Row>
                <Col lg={8} md={6} sm={12} className="pl-3">
                  <div className="title-heading checkout-heading">
                    Checkout Payment
                  </div>

                  <div className="payment-method-cont">
                    <div
                      className={` payment-item ${
                        activeMethod === "cod" ? "activeMethod" : ""
                      }`}
                      onClick={() => setPaymentMethod("cod")}
                    >
                      <span>
                        <img src={codImage} />
                      </span>
                      <span className="px-3">Cash On Delivery</span>
                      <FontAwesome name="angle-right" />
                    </div>
                    
                    {!customerOrder && 
                    <>
                      <div
                        className={` payment-item ssl ${
                          activeMethod === "ssl_commerz" ? "activeMethod" : ""
                        }`}
                        onClick={() => setPaymentMethod("ssl_commerz")}
                      >
                        <span>
                          <img src={SslImg} />
                        </span>
                        Pay Online <span className="small">Powered By: SSL Commerz</span>
                        <FontAwesome name="angle-right" />
                      </div>

                      <div
                        className={` payment-item ${
                          activeMethod === "osud_wallet" ? "activeMethod" : ""
                        }`}
                        onClick={() => setPaymentMethod("osud_wallet")}
                      >
                        <span>
                          <img src={walletImage} />
                        </span>
                        <span className="px-3">OsudPotro Wallet</span>
                        <FontAwesome name="angle-right" />
                      </div>
                    </>
                  } 
                  </div>

                  <div className="acpt-area mt-3" className={`arrange-for-me-checkmark ${acceptedErorr ? 'checkouterror' : ''}`}>
                  <label className="container arrange-label terms-label checkout-page-terms">
                        <span className="terms-checkbox">
                          <input
                            className="arrange-for-me-box"
                            type="checkbox"
                            value={isAccepted}
                            checked={isAccepted}
                            onChange={(e) => {
                              setIsAccepted(isAccepted => !isAccepted)
                              setAcceptedErorr(isAccepted)
                            }}
                          />
                          <span className={`arrange-for-me-checkmark ${acceptedErorr ? 'hasError' : ''}`}></span>
                        </span>
                        I have read the website 
                        <Nav.Link as={PageLink} to="/disclaimer">
                          legal disclaimer
                        </Nav.Link>, 
                        <Nav.Link as={PageLink} to="/privacy-policy">
                          privacy policy
                        </Nav.Link>, 
                        <Nav.Link as={PageLink} to="/refund-policy">
                          refund policy
                        </Nav.Link>, 
                         and accept all the terms here is in
                      </label>
                  </div>

                </Col>
                <Col lg={4} md={6} sm={12}>
                  <div className="purchase-summary-checkout">
                    <div
                      style={{
                        textAlign: "center",
                        backgroundColor: "transparent",
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      <p style={{ fontSize: "24px" }}>
                      You can review this order before placing the order
                      </p>
                      <div className="purchase-summary-btn-area">
                        <Nav.Item as="li" >
                            <Nav.Link as={Link} to="/" >Buy More</Nav.Link>
                       </Nav.Item>
                       <Nav.Item as="li">
                            <Nav.Link as={Link} to="/cart" >Review Order</Nav.Link>
                       </Nav.Item>
                       <Nav.Item as="li">
                            <Nav.Link as={Link} onClick={() => placeOrderToServer()}>Place Order</Nav.Link>
                       </Nav.Item>
                    </div>
                     
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer backToTopVisible={false} />
        </>
      )}
      <AlertModal
        show={showAlertModal}
        message={alertMessage}
        buttonText="OK"
        link={link}
        linkText="Go to your wallet"
        handleModalClose={onCloseAlertModal}
      />
      <Modal
        show={orerStatusModal}
        centered={true}
        keyboard={false}
        onHide={() => {
          setOrderStatusModal(false);
        }}
      >
        <Modal.Body
          style={{ display: "flex", justifyContent: "center", width: 500 }}
        >
          <div
            className="history-det-cont"
            style={{
              borderTop: "2px solid #b9b9b9",
              paddingTop: "25px",
            }}
          >
            <div className="checkout-page-title-area">
              <span className="checkout-title-up">Order</span>
              <span className="checkout-title-down">Summary</span>
            </div>

            {cartData.map((cartItem, index) => (
              <OrderHistoryList
                item={cartItem}
                is_checkout={true}
                key={index}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            className="guest-modal-footer-button"
            variant="secondary"
            onClick={() => setOrderStatusModal(false)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlaceOrder;
