const getGuestUser = () => {
    return {
        type: "GET_GUEST_USER"
    }
}

const currentUserToStore = (userData) => {
    return {
        type: "CURRENT_USER",
        userData: userData
    }
}

const updateCurrentUser = (userData) => {
    return {
        type: "UPDATE_CURRENT_USER",
        userData: userData
    }
}

export default {
    getGuestUser,
    currentUserToStore,
    updateCurrentUser
}