import React from "react";
import NumberFormat from "react-number-format";
import { RootThumbImgUrl } from "../constants/const";
import { useHistory } from "react-router-dom";
import { isTypeProductCategory } from "../helpers/utils"
import CallForPrice from '../components/callPriceComponent';
import "../css/allProductListing.css";

const ProductListComponent = ({
  item,
  itemId,
  imageToShow,
  name,
  price,
  regular_price,
  discount
}) => {
  
  const history = useHistory();

  const RedirectToDetailPage = () => {
    let navigateTo = "/";
    if (item && item.cat_type && isTypeProductCategory(item.cat_type)) {
      if (item.cat_type) navigateTo += `${item.cat_type}/`;
      if (item.cat_name) navigateTo += `${item.cat_name}/`;
      if (itemId) navigateTo +=  item.alias;
    } else if ( item && (item.cat_type === "doctors" || item.cat_name === "online-doctor's")) {
      navigateTo = `/doctor/${itemId}`;
    } else {
      if (item && item.cat_type && (item.cat_type === "medicine" || item.cat_type === "prescribed") || (item.cat_name === "otc-medicines" && item.cat_type !== "" && item.cat_type !== "disease"))
        navigateTo += `medicine/${item.cat_name}/${item.alias}`;
      else navigateTo = `/disease/${item.alias}`;
    } //prescribed
    history.push(navigateTo);
  };

  const sectionStyle = {
    backgroundImage: `url(${RootThumbImgUrl}${imageToShow})`,
  };
  return (
    <div className="component-produ-cont" onClick={RedirectToDetailPage}>
      {discount > 0 && <div className="discount-percent">{discount}%<br/>Off</div>}
      <div className="img-placeholder" style={sectionStyle}></div>
      <div className="component-produ-det">
        <h6 title={name}>{name}</h6>
        {price && !item.is_showcasing ? (
          <p className="product-price">
            <NumberFormat
              value={price}
              displayType={"text"}
              thousandSeparator={true}
              prefix={`৳`}
              decimalScale={2}
              fixedDecimalScale={true}
              type="currency"
              renderText={(value) => value}
            />
            {
              discount > 0 && 
              <span className='regular-price'>
                <NumberFormat
                  value={regular_price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={`৳`}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  type="currency"
                  renderText={(value) => value}
                />
              </span>
            }
          </p>
        ) : <></>}

        {price && item.is_showcasing ? <CallForPrice /> : <></>}
        
      </div>
    </div>
  );
};

export default ProductListComponent;
